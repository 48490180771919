import React, {useRef, useState} from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withRouter, Link as RouterLink } from "react-router-dom";
import { Grid, Typography, Paper, Button, InputBase, Collapse, IconButton } from "@material-ui/core";
import { Impecc2, LogoBlanc, Blog} from "./icons"
import axios from "axios"
import { Alert } from '@material-ui/lab'
import CloseIcon from '@material-ui/icons/Close';
import {ConfSections} from "./conf_content"
import useWindowSize from "./useWindowSize";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

const useStyles = makeStyles((theme) => ({
  CGUMain: {
    minHeight: 1659,
    backgroundColor: "#fff",
    [theme.breakpoints.down('md')]:{
      minHeight: 1400,

    }
  },
  CGUTopGrid:{
    height: 500,
    backgroundColor: "#ffe72f",
    [theme.breakpoints.down('md')]:{
        height: 450,
  
      }
  },
  CGUBody:{
    minHeight: 1659,
    paddingTop: 160,
    paddingBottom: 200,
    backgroundColor: "#fff",
    overflow: "visible",
    position: "relative",
    [theme.breakpoints.down('md')]:{
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 80
      }
  },
  gridFooter: {
    backgroundColor: "#000000",
    height: 800,
    position: "relative",
    overflow: "visible",
    [theme.breakpoints.down('md')]:{
      height: 600,

    }
  },
  typoCGU:{
    fontFamily: "DM Serif Display",
    marginTop:24,
    fontSize: 56,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: 0.5,
    color: "#000000",
    [theme.breakpoints.down('md')]:{
      fontSize: 36,
      textAlign: "center"
    }
  },
  typoCGUVigeur:{
    fontFamily: "DM Serif Display",
    fontSize: 16,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: 0.5,
    color: "#000000",
    [theme.breakpoints.down('md')]:{
      fontSize: 16,
      textAlign: "center"
    }
  },
  typoTexte:{
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 2,
    letterSpacing: 0,
    color: "#7e7e7e",
    [theme.breakpoints.down('md')]:{
     
      textAlign: "center"
    }
  },
  footerImpecc: {
    width: 586,
    height: 720,
    position: "absolute",
    top: 0,
    right: 0,
    overflow: "hidden",
    objectFit: "contain",
  },
  typoFooter: {
    fontFamily: "DM Serif Display",
    fontSize: 48,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.08,
    letterSpacing: 0.43,
    color: "#ffffff",
    maxWidth: 600,
    [theme.breakpoints.down('md')]:{
      textAlign:"center",
      fontSize: 36
    }
  },
  typoLiensFooter: {
    opacity: 0.64,
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: 1.17,
    letterSpacing: 0,
    color: "#ffffff",
    cursor: "pointer",
    textDecoration: "none",
  },
  typoFooterMentions: {
    opacity: 0.4,
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.67,
    letterSpacing: 0,
    textAlign: "right",
    color: "#ffffff",
    cursor: "pointer",
    textDecoration: "none",
  },

  buttonGoFooter: {
    width: 240,
    height: 240,
    borderRadius: 120,
    backgroundColor: "#ffe72f",
    color: "#000000",
    fontFamily: "DM Serif Display",
    fontSize: 48,
    zIndex: 200,
    "&:hover ": {
      backgroundColor: "rgb(178, 161, 32)",
    },
    [theme.breakpoints.down('md')]:{
      width: 180,
      height: 180,
      borderRadius: 90,
      fontSize: 36,
    }
  },
  footerBlog: {
    position: "absolute",
    top: -14,
    left: -15,
  },
  paperInput:{
    display: "flex",
    alignItems: "center",
    width: "100%",
    maxWidth: 411,
    height: 48,
    borderRadius: 16,
    backgroundColor: "#202020",
  },
  paperInputMessage:{
    display: "flex",
    alignItems: "center",
    width: "100%",
    maxWidth: 411,
    height: 112,
    borderRadius: 16,
    backgroundColor: "#202020",
  },
  buttonBlog: {
    height: 48,
    width: 145,
    borderRadius: "14px 14px 0px 14px",
    backgroundColor: "#ffe72f",
    textTransform: "none",
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: 1.63,
    letterSpacing: 0,
    color: "#000000",
    textAlign: "left",
    "&:hover ": {
      backgroundColor: "rgb(178, 161, 32)",
    },
  },
  buttonDisabled:{
    backgroundColor: "#202020 !important"
  },
  CGUFiller: {
    position: "absolute",
    width: 160,
    height: 160,
    top: 0,
    left: 0,
    backgroundColor: "#ffe72f",
    [theme.breakpoints.down('md')]:{
        width: 80,
        height: 80,
       
      }
  },
  CGUFiller2: {
    position: "absolute",
    width: 160,
    height: 160,
    top: 0,
    left: 0,
    backgroundColor: "#fff",
    borderTopLeftRadius: 160,
    [theme.breakpoints.down('md')]:{
        width: 80,
        height: 80,
        borderTopLeftRadius: 80,
      }
  },
  CGUFiller3: {
    position: "absolute",
    width: 160,
    height: 160,
    top: -160,
    right: 0,
    backgroundColor: "#fff",
    [theme.breakpoints.down('md')]:{
        width: 80,
        height: 80,
        top: -80,
      }
  },
  CGUFiller4: {
    position: "absolute",
    width: 160,
    height: 160,
    top: -160,
    right: 0,
    backgroundColor: "#ffe72f",
    borderBottomRightRadius: 160,
    [theme.breakpoints.down('md')]:{
        top: -80,
        width: 80,
        height: 80,
        borderBottomRightRadius: 80,
      }
  },
  CGUItemHeader:{
    marginTop: 24
  },
  CGUItemBody:{
    marginTop: 16
  },
  typoCGUItemHeader:{
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500
  },
  typoCGUItemBody:{
     fontSize: theme.typography.pxToRem(14),
     whiteSpace: "pre-line"
  },
  bandeauNoir1: {
    position: "absolute",
    top: -35,
    height: 30,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir2: {
    position: "absolute",
    top: -67,
    height: 25,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir3: {
    position: "absolute",
    top: -92,
    height: 15,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir4: {
    position: "absolute",
    top: -112,
    height: 5,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir1Hidden: {
    position: "absolute",
    top: 0,
    height: 30,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir2Hidden: {
    position: "absolute",
    top: 0,
    height: 25,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir3Hidden: {
    position: "absolute",
    top: 0,
    height: 15,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir4Hidden: {
    position: "absolute",
    top: 0,
    height: 5,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
}));
function Confidentialite(props) {
  const classes = useStyles(props);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const [mail, setMail] = useState("")
  const [errorNewsletter, setErrorNewsletter] = useState(false)
  const [successNewsletter, setSuccessNewsletter] = useState(false)
  const [accordeonFooter, setAccordeonFooter] = useState(false);
  const gridFooterRef = useRef();
  const windowSize = useWindowSize();

  function registerNewsletter(mail){
    axios.post("https://www.qibish.com/newsletter", {
      mail:mail
    })
    .then((res) => {setSuccessNewsletter(true); setMail("")})
    .catch((error) => {
      setErrorNewsletter(true)
      setMail("")
    });
  }
  useScrollPosition(
    ({ currPos }) => {
      
      if (currPos.y + 25 < windowSize.height) {
        setAccordeonFooter(true);
      } else {
        setAccordeonFooter(false);
      }
    },
    [],
    gridFooterRef
  );

  return (
    <React.Fragment>
      <div className={classes.CGUMain}>
        <Grid
            container
            className={classes.CGUTopGrid}
        >        
        {mobile ?
        <Grid item style={{paddingTop: 150}}>
                        <Typography className={classes.typoCGUVigeur}>
            EN VIGUEUR À COMPTER DU 15 NOVEMBRE 2020
                </Typography>
        <Typography className={classes.typoCGU}>
        Charte relative à la protection des données à caractère personnel des utilisateurs
        </Typography>
    </Grid>
        :
        <>
        <Grid lg={1} />
        <Grid lg={6} item style={{paddingTop: 200}}>
            <Typography className={classes.typoCGUVigeur}>
            EN VIGUEUR À COMPTER DU 15 NOVEMBRE 2020
                </Typography>
                <Typography className={classes.typoCGU}>
                Charte relative à la protection des données à caractère personnel des utilisateurs
                </Typography>
            </Grid>
        </>
        }


        </Grid>
        <Grid container className={classes.CGUBody} direction="column">
        <div className={classes.CGUFiller} />
          <div className={classes.CGUFiller2} />
          <div className={classes.CGUFiller3} />
          <div className={classes.CGUFiller4} />
          {ConfSections.map(cgu => (
              <>
                <Grid container item className={classes.CGUItemHeader}>
                    <Grid lg={1}/>
                    <Grid lg={9}>
                        <Typography className={classes.typoCGUItemHeader}>{cgu.header}</Typography>
                    </Grid>    
                </Grid>
                {cgu.body ?
                <Grid container item className={classes.CGUItemBody}>
                <Grid lg={1}/>
                    <Grid lg={9}>
                    <Typography className={classes.typoCGUItemBody}>{cgu.body}</Typography>
                    </Grid>    
                </Grid>                
                :null}

              </>
          ))}
        </Grid>
<Grid
          container
          justify="flex-start"
          direction="column"
          className={classes.gridFooter}
          ref={gridFooterRef}
        >
                    <div
            className={
              accordeonFooter
                ? classes.bandeauNoir1
                : classes.bandeauNoir1Hidden
            }
          />
          <div
            className={
              accordeonFooter
                ? classes.bandeauNoir2
                : classes.bandeauNoir2Hidden
            }
          />
          <div
            className={
              accordeonFooter
                ? classes.bandeauNoir3
                : classes.bandeauNoir3Hidden
            }
          />
          <div
            className={
              accordeonFooter
                ? classes.bandeauNoir4
                : classes.bandeauNoir4Hidden
            }
          />
          {mobile ? null:
          <div className={classes.footerImpecc}>
            <Impecc2 />
          </div>          
          }

          <Grid
            container
            justify={mobile ? "center":"flex-start"}
            style={mobile ?{ marginTop:36 } :{ marginTop: 176, paddingLeft: 80, height: 250 }}
          >
            <Grid>
              <Typography className={classes.typoFooter}>
                {
                  "Facilite la communication et l’échange au sein de tes équipes avec Qibish"
                }
              </Typography>
            </Grid>
            <Grid style={mobile ?{marginTop: 24}:{ paddingLeft: "20%" }}>
              <Button
                disableElevation
                variant="contained"
                className={classes.buttonGoFooter}
                onClick={() => props.goToApp()}
              >
                GO !
              </Button>
            </Grid>
          </Grid>
          {mobile ? 
          <Grid container justify="center" style={{marginTop: 34}}>
            <Grid xs={10}>
              <Paper
                component="form"
                elevation={0}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: mobile ? "100%":303,
                  height: 48,
                  borderRadius: 16,
                  backgroundColor: "#202020",
                  opacity: 0.39,
                }}
                onSubmit={e => {e.preventDefault(); registerNewsletter(mail)}}
              >
                <InputBase
                onChange={(e) => setMail(e.target.value)}
                value={mail}
                  placeholder="S'inscrire à la newsletter"
                  inputProps={{ "aria-label": "S'inscrire à la newsletter" }}
                  style={{
                    marginLeft: 16,
                    flex: 1,
                    color: "#fff",
                    fontSize: 12,
                  }}
                />
                <Button disableElevation style={{ color: "#fff" }} onClick={() =>  registerNewsletter(mail)}>
                  OK
                </Button>
              </Paper>
              <Collapse in={successNewsletter}>
              <Alert
                style={{marginTop: 8}}
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setSuccessNewsletter(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Inscription validée !
              </Alert>
            </Collapse>

         
            <Collapse in={errorNewsletter}>
              <Alert
                style={{marginTop: 8}}
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setErrorNewsletter(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
               Une erreur s'est produite lors de ton inscription
              </Alert>
              </Collapse>
            </Grid>
          </Grid>
          :
          <Grid
            container
            justify="space-between"
            alignItems="flex-start"
            style={{
              marginTop: 70,
              paddingLeft: 80,
              paddingRight: 80,
              height: 150,
            }}
          >
            <Grid>
              <Grid container style={{ width: 310 }} alignItems="center">
                <Grid xs={3}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/"
                  >
                    Accueil
                  </Typography>
                </Grid>
                <Grid xs={3}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/prix"
                  >
                    Prix
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ position: "relative" }}>
                  <Typography className={classes.typoLiensFooter}>
                    Blog
                  </Typography>
                  <div className={classes.footerBlog}>
                    <Blog />
                  </div>
                </Grid>
                <Grid xs={3}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/contact"
                  >
                    Contact
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ marginTop: 24 }}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/solution"
                  >
                    Solution
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ marginTop: 24 }}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/mission"
                  >
                    Mission
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ marginTop: 24 }}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/cgu"
                  >
                    CGU
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ marginTop: 24 }}>
                  <Typography className={classes.typoLiensFooter} onClick={() => props.goToApp()}>
                    Se connecter
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <Paper
                component="form"
                elevation={0}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: 303,
                  height: 48,
                  borderRadius: 16,
                  backgroundColor: "#202020",
                  opacity: 0.39,
                }}
                onSubmit={e => {e.preventDefault(); registerNewsletter(mail)}}
              >
                <InputBase
                onChange={(e) => setMail(e.target.value)}
                value={mail}
                  placeholder="S'inscrire à la newsletter"
                  inputProps={{ "aria-label": "S'inscrire à la newsletter" }}
                  style={{
                    marginLeft: 16,
                    flex: 1,
                    color: "#fff",
                    fontSize: 12,
                  }}
                />
                <Button disableElevation style={{ color: "#fff" }} onClick={() =>  registerNewsletter(mail)}>
                  OK
                </Button>
              </Paper>
              <Collapse in={successNewsletter}>
              <Alert
                style={{marginTop: 8}}
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setSuccessNewsletter(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Inscription validée !
              </Alert>
            </Collapse>

         
            <Collapse in={errorNewsletter}>
              <Alert
                style={{marginTop: 8}}
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setErrorNewsletter(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
               Une erreur s'est produite lors de ton inscription
              </Alert>
              </Collapse>
            </Grid>
          </Grid>          
          }

          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={mobile ?{ marginTop: 40, paddingLeft: 24, paddingRight: 24 } :{ marginTop: 10, paddingLeft: 80, paddingRight: 80 }}
          >
            <Grid>
              <LogoBlanc />
            </Grid>
            <Grid>
                <Grid container direction="column">
                    <Grid>
                        <Typography 
                            className={classes.typoFooterMentions}
                            component={RouterLink}
                            to="/confidentialite">
                            Politique de confidentialité
                        </Typography>                        
                    </Grid>                    
                    <Grid>
                        <Typography 
                            className={classes.typoFooterMentions}
                            component={RouterLink}
                            to="/mentions">
                            Mentions légales © 2020 Qibish
                        </Typography>                        
                    </Grid>

                </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default withRouter(Confidentialite);
