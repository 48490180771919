import {
  Switch,
  Route,
  useLocation,
  Link as RouterLink,
  withRouter
} from "react-router-dom";

import Home from "./home";
import Solution from "./solution";
import Prix from "./prix";
import Mission from "./mission"
import Contact from "./contact"
import FourOFour from"./404"
import CGU from "./cgu"
import Mentions from "./mentions"
import Confidentialite from "./confidentialite"
import React, {useEffect} from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  useScrollTrigger,
  Slide,
  CssBaseline,
  Grid,
  Button,
  IconButton,
  Dialog,
  Divider,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LogoCompletSvg from "./logo_complet";
import { MenuBlackIcon } from "./icons";
import CloseIcon from '@material-ui/icons/Close';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history'




const useStyles = makeStyles((theme) => ({
  main: {
    minHeight: 6660,
    backgroundColor: "#ffe72f",
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(4),
  },
  appbar: {
    height: 124,
  },
  typoNav: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "500",
    color: "#000000",
    cursor: "pointer",
    textDecoration: "none"
  },
  typoNavPrix: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "500",
    color: "#ffffff",
    cursor: "pointer",
    textDecoration: "none"
  },
  obtenirButton:{
    width: 122,
    height: 40,
    borderRadius: 14,
    backgroundColor: "#000000",
    color: "#ffffff",
    textTransform: "none",
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "500",
    fontStyle: "normal",
    padding: 0,
    "&:hover ":{
      backgroundColor: "#212121"
    }
  },
  obtenirButtonPrix:{
    width: 122,
    height: 40,
    borderRadius: 14,
    backgroundColor: "#ffffff",
    color: "#000000",
    textTransform: "none",
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "500",
    fontStyle: "normal",
    padding: 0
  },
  paperFullScreen:{
    marginTop: 130,
    position:"relative",
    overflow: "visible",
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    zIndex: 3000,
    backgroundColor: "#000"
  },
  listItem:{
    color: "#fff",
    textAlign: "center",
    fontSize: 14,
    fontWeight: 500
  },
}));

function HideOnScroll(props) {
  const { children } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
function App(props) {
  const classes = useStyles(props);
  const location = useLocation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = React.useState(false);
  ReactGA.initialize('UA-183387285-1');
const browserHistory = createBrowserHistory()
browserHistory.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search)
})
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [browserHistory])
  function handleClickOpen(){
    setOpen(true);
  };

  function handleClose(){
    setOpen(false);
  };


  useEffect(() => {
    window.scrollTo(0, 0);
    setOpen(false)
  }, [location]);
  function appbarStyleBuilder(path) {
    let style;
    switch (path) {
      case "/":
        style = {
          backgroundColor: "transparent",
        };
        break;
      case "/prix":
        style = {
          backgroundColor: "transparent",
        };
      break;
      default:
        style = style = {
          backgroundColor: "transparent",
        };
    }
    return style;
  }
  function goToApp(){
    window.open('https://app.qibish.com', '_blank')
  }
  function goToAppPaymentMensuel(){
    window.open('https://app.qibish.com/?fromWebsite=true&selectedOffer=monthly', '_blank')
  }
  function goToAppPaymentAnnuel(){
    window.open('https://app.qibish.com/?fromWebsite=true&selectedOffer=yearly', '_blank')
  }
  return (
    <React.Fragment>
       <CssBaseline /> 
       {location.pathname === '/' ||
       location.pathname === '/solution' ||
       location.pathname === '/prix' ||
       location.pathname === '/mission' ||
       location.pathname === '/contact' ||
       location.pathname === '/cgu' ||
       location.pathname === '/mentions' ||
       location.pathname === '/confidentialite'
       ?
       <>
      <HideOnScroll {...props}>
        <AppBar
          elevation={0}
          className={classes.appbar}
          style={appbarStyleBuilder(location.pathname)}
        >
          
          <Toolbar
            style={mobile ?{ height: "100%", paddingLeft: 24, paddingRight: 24 }:{ height: "100%", paddingLeft: 80, paddingRight: 80 }}
          >
            <Grid
              container
              justify="space-between"
              alignItems="center"
              style={{ height: "100%" }}
            >
              <Grid item style={{ cursor: "pointer" }} component={RouterLink} to="/">
                <LogoCompletSvg
                  height={27}
                  width={86}
                  color={location.pathname === '/prix' || location.pathname === '/contact'  ? "#ffffff": "#000000"}
                />
              </Grid>
              <Grid item>
                {mobile ?

                  <IconButton onClick={() => handleClickOpen()}>
                     <MenuBlackIcon taille={24} color={location.pathname === '/prix' || location.pathname === '/contact'  ? "#ffffff": "#000000"} />
                  </IconButton>
                   
                  

:
                <Grid container alignItems="center" spacing={0}>
                  <Grid item>
                    <Typography className={location.pathname === '/prix' || location.pathname === '/contact' ? classes.typoNavPrix:classes.typoNav} component={RouterLink} to="/solution">Solution</Typography>
                  </Grid>
                  <Grid item style={{paddingLeft: 32}}>
                    <Typography className={location.pathname === '/prix' || location.pathname === '/contact' ? classes.typoNavPrix:classes.typoNav} component={RouterLink} to="/prix">Prix</Typography>
                  </Grid>
                  <Grid item style={{paddingLeft: 32}}>
                    <Typography className={location.pathname === '/prix' || location.pathname === '/contact'  ? classes.typoNavPrix:classes.typoNav} component={RouterLink} to="/mission">Mission</Typography>
                  </Grid>
                  <Grid item style={{paddingLeft: 32}}>
                    <Typography className={location.pathname === '/prix' || location.pathname === '/contact'  ? classes.typoNavPrix:classes.typoNav} onClick={() => goToApp()}>Se connecter</Typography>
                  </Grid>
                  <Grid item style={{paddingLeft: 32}}>
                    <Button
                    variant="contained"
                    className={location.pathname === '/prix' || location.pathname === '/contact'  ? classes.obtenirButtonPrix:classes.obtenirButton}
                    disableElevation
                    onClick={() => goToAppPaymentAnnuel()}
                    >
                    Obtenir Qibish
                    </Button>
                  </Grid>
                </Grid>                
          
                }

              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </HideOnScroll>   
      </>    
      :null}

<Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} classes={{paperFullScreen:classes.paperFullScreen}}>
    
            <IconButton 
            onClick={handleClose} 
            aria-label="close" 
            style={{
              position: "absolute", 
              top:-56,
              left: "calc(50% - 24px)",
              backgroundColor: "#000",
              color: "#fff"
            }}
            >
              <CloseIcon />
            </IconButton>

         
       
        <List>
          <ListItem button component={RouterLink} to="/">
            <ListItemText primary="Accueil" className={classes.listItem} />
          </ListItem>
          <Divider style={{backgroundColor: "#202020"}} />
          <ListItem button component={RouterLink} to="/solution">
            <ListItemText primary="Solution" className={classes.listItem} />
          </ListItem>
          <Divider style={{backgroundColor: "#202020"}} />
          <ListItem button component={RouterLink} to="/prix">
            <ListItemText primary="Prix" className={classes.listItem} />
          </ListItem>
          <Divider style={{backgroundColor: "#202020"}} />
          <ListItem button component={RouterLink} to="/mission">
            <ListItemText primary="Mission" className={classes.listItem} />
          </ListItem>
          <Divider style={{backgroundColor: "#202020"}} />
          <ListItem button component={RouterLink} to="/contact">
            <ListItemText primary="Contact" className={classes.listItem} />
          </ListItem>
          <Divider style={{backgroundColor: "#202020"}} />
          <ListItem button>
            <ListItemText primary="Se connecter" className={classes.listItem} />
          </ListItem>
          <Divider style={{backgroundColor: "#202020"}} />
        </List>
      </Dialog>
     
        <Switch>
          <Route exact path="/solution" component={props => <Solution key={location.pathname} location={location} goToApp={goToApp} goToAppPaymentAnnuel={goToAppPaymentAnnuel} goToAppPaymentMensuel={goToAppPaymentMensuel} {...props}/>} />
          <Route exact path="/prix" component={props => <Prix key={location.pathname} location={location}goToApp={goToApp} goToAppPaymentAnnuel={goToAppPaymentAnnuel} goToAppPaymentMensuel={goToAppPaymentMensuel} {...props}/>}/>
          <Route exact path="/mission" component={props => <Mission key={location.pathname} location={location}goToApp={goToApp} goToAppPaymentAnnuel={goToAppPaymentAnnuel} goToAppPaymentMensuel={goToAppPaymentMensuel} {...props}/>}/>
          <Route exact path="/contact" component={props => <Contact key={location.pathname} location={location}goToApp={goToApp} goToAppPaymentAnnuel={goToAppPaymentAnnuel} goToAppPaymentMensuel={goToAppPaymentMensuel} {...props}/>}/>
          <Route exact path="/cgu" component={props => <CGU key={location.pathname} location={location}goToApp={goToApp} goToAppPaymentAnnuel={goToAppPaymentAnnuel} goToAppPaymentMensuel={goToAppPaymentMensuel} {...props}/>}/>
          <Route exact path="/mentions" component={props => <Mentions key={location.pathname} location={location}goToApp={goToApp} goToAppPaymentAnnuel={goToAppPaymentAnnuel} goToAppPaymentMensuel={goToAppPaymentMensuel} {...props}/>}/>
          <Route exact path="/confidentialite" component={props => <Confidentialite key={location.pathname} location={location}goToApp={goToApp} goToAppPaymentAnnuel={goToAppPaymentAnnuel} goToAppPaymentMensuel={goToAppPaymentMensuel} {...props}/>}/>
          <Route exact path="/" component={props => <Home key={location.pathname} goToApp={goToApp} goToAppPaymentAnnuel={goToAppPaymentAnnuel} goToAppPaymentMensuel={goToAppPaymentMensuel} location={location} {...props} />}/>
          <Route  path="*" component={props => <FourOFour key={location.pathname} goToApp={goToApp} goToAppPaymentAnnuel={goToAppPaymentAnnuel} goToAppPaymentMensuel={goToAppPaymentMensuel} location={location} {...props}/>}/>
        </Switch>
    
    </React.Fragment>
  );
}
export default withRouter(App)
