import React, { useState, useRef  } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withRouter, Link as RouterLink } from "react-router-dom";
import { Grid, Typography, Button, Paper, InputBase, Collapse, IconButton } from "@material-ui/core";
import ExoIconSwitch from "./exo_icon_switch";
import useWindowSize from "./useWindowSize";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import C1S1_1 from "./images/solution-C1S1-1.png"
import C1S1_2 from "./images/solution-C1S1-2.png"
import C1S1_3 from "./images/solution-C1S1-3.png"
import C1S2_1 from "./images/solution-C1S2-1.png"
import C1S2_2 from "./images/solution-C1S2-2.png"
import C1S2_3 from "./images/solution-C1S2-3.png"
import C1S2_4 from "./images/solution-C1S2-4.png"
import C1S3_1 from "./images/solution-C1S3-1.png"
import C1S3_2 from "./images/solution-C1S3-2.png"
import C1S3_3 from "./images/solution-C1S3-3.png"
import C1S3_4 from "./images/solution-C1S3-4.png"
import C2S1_1 from "./images/solution-C2S1-1.png"
import C2S1_2 from "./images/solution-C2S1-2.png"
import C2S1_3 from "./images/solution-C2S1-3.png"
import C2S1_4 from "./images/solution-C2S1-4.png"
import C2S1_5 from "./images/solution-C2S1-5.png"
import C2S1_6 from "./images/solution-C2S1-6.png"
import C2S2_1 from "./images/solution-C2S2-1.png"
import C2S2_2 from "./images/solution-C2S2-2.png"
import C2S3_1 from "./images/solution-C2S3-1.png"
import C2S3_2 from "./images/solution-C2S3-2.png"
import C3S1_1 from "./images/solution-C3S1-1.png"
import C3S1_2 from "./images/solution-C3S1-2.png"
import C3S1_3 from "./images/solution-C3S1-3.png"
import C3S1_4 from "./images/solution-C3S1-4.png"
import C3S1_5 from "./images/solution-C3S1-5.png"
import C3S2_1 from "./images/solution-C3S2-1.png"
import C3S3_1 from "./images/solution-C3S3-1.png"
import C3S3_2 from "./images/solution-C3S3-2.png"
import roue from "./images/roue.png"
import {
  Impecc2,
  Blog,
  LogoBlanc,
} from "./icons";
import { Alert } from '@material-ui/lab'
import CloseIcon from '@material-ui/icons/Close';
import axios from "axios"

const ligne1 = [
  "Input2fcs",
  "Input3css",
  "Input4KISS",
  "Input4L",
  "Input4swot",
  "Input4back",
  "Intro",
  "Cards3gen",
  "Cards3rules",

  "Collecte3action",
  "Collecte3req",
  "Collecte3obj",
  "Collecte4mag",
  "Select",
  "Vote",
  "PrioQW",
  "Prioui",
  "Conclusionroti",
  "Conclusionconf",
  "Collecte2risque",
  "Présentation",
  "Collecte2brainstorming",
  "Collecte3risque",
  "Collecte4idea",
  "Collecte4portrait",
  "Input2feedpj",
  "Input2feedws",
  "Input3daily",
  "Input4discovery",
  "Input4hebdo",
  "Input4kanban",
  "Input4mix",
  "Input4ocean",
  "Input5onetone",
  "Input6bono",
  "Input6empathy",
  "Input6personae",
  "Input6pestel",
  "Input8canvas",
  "Priocb",
  "PrioRC",
];
const ligne2 = [
  "Input4kanban",
  "Input4discovery",
  "Collecte2risque",
  "Présentation",
  "Input2feedpj",
  "Priocb",
  "Input4hebdo",
  "Collecte4idea",
  "Input8canvas",
  "Input4L",
  "Collecte4mag",
  "PrioQW",
  "Collecte4portrait",
  "Input4swot",
  "Input6bono",
  "Cards3rules",
  "Cards3gen",
  "Input3css",
  "Conclusionroti",
  "Collecte3risque",
  "Conclusionconf",
  "Input4mix",
  "Prioui",
  "Collecte2brainstorming",
  "Collecte3obj",
  "Input3daily",
  "Input6empathy",
  "Input4KISS",
  "Input2feedws",
  "Input6personae",
  "Select",
  "PrioRC",
  "Vote",
  "Input2fcs",
  "Collecte3action",
  "Collecte3req",
  "Input4back",
  "Input5onetone",
  "Input6pestel",
  "Input4ocean",
  "Intro",
];
const ligne3 = [
  "Intro",
  "Input5onetone",
  "Input2fcs",
  "Vote",
  "Collecte3obj",
  "Collecte2brainstorming",
  "Input2feedpj",
  "Cards3gen",
  "Input6bono",
  "Présentation",
  "Collecte3risque",
  "PrioRC",
  "Input4L",
  "Collecte3action",
  "Conclusionroti",
  "Input4discovery",
  "Collecte4mag",
  "Input4swot",
  "Input4hebdo",
  "Collecte3req",
  "Input4kanban",
  "Input2feedws",
  "Input8canvas",
  "Input3daily",
  "Priocb",
  "Input4mix",
  "Conclusionconf",
  "Input6empathy",
  "Input6personae",
  "Prioui",
  "Input4ocean",
  "Collecte2risque",
  "Select",
  "Input3css",
  "Input4KISS",
  "PrioQW",
  "Cards3rules",
  "Input4back",
  "Collecte4portrait",
  "Input6pestel",
  "Collecte4idea",
];
const roueTitles = [
  "prévisualisation de l'atelier", "upload présentation", "invitation des participants", "gestion des équipes", "sur place ou à distance", "mobile first", "temps réel", "gestion des pauses", "tutoriel intéractif"
]
const useStyles = makeStyles((theme) => ({
  main: {
    overflow: "hidden",
    minHeight: 10003,
    backgroundColor: "#f2f1e9",
  },
  mainTop: {
    overflow: "hidden",
    minHeight: 1042,
    background: "linear-gradient(to bottom, #ffffff 33%, #f2f1e9 67%)",
    [theme.breakpoints.down('md')]:{
      minHeight: 950,
    }
  },
  divTop: {
    height: 206,
    [theme.breakpoints.down('md')]:{
      height: 100,
    }
  },
  gridTopSubHeader: {
    marginTop: 24,
  },
  gridRoue:{
    backgroundColor: "#fff",
    marginTop: 400,
    height: 900,
    position:"relative",
    overflow: "visible"
  },
  gridChiffres:{
    height: 700,
    paddingTop: 250,
    backgroundColor: "#ffe72f",
    position: "relative",
    overflow: "visible",
    [theme.breakpoints.down('md')]:{
      paddingTop: 150,
      paddingBottom: 150,
      height: 800,
    }
  },
  gridChiffreFillerTopLeft:{
    position: "absolute",
    top: -160,
    left: 0,
    height: 160,
    width: 160,
    backgroundColor: "#ffe72f",
  },
  gridChiffreFillerTopLeft2:{
    position: "absolute",
    top: -160,
    left: 0,
    height: 160,
    width: 160,
    backgroundColor: "#fff",
    borderBottomLeftRadius: 160
  },
  gridChiffreFillerTopRight:{
    position: "absolute",
    top: 0,
    right: 0,
    height: 160,
    width: 160,
    backgroundColor: "#ffe72f",
    borderTopRightRadius: 160
  },
  gridChiffreFillerTopRight2:{
    position: "absolute",
    top: 0,
    right: 0,
    height: 160,
    width: 160,
    backgroundColor: "#fff",

  },
  gridFooter: {
    backgroundColor: "#000000",
    height: 800,
    position: "relative",
    overflow: "visible",
    [theme.breakpoints.down('md')]:{
      height: 600,
    }
  },
  typoTop: {
    fontFamily: "DM Serif Display",
    fontSize: 56,
    fontWeight: "normal",
    fontStyle: "normal",
    letterSpacing: 0.5,
    textAlign: "center",
    color: "#000000",
    lineHeight: 1,
    [theme.breakpoints.down('md')]:{
      fontSize: 46,
      display: "inline",
      position: "relative"
    }
  },
  typoTopHighlight: {
    fontFamily: "DM Serif Display",
    fontSize: 56,
    fontWeight: "normal",
    fontStyle: "normal",
    letterSpacing: 0.5,
    textAlign: "center",
    color: "#000000",
    background: "linear-gradient(to top, #ffe72f 50%, transparent 50%)",
    lineHeight: 1.2,
    [theme.breakpoints.down('md')]:{
      fontSize: 46,
      display: "inline",
      position: "relative"
    }
  },
  typoTopSubHeader: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: 1.65,
    letterSpacing: 0,
    textAlign: "center",
    color: "#030303",
    maxWidth: 464
  },
  ligne1Before: {
    display: "flex",
    top: 120,
    left: -25,
    position: "absolute",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "1s",
    }),
  },
  ligne1After: {
    display: "flex",
    top: 120,
    left: -25,
    transform: "translateX(-400px)",
    position: "absolute",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "1s",
    }),
  },
  ligne2Before: {
    display: "flex",
    marginTop: 235,
    left: -450,
    position: "absolute",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "1s",
    }),
  },
  ligne2After: {
    display: "flex",
    marginTop: 235,
    left: -450,
    transform: "translateX(400px)",
    position: "absolute",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "1s",
    }),
  },
  ligne3Before: {
    display: "flex",
    top: 350,
    left: -105,
    position: "absolute",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "1s",
    }),
  },
  ligne3After: {
    display: "flex",
    top: 350,
    left: -105,
    transform: "translateX(-460px)",
    position: "absolute",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "1s",
    }),
  },
  typoNumero: {
    width: 48,
    height: 48,
    backgroundColor: "#ffe72f",
    borderRadius: "50%",
    fontFamily: "DM Serif Display",
    fontSize: 26,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.31,
    letterSpacing: 0.5,
    textAlign: "center",
    color: "#000000",
    paddingTop: 6,
  },
  typoHeader: {
    fontFamily: "DM Serif Display",
    fontSize: 56,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.1,
    letterSpacing: 0.5,
    textAlign: "center",
    color: "#000000",
    [theme.breakpoints.down('md')]:{
      fontSize: 40,
    }
  },
  highlight: {
    background: "linear-gradient(to top, #ffe72f 50%, transparent 50%)",
  },
  textHidden: {
    visibility: "hidden",
    opacity: 0,
    marginTop: 50,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
  },
  textShow: {
    visibility: "visible",
    opacity: 1,
    marginTop: 0,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
  },
  typoSubHeader: {
    fontFamily: "Roboto",
    fontSize: 18,
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: 1.56,
    letterSpacing: 0,
    textAlign: "center",
    color: "#000000",
    whiteSpace: "pre-line",
  },
  typoItemHeader: {
    whiteSpace: "pre-line",
    fontFamily: "DM Serif Display",
    fontSize: 26,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.23,
    letterSpacing: 0.5,
    color: "#000000",
  },
  typoItemText: {
    whiteSpace: "pre-line",
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.71,
    letterSpacing: 0,
    color: "#505049"
  },
  typoChiffresHeader:{
    display: "inline-block",
    fontFamily: "DM Serif Display",
    fontSize: 120,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 0.7,
    letterSpacing: 0,
    textAlign: "center",
    color: "#000000",
    background: "linear-gradient(to top, #fff 50%, transparent 50%)",

  },
  typoChiffresBody:{
    marginTop: 32,
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: 0,
    color: "#000000",
    maxWidth: 140
  
  },
  C1S1_1:{
    visibility: "visible",
    opacity:1,
    position: "absolute",
    top:0,
    right:0,
    zIndex: 3,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
    [theme.breakpoints.down('md')]:{
      right: "calc(50% - 200px)"
    }
  },
  C1S1_2:{
    visibility: "visible",
    opacity:1,
    position: "absolute",
    top:70,
    right:145,
    zIndex: 2,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
    transitionDelay: "0.3s",
    [theme.breakpoints.down('md')]:{
      right: "calc(50% - 130px)"
    }
  },
  C1S1_3:{
    visibility: "visible",
    opacity:1,
    position: "absolute",
    top:140,
    right:240,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
    transitionDelay: "0.6s",
    [theme.breakpoints.down('md')]:{
      right: "calc(50% - 100px)"
    }
  },
  C1S1_1Hidden:{
    visibility: "hidden",
    opacity:0,
    position: "absolute",
    top:200,
    right:0,
    zIndex: 3,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
  },
  C1S1_2Hidden:{
    visibility: "hidden",
    opacity:0,
    position: "absolute",
    top:270,
    right:145,
    zIndex: 2,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
  },
  C1S1_3Hidden:{
    visibility: "hidden",
    opacity:0,
    position: "absolute",
    top:340,
    right:240,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
  },
  C1S2_1:{
    visibility: "visible",
    opacity:1,
    position: "absolute",
    top:0,
    left:0,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
    [theme.breakpoints.down('md')]:{
      left: "calc(50% - 150px)"
    }
  },
  C1S2_2:{
    visibility: "visible",
    opacity:1,
    position: "absolute",
    top:16,
    left:43,
    zIndex: 2,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
    transitionDelay: "0.2s",
    [theme.breakpoints.down('md')]:{
      left: "calc(50% - 128px)",
      top: 8
    }
  },
  C1S2_3:{
    visibility: "visible",
    opacity:1,
    position: "absolute",
    top:120,
    left: 43,
    zIndex: 3,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
    transitionDelay: "0.4s",
    [theme.breakpoints.down('md')]:{
      left: "calc(50% - 128px)",
      top: 72
    }
  },
  C1S2_4:{
    visibility: "visible",
    opacity:1,
    position: "absolute",
    top:224,
    left:43,
    zIndex: 4,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
    transitionDelay: "0.6s",
    [theme.breakpoints.down('md')]:{
      left: "calc(50% - 128px)",
      top: 136

    }
  },
  C1S2_1Hidden:{
    visibility: "hidden",
    opacity:0,
    position: "absolute",
    top:200,
    left:0,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
  },
  C1S2_2Hidden:{
    visibility: "hidden",
    opacity:0,
    position: "absolute",
    top:216,
    left:43,
    zIndex: 2,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
  },
  C1S2_3Hidden:{
    visibility: "hidden",
    opacity:0,
    position: "absolute",
    top:320,
    left: 43,
    zIndex: 3,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
  },
  C1S2_4Hidden:{
    visibility: "hidden",
    opacity:0,
    position: "absolute",
    top:424,
    left:43,
    zIndex: 4,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
  },
  C1S3_1:{
    visibility: "visible",
    opacity:1,
    position: "absolute",
    top:0,
    right:0,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
    [theme.breakpoints.down('md')]:{
      right: "calc(50% - 204px)"
    }
  },
  C1S3_2:{
    visibility: "visible",
    opacity:1,
    position: "absolute",
    top:30,
    right:61,
    zIndex: 2,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
    transitionDelay: "0.2s",
    [theme.breakpoints.down('md')]:{
      right: "calc(50% - 144px)"
    }
  },
  C1S3_3:{
    visibility: "visible",
    opacity:1,
    position: "absolute",
    top:190,
    right:61,
    zIndex: 3,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
    transitionDelay: "0.4s",
    [theme.breakpoints.down('md')]:{
      right: "calc(50% - 144px)"
    }
  },
  C1S3_4:{
    visibility: "visible",
    opacity:1,
    position: "absolute",
    top:350,
    right:61,
    zIndex: 4,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
    transitionDelay: "0.6s",
    [theme.breakpoints.down('md')]:{
      right: "calc(50% - 144px)"
    }
  },
  C1S3_1Hidden:{
    visibility: "hidden",
    opacity:0,
    position: "absolute",
    top:200,
    right:0,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
  },
  C1S3_2Hidden:{
    visibility: "hidden",
    opacity:0,
    position: "absolute",
    top:230,
    right:61,
    zIndex: 2,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
  },
  C1S3_3Hidden:{
    visibility: "hidden",
    opacity:0,
    position: "absolute",
    top:390,
    right:61,
    zIndex: 3,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
  },
  C1S3_4Hidden:{
    visibility: "hidden",
    opacity:0,
    position: "absolute",
    top:550,
    right:61,
    zIndex: 4,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
  },
  C2S1_1:{
    visibility: "visible",
    opacity:1,
    position: "absolute",
    top:0,
    right:0,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
    [theme.breakpoints.down('md')]:{
      right: "calc(50% - 176px)"
    }
  },
  C2S1_2:{
    visibility: "visible",
    opacity:1,
    position: "absolute",
    top:48,
    right:0,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
    transitionDelay: "0.1s",
    [theme.breakpoints.down('md')]:{
      right: "calc(50% - 176px)"
    }
  },
  C2S1_3:{
    visibility: "visible",
    opacity:1,
    position: "absolute",
    top:96,
    right:0,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
    transitionDelay: "0.2s",
    [theme.breakpoints.down('md')]:{
      right: "calc(50% - 176px)"
    }
  },
  C2S1_4:{
    visibility: "visible",
    opacity:1,
    position: "absolute",
    top:144,
    right:0,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
    transitionDelay: "0.3s",
    [theme.breakpoints.down('md')]:{
      right: "calc(50% - 176px)"
    }
  },
  C2S1_5:{
    visibility: "visible",
    opacity:1,
    position: "absolute",
    top:192,
    right:0,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
    transitionDelay: "0.4s",
    [theme.breakpoints.down('md')]:{
      right: "calc(50% - 176px)"
    }
  },
  C2S1_6:{
    visibility: "visible",
    opacity:1,
    position: "absolute",
    top:240,
    right:0,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
    transitionDelay: "0.5s",
    [theme.breakpoints.down('md')]:{
      right: "calc(50% - 176px)"
    }
  },
  C2S1_1Hidden:{
    visibility: "hidden",
    opacity:0,
    position: "absolute",
    top:200,
    right:0,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
  },
  C2S1_2Hidden:{
    visibility: "hidden",
    opacity:0,
    position: "absolute",
    top:248,
    right:0,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
  },
  C2S1_3Hidden:{
    visibility: "hidden",
    opacity:0,
    position: "absolute",
    top:296,
    right:0,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
  },
  C2S1_4Hidden:{
    visibility: "hidden",
    opacity:0,
    position: "absolute",
    top:344,
    right:0,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
  },
  C2S1_5Hidden:{
    visibility: "hidden",
    opacity:0,
    position: "absolute",
    top:392,
    right:0,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
  },
  C2S1_6Hidden:{
    visibility: "hidden",
    opacity:0,
    position: "absolute",
    top:440,
    right:0,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
  },
  C2S2_1:{
    visibility: "visible",
    opacity:1,
    position: "absolute",
    top:360,
    left:50,
    zIndex: 2,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
    [theme.breakpoints.down('md')]:{
      left: "calc(50% - 180px)"
    }
  },
  C2S2_2:{
    visibility: "visible",
    opacity:1,
    position: "absolute",
    top:0,
    left:50,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
    [theme.breakpoints.down('md')]:{
      left: "calc(50% - 180px)"
    }
  },
  C2S2_1Hidden:{
    visibility: "hidden",
    opacity:0,
    position: "absolute",
    top:560,
    left:50,
    zIndex: 2,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
  },
  C2S2_2Hidden:{
    visibility: "hidden",
    opacity:0,
    position: "absolute",
    top:200,
    left:50,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
  },
  C2S3_1:{
    visibility: "visible",
    opacity:1,
    position: "absolute",
    top:0,
    right:0,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
    [theme.breakpoints.down('md')]:{
      right: "calc(50% - 200px)"
    }
  },
  C2S3_2:{
    visibility: "visible",
    opacity:1,
    position: "absolute",
    top:50,
    right:-75,
    zIndex: 2,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
    transitionDelay: "0.3s",
    [theme.breakpoints.down('md')]:{
      right: "calc(50% - 200px)",
      top: 30
    }
  },
  C2S3_1Hidden:{
    visibility: "hidden",
    opacity:0,
    position: "absolute",
    top:200,
    right:0,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
  },
  C2S3_2Hidden:{
    visibility: "hidden",
    opacity:0,
    position: "absolute",
    top:250,
    right:-75,
    zIndex: 2,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
  },
  C3S1_1:{
    visibility: "visible",
    opacity:1,
    position: "absolute",
    top:10,
    right:450,
    zIndex: 2,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
    [theme.breakpoints.down('md')]:{
      right: "calc(50% + 115px)",
      top: 30
    }

  },
  C3S1_2:{
    visibility: "visible",
    opacity:1,
    position: "absolute",
    top:0,
    right:0,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
    transitionDelay: "0.1s",
    [theme.breakpoints.down('md')]:{
      right: "calc(50% - 205px)",

    }
  },
  C3S1_3:{
    visibility: "visible",
    opacity:1,
    position: "absolute",
    top:72,
    right:10,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
    transitionDelay: "0.2s",
    [theme.breakpoints.down('md')]:{
      right: "calc(50% - 195px)",
      top: 50
    }
  },
  C3S1_4:{
    visibility: "visible",
    opacity:1,
    position: "absolute",
    top:144,
    right:0,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
    transitionDelay: "0.3s",
    [theme.breakpoints.down('md')]:{
      right: "calc(50% - 205px)",
      top: 100
    }
  },
  C3S1_5:{
    visibility: "visible",
    opacity:1,
    position: "absolute",
    top:216,
    right:10,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
    transitionDelay: "0.4s",
    [theme.breakpoints.down('md')]:{
      right: "calc(50% - 195px)",
      top: 150
    }
  },
  C3S1_1Hidden:{
    visibility: "hidden",
    opacity:0,
    position: "absolute",
    top:210,
    right:450,
    zIndex: 2,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
  },
  C3S1_2Hidden:{
    visibility: "hidden",
    opacity:0,
    position: "absolute",
    top:200,
    right:0,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
  },
  C3S1_3Hidden:{
    visibility: "hidden",
    opacity:0,
    position: "absolute",
    top:272,
    right:10,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
  },
  C3S1_4Hidden:{
    visibility: "hidden",
    opacity:0,
    position: "absolute",
    top:344,
    right:0,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
  },
  C3S1_5Hidden:{
    visibility: "hidden",
    opacity:0,
    position: "absolute",
    top:416,
    right:10,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
  },
  C3S2_1:{
    visibility: "visible",
    opacity:1,
    position: "absolute",
    top:20,
    left:-60,
    zIndex: 2,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
    [theme.breakpoints.down('md')]:{
      left: "calc(50% - 225px)",

    }
  },
  C3S2_1Hidden:{
    visibility: "hidden",
    opacity:0,
    position: "absolute",
    top:220,
    left:-60,
    zIndex: 2,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
  },
  C3S3_1:{
    visibility: "visible",
    opacity:1,
    position: "absolute",
    top:0,
    right:100,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
    [theme.breakpoints.down('md')]:{
      right: "calc(50% - 110px)",

    }
  },
  C3S3_2:{
    visibility: "visible",
    opacity:1,
    position: "absolute",
    top:150,
    right:-75,
    zIndex: 2,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
    transitionDelay: "0.3s",
    [theme.breakpoints.down('md')]:{
      right: "calc(50% - 190px)",
      top: 100

    }
  },
  C3S3_1Hidden:{
    visibility: "hidden",
    opacity:0,
    position: "absolute",
    top:200,
    right:100,
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
  },
  C3S3_2Hidden:{
    visibility: "hidden",
    opacity:0,
    position: "absolute",
    top:350,
    right:-75,
    zIndex: 2,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.5s",
    }),
  },
  bandeauBlanc1: {
    position: "absolute",
    top: -35,
    height: 30,
    width: "100%",
    backgroundColor: "#ffffff",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauBlanc2: {
    position: "absolute",
    top: -67,
    height: 25,
    width: "100%",
    backgroundColor: "#ffffff",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauBlanc3: {
    position: "absolute",
    top: -92,
    height: 15,
    width: "100%",
    backgroundColor: "#ffffff",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauBlanc4: {
    position: "absolute",
    top: -112,
    height: 5,
    width: "100%",
    backgroundColor: "#ffffff",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauBlanc1Hidden: {
    position: "absolute",
    top: 0,
    height: 30,
    width: "100%",
    backgroundColor: "#ffffff",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  bandeauBlanc2Hidden: {
    position: "absolute",
    top: 0,
    height: 25,
    width: "100%",
    backgroundColor: "#ffffff",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  bandeauBlanc3Hidden: {
    position: "absolute",
    top: 0,
    height: 15,
    width: "100%",
    backgroundColor: "#ffffff",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  bandeauBlanc4Hidden: {
    position: "absolute",
    top: 0,
    height: 5,
    width: "100%",
    backgroundColor: "#ffffff",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir1: {
    position: "absolute",
    top: -35,
    height: 30,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir2: {
    position: "absolute",
    top: -67,
    height: 25,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir3: {
    position: "absolute",
    top: -92,
    height: 15,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir4: {
    position: "absolute",
    top: -112,
    height: 5,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir1Hidden: {
    position: "absolute",
    top: 0,
    height: 30,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir2Hidden: {
    position: "absolute",
    top: 0,
    height: 25,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir3Hidden: {
    position: "absolute",
    top: 0,
    height: 15,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir4Hidden: {
    position: "absolute",
    top: 0,
    height: 5,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  roueFond: {
    position: "absolute",
    top: "calc(50% - 273px)",
    left: "calc(50% - 478px)"
  },
  roueStyle0:{
    position: "absolute",
    top: 140,
    fontSize: 20,
    fontFamily: "DM Serif Display",
    width: "100%",
    textAlign: "center",
    opacity: 0.2,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.2s",
    }),
    [theme.breakpoints.down('md')]:{
      fontSize: 14,
    }
  },
  roueStyle1:{
    position: "absolute",
    top: 190,
    fontSize: 24,
    fontFamily: "DM Serif Display",
    width: "100%",
    textAlign: "center",
    opacity: 0.4,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.2s",
    }),
    [theme.breakpoints.down('md')]:{
      fontSize: 18,
    }
  },
  roueStyle2:{
    position: "absolute",
    top: 250,
    fontSize: 28,
    fontFamily: "DM Serif Display",
    width: "100%",
    textAlign: "center",
    opacity: 0.6,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.2s",
    }),
    [theme.breakpoints.down('md')]:{
      fontSize: 22,
    }
  },
  roueStyle3:{
    position: "absolute",
    top: 310,
    fontSize: 32,
    fontFamily: "DM Serif Display",
    width: "100%",
    textAlign: "center",
    opacity: 0.7,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.2s",
    }),
    [theme.breakpoints.down('md')]:{
      fontSize: 26,
    }
  },
  roueStyle4:{
    position: "absolute",
    top: 400,
    fontSize: 46,
    fontFamily: "DM Serif Display",
    width: "100%",
    textAlign: "center",
    opacity: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.2s",
    }),
    [theme.breakpoints.down('md')]:{
      fontSize: 32,
    }
  },
  roueStyle5:{
    position: "absolute",
    top: 500,
    fontSize: 32,
    fontFamily: "DM Serif Display",
    width: "100%",
    textAlign: "center",
    opacity: 0.7,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.2s",
    }),
    [theme.breakpoints.down('md')]:{
      fontSize: 26,
    }
  },
  roueStyle6:{
    position: "absolute",
    top: 570,
    fontSize: 28,
    fontFamily: "DM Serif Display",
    width: "100%",
    textAlign: "center",
    opacity: 0.6,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.2s",
    }),
    [theme.breakpoints.down('md')]:{
      fontSize: 22,
    }
  },
  roueStyle7:{
    position: "absolute",
    top: 630,
    fontSize: 24,
    fontFamily: "DM Serif Display",
    width: "100%",
    textAlign: "center",
    opacity: 0.4,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.2s",
    }),
    [theme.breakpoints.down('md')]:{
      fontSize: 18,
    }
  },
  roueStyle8:{
    position: "absolute",
    top: 680,
    fontSize: 20,
    fontFamily: "DM Serif Display",
    width: "100%",
    textAlign: "center",
    opacity: 0.2,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.2s",
    }),
    [theme.breakpoints.down('md')]:{
      fontSize: 14,
    }
  },
  footerImpecc: {
    width: 586,
    height: 720,
    position: "absolute",
    top: 0,
    right: 0,
    overflow: "hidden",
    objectFit: "contain",
  },
  typoFooter: {
    fontFamily: "DM Serif Display",
    fontSize: 48,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.08,
    letterSpacing: 0.43,
    color: "#ffffff",
    maxWidth: 600,
    [theme.breakpoints.down('md')]:{
      textAlign:"center",
      fontSize: 36
    }
  },
  typoLiensFooter: {
    opacity: 0.64,
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: 1.17,
    letterSpacing: 0,
    color: "#ffffff",
    cursor: "pointer",
    textDecoration: "none",
  },
  typoFooterMentions: {
    opacity: 0.4,
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.67,
    letterSpacing: 0,
    textAlign: "right",
    color: "#ffffff",
    cursor: "pointer",
    textDecoration: "none",
  },
  buttonGo: {
    width: 240,
    height: 240,
    borderRadius: 120,
    backgroundColor: "#000000",
    color: "#ffffff",
    fontFamily: "DM Serif Display",
    fontSize: 48,
    zIndex: 200,
    "&:hover ": {
      backgroundColor: "#212121",
    },
  },
  buttonGoFooter: {
    width: 240,
    height: 240,
    borderRadius: 120,
    backgroundColor: "#ffe72f",
    color: "#000000",
    fontFamily: "DM Serif Display",
    fontSize: 48,
    zIndex: 200,
    "&:hover ": {
      backgroundColor: "rgb(178, 161, 32)",
    },
    [theme.breakpoints.down('md')]:{
      width: 180,
      height: 180,
      borderRadius: 90,
      fontSize: 36,
    }
  },
  footerBlog: {
    position: "absolute",
    top: -14,
    left: -15,
  },
}));
function Solution(props) {
  const classes = useStyles(props);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const windowSize = useWindowSize();
  const [exoDance, setExoDance] = useState(false);
  const exoLigneRef = useRef();
  const [grid1, setGrid1] = useState(false);
  const [grid1bis, setGrid1bis] = useState(false);
  const [gridC1S1R, setGridC1S1R] = useState(false);
  const [gridC1S1L, setGridC1S1L] = useState(false);
  const [gridC1S2R, setGridC1S2R] = useState(false);
  const [gridC1S2L, setGridC1S2L] = useState(false);
  const [gridC1S3R, setGridC1S3R] = useState(false);
  const [gridC1S3L, setGridC1S3L] = useState(false);
  const grid1Ref = useRef();
  const grid1bisRef = useRef();
  const gridC1S1RRef = useRef(); 
  const gridC1S1LRef = useRef(); 
  const gridC1S2RRef = useRef(); 
  const gridC1S2LRef = useRef(); 
  const gridC1S3RRef = useRef(); 
  const gridC1S3LRef = useRef(); 
  const [grid2, setGrid2] = useState(false);
  const [grid2bis, setGrid2bis] = useState(false);
  const [gridC2S1R, setGridC2S1R] = useState(false);
  const [gridC2S1L, setGridC2S1L] = useState(false);
  const [gridC2S2R, setGridC2S2R] = useState(false);
  const [gridC2S2L, setGridC2S2L] = useState(false);
  const [gridC2S3R, setGridC2S3R] = useState(false);
  const [gridC2S3L, setGridC2S3L] = useState(false);
  const grid2Ref = useRef();
  const grid2bisRef = useRef();
  const gridC2S1RRef = useRef(); 
  const gridC2S1LRef = useRef(); 
  const gridC2S2RRef = useRef(); 
  const gridC2S2LRef = useRef(); 
  const gridC2S3RRef = useRef(); 
  const gridC2S3LRef = useRef(); 
  const [grid3, setGrid3] = useState(false);
  const [grid3bis, setGrid3bis] = useState(false);
  const [gridC3S1R, setGridC3S1R] = useState(false);
  const [gridC3S1L, setGridC3S1L] = useState(false);
  const [gridC3S2R, setGridC3S2R] = useState(false);
  const [gridC3S2L, setGridC3S2L] = useState(false);
  const [gridC3S3R, setGridC3S3R] = useState(false);
  const [gridC3S3L, setGridC3S3L] = useState(false);
  const grid3Ref = useRef();
  const grid3bisRef = useRef();
  const gridC3S1RRef = useRef(); 
  const gridC3S1LRef = useRef(); 
  const gridC3S2RRef = useRef(); 
  const gridC3S2LRef = useRef(); 
  const gridC3S3RRef = useRef(); 
  const gridC3S3LRef = useRef(); 
  const [accordeonRoue, setAccordeonRoue] = useState(false);
  const roueRef = useRef();
  const [roueOffsetHeight, setRoueOffsetHeight] = useState(0)
  const [roueOffsetNumber, setRoueOffsetNumber] = useState(10000)
  const [accordeonFooter, setAccordeonFooter] = useState(false);
  const gridFooterRef = useRef();
  const [mail, setMail] = useState("")
  const [errorNewsletter, setErrorNewsletter] = useState(false)
  const [successNewsletter, setSuccessNewsletter] = useState(false)

  function registerNewsletter(mail){
    axios.post("https://www.qibish.com/newsletter", {
      mail:mail
    })
    .then((res) => {setSuccessNewsletter(true); setMail("")})
    .catch((error) => {
      setErrorNewsletter(true)
      setMail("")
    });
  }

  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + 25 < windowSize.height / 3) {
        setExoDance(true);
      } else {
        setExoDance(false);
      }
    },
    [],
    exoLigneRef
  );
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + 250 < windowSize.height) {
        setGrid1(true);
      } else {
        setGrid1(false);
      }
    },
    [],
    grid1Ref
  );
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + 250 < windowSize.height) {
        setGrid1bis(true);
      } else {
        setGrid1bis(false);
      }
    },
    [],
    grid1bisRef
  );
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + 250 < windowSize.height) {
        setGridC1S1R(true);
      } else {
        setGridC1S1R(false);
      }
    },
    [],
    gridC1S1RRef
  );
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + 250 < windowSize.height) {
        setGridC1S1L(true);
      } else {
        setGridC1S1L(false);
      }
    },
    [],
    gridC1S1LRef
  );
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + 250 < windowSize.height) {
        setGridC1S2R(true);
      } else {
        setGridC1S2R(false);
      }
    },
    [],
    gridC1S2RRef
  );
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + 250 < windowSize.height) {
        setGridC1S2L(true);
      } else {
        setGridC1S2L(false);
      }
    },
    [],
    gridC1S2LRef
  );
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + 250 < windowSize.height) {
        setGridC1S3R(true);
      } else {
        setGridC1S3R(false);
      }
    },
    [],
    gridC1S3RRef
  );
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + 250 < windowSize.height) {
        setGridC1S3L(true);
      } else {
        setGridC1S3L(false);
      }
    },
    [],
    gridC1S3LRef
  );
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + 150 < windowSize.height) {
        setGrid2(true);
      } else {
        setGrid2(false);
      }
    },
    [],
    grid2Ref
  );
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + 150 < windowSize.height) {
        setGrid2bis(true);
      } else {
        setGrid2bis(false);
      }
    },
    [],
    grid2bisRef
  );
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + 250 < windowSize.height) {
        setGridC2S1R(true);
      } else {
        setGridC2S1R(false);
      }
    },
    [],
    gridC2S1RRef
  );
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + 250 < windowSize.height) {
        setGridC2S1L(true);
      } else {
        setGridC2S1L(false);
      }
    },
    [],
    gridC2S1LRef
  );
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + 250 < windowSize.height) {
        setGridC2S2R(true);
      } else {
        setGridC2S2R(false);
      }
    },
    [],
    gridC2S2RRef
  );
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + 250 < windowSize.height) {
        setGridC2S2L(true);
      } else {
        setGridC2S2L(false);
      }
    },
    [],
    gridC2S2LRef
  );
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + 250 < windowSize.height) {
        setGridC2S3R(true);
      } else {
        setGridC2S3R(false);
      }
    },
    [],
    gridC2S3RRef
  );
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + 250 < windowSize.height) {
        setGridC2S3L(true);
      } else {
        setGridC2S3L(false);
      }
    },
    [],
    gridC2S3LRef
  );
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + 150 < windowSize.height) {
        setGrid3(true);
      } else {
        setGrid3(false);
      }
    },
    [],
    grid3Ref
  );
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + 150 < windowSize.height) {
        setGrid3bis(true);
      } else {
        setGrid3bis(false);
      }
    },
    [],
    grid3bisRef
  );
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + 250 < windowSize.height) {
        setGridC3S1R(true);
      } else {
        setGridC3S1R(false);
      }
    },
    [],
    gridC3S1RRef
  );
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + 250 < windowSize.height) {
        setGridC3S1L(true);
      } else {
        setGridC3S1L(false);
      }
    },
    [],
    gridC3S1LRef
  );
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + 250 < windowSize.height) {
        setGridC3S2R(true);
      } else {
        setGridC3S2R(false);
      }
    },
    [],
    gridC3S2RRef
  );
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + 250 < windowSize.height) {
        setGridC3S2L(true);
      } else {
        setGridC3S2L(false);
      }
    },
    [],
    gridC3S2LRef
  );
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + 250 < windowSize.height) {
        setGridC3S3R(true);
      } else {
        setGridC3S3R(false);
      }
    },
    [],
    gridC3S3RRef
  );
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + 250 < windowSize.height) {
        setGridC3S3L(true);
      } else {
        setGridC3S3L(false);
      }
    },
    [],
    gridC3S3LRef
  );
  useScrollPosition(
    ({ currPos }) => {
     
      if (currPos.y + 25 < windowSize.height) {
        setAccordeonRoue(true);
      } else {
        setAccordeonRoue(false);
      }
    },
    [],
    roueRef
  );
  useScrollPosition(
    ({prevPos, currPos }) => {
      if (currPos.y - 50 < windowSize.height) {
        if(currPos.y > prevPos.y){
          if(Math.abs((Math.abs(currPos.y) - Math.abs(roueOffsetHeight))) > 60){
             setRoueOffsetNumber(roueOffsetNumber + 1)
             setRoueOffsetHeight(currPos.y)
          }
         
        } else { 
          if(Math.abs((Math.abs(currPos.y) - Math.abs(roueOffsetHeight))) > 60){
             setRoueOffsetNumber(roueOffsetNumber - 1)
             setRoueOffsetHeight(currPos.y)
          }
        }
      } else {
        
      }
    },
    [roueOffsetNumber, roueOffsetHeight],
    roueRef
  );
  useScrollPosition(
    ({ currPos }) => {
      
      if (currPos.y + 25 < windowSize.height) {
        setAccordeonFooter(true);
      } else {
        setAccordeonFooter(false);
      }
    },
    [],
    gridFooterRef
  );
  function roueStyleBuilder(index){

    let newIndex = (roueOffsetNumber + index) % 9
    let style = classes['roueStyle'+newIndex]
    return style
  }
  return (
    <React.Fragment>
      <div className={classes.main}>
        <div className={classes.mainTop}>
          <div className={classes.divTop} />

          <Grid container justify="center">
            <Grid container justify="center" item>
              <Typography className={classes.typoTop}>
                Plus qu’un simple outil,
              </Typography>
            </Grid>
            <Grid
              container
              justify="center"
              item
              style={mobile ? { textAlign: "center" } : {}}
            >
              <Grid>
                <Typography className={classes.typoTopHighlight}>
                  la solution que tu attendais
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justify="center" className={classes.gridTopSubHeader}>
            <Typography className={classes.typoTopSubHeader}>
              {
                "Grâce à Qibish, anime simplement des réunions collaboratives qui réveillent l’innovation et la productivité de tes équipes."
              }
            </Typography>
          </Grid>
          <div style={{ width: "100vw", position: "relative" }}>
            <div
              ref={exoLigneRef}
              className={exoDance ? classes.ligne1After : classes.ligne1Before}
            >
              {ligne1.map((e) => (
                <div style={{ paddingLeft: 6, paddingRight: 6 }}>
                  <ExoIconSwitch variant={e} />
                </div>
              ))}
            </div>
            <div
              className={exoDance ? classes.ligne2After : classes.ligne2Before}
            >
              {ligne2.map((e) => (
                <div style={{ paddingLeft: 6, paddingRight: 6 }}>
                  <ExoIconSwitch variant={e} />
                </div>
              ))}
            </div>
            <div
              className={exoDance ? classes.ligne3After : classes.ligne3Before}
            >
              {ligne3.map((e) => (
                <div style={{ paddingLeft: 6, paddingRight: 6 }}>
                  <ExoIconSwitch variant={e} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <Grid container justify="center" id="prepare">
          <Grid>
            <Typography className={classes.typoNumero}>1</Typography>
          </Grid>
        </Grid>
        <Grid container justify="center" style={{ marginTop: 40 }}>
          <Grid
            className={grid1 ? classes.textShow : classes.textHidden}
            ref={grid1Ref}
          >
            <Typography
              className={classes.typoHeader}
              style={{ maxWidth: 810 }}
            >
              <span className={classes.highlight}>Prépare</span>
              {" des ateliers collaboratifs de bout en bout."}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justify="center" style={{ marginTop: 30 }}>
          <Grid
            className={grid1bis ? classes.textShow : classes.textHidden}
            ref={grid1bisRef}
          >
            <Typography
              className={classes.typoSubHeader}
              style={{ maxWidth: 729 }}
            >
              {
                "Qibish est pensé pour préparer des réunions et ateliers collaboratifs complets, de l’introduction à la conclusion. Plus besoin d’outils et supports externes complémentaires."
              }
            </Typography>
          </Grid>
        </Grid>
        {mobile ? (
          <Grid
            container
            justify="center"
            style={{ position: "relative", padding: 24 }}
          >
            <Grid
              xs={12}
              style={{ position: "relative", height: 440 }}
              ref={gridC1S1LRef}
            >
              <div
                className={gridC1S1L ? classes.C1S1_1 : classes.C1S1_1Hidden}
              >
                <img src={C1S1_1} alt="C1S1_1" />
              </div>
              <div
                className={gridC1S1L ? classes.C1S1_2 : classes.C1S1_2Hidden}
              >
                <img src={C1S1_2} alt="C1S1_2" />
              </div>
              <div
                className={gridC1S1L ? classes.C1S1_3 : classes.C1S1_3Hidden}
              >
                <img src={C1S1_3} alt="C1S1_3" />
              </div>
            </Grid>
            <Grid
              xs={12}
              ref={gridC1S1RRef}
              className={gridC1S1R ? classes.textShow : classes.textHidden}
            >
              <Grid container style={{ marginTop: 48 }}>
                <Typography
                  className={classes.typoItemHeader}
                  style={{ maxWidth: 302 }}
                >
                  {"Génère des ateliers collaboratifs simplement et rapidement"}
                </Typography>
              </Grid>
              <Grid container style={{ marginTop: 22 }}>
                <Typography
                  className={classes.typoItemText}
                  style={{ maxWidth: 270 }}
                >
                  {
                    "Grâce à son générateur intelligent, Qibish prépare tes réunions et ateliers sur mesure selon tes besoins, tes objectifs et ton niveau de compétence en quelques minutes."
                  }
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            justify="center"
            style={{ marginTop: 200, position: "relative" }}
          >
            <Grid lg={4} style={{ position: "relative" }} ref={gridC1S1LRef}>
              <div
                className={gridC1S1L ? classes.C1S1_1 : classes.C1S1_1Hidden}
              >
                <img src={C1S1_1} alt="C1S1_1" />
              </div>
              <div
                className={gridC1S1L ? classes.C1S1_2 : classes.C1S1_2Hidden}
              >
                <img src={C1S1_2} alt="C1S1_2" />
              </div>
              <div
                className={gridC1S1L ? classes.C1S1_3 : classes.C1S1_3Hidden}
              >
                <img src={C1S1_3} alt="C1S1_3" />
              </div>
            </Grid>
            <Grid lg={1} />
            <Grid
              lg={3}
              ref={gridC1S1RRef}
              className={gridC1S1R ? classes.textShow : classes.textHidden}
            >
              <Grid container style={{ marginTop: 90 }}>
                <Typography
                  className={classes.typoItemHeader}
                  style={{ maxWidth: 302 }}
                >
                  {"Génère des ateliers collaboratifs simplement et rapidement"}
                </Typography>
              </Grid>
              <Grid container style={{ marginTop: 22 }}>
                <Typography
                  className={classes.typoItemText}
                  style={{ maxWidth: 270 }}
                >
                  {
                    "Grâce à son générateur intelligent, Qibish prépare tes réunions et ateliers sur mesure selon tes besoins, tes objectifs et ton niveau de compétence en quelques minutes."
                  }
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        {mobile ? (
          <Grid
            container
            justify="center"
            style={{ position: "relative", padding: 24 }}
          >
            <Grid xs={12} style={{ position: "relative", height: 250 }} ref={gridC1S2RRef}>
              <div
                className={gridC1S2R ? classes.C1S2_1 : classes.C1S2_1Hidden}
              >
                <img src={C1S2_1} alt="C1S2_1" style={{width: 300, height: "auto"}} />
              </div>
              <div
                className={gridC1S2R ? classes.C1S2_2 : classes.C1S2_2Hidden}
              >
                <img src={C1S2_2} alt="C1S2_2" style={{width: 248, height: "auto"}} />
              </div>
              <div
                className={gridC1S2R ? classes.C1S2_3 : classes.C1S2_3Hidden}
              >
                <img src={C1S2_3} alt="C1S2_3" style={{width: 248, height: "auto"}}/>
              </div>
              <div
                className={gridC1S2R ? classes.C1S2_4 : classes.C1S2_4Hidden}
              >
                <img src={C1S2_4} alt="C1S2_4" style={{width: 248, height: "auto"}}/>
              </div>
            </Grid>
            <Grid
              xs={12}
              ref={gridC1S2LRef}
              className={gridC1S2L ? classes.textShow : classes.textHidden}
            >
              <Grid container style={{ marginTop: 30 }}>
                <Typography
                  className={classes.typoItemHeader}
                  style={{ maxWidth: 302 }}
                >
                  {"Applique des méthodes innovantes de travail d’équipe"}
                </Typography>
              </Grid>
              <Grid container style={{ marginTop: 22 }}>
                <Typography
                  className={classes.typoItemText}
                  style={{ maxWidth: 302 }}
                >
                  {
                    "Grâce à Qibish, transforme tes réunions interactives en réunions collaboratives. Qibish offre la façon la plus simple d’appliquer des méthodes concrètes et éprouvées comme l’Agile, le Lean ou encore le Design Thinking pour vos réunions du quotidien. "
                  }
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            justify="center"
            style={{ marginTop: 285, position: "relative" }}
          >
            <Grid lg={2} />
            <Grid
              lg={3}
              ref={gridC1S2LRef}
              className={gridC1S2L ? classes.textShow : classes.textHidden}
            >
              <Grid container style={{ marginTop: 30 }}>
                <Typography
                  className={classes.typoItemHeader}
                  style={{ maxWidth: 302 }}
                >
                  {"Applique des méthodes innovantes de travail d’équipe"}
                </Typography>
              </Grid>
              <Grid container style={{ marginTop: 22 }}>
                <Typography
                  className={classes.typoItemText}
                  style={{ maxWidth: 302 }}
                >
                  {
                    "Grâce à Qibish, transforme tes réunions interactives en réunions collaboratives. Qibish offre la façon la plus simple d’appliquer des méthodes concrètes et éprouvées comme l’Agile, le Lean ou encore le Design Thinking pour vos réunions du quotidien. "
                  }
                </Typography>
              </Grid>
            </Grid>

            <Grid lg={4} style={{ position: "relative" }} ref={gridC1S2RRef}>
              <div
                className={gridC1S2R ? classes.C1S2_1 : classes.C1S2_1Hidden}
              >
                <img src={C1S2_1} alt="C1S2_1" />
              </div>
              <div
                className={gridC1S2R ? classes.C1S2_2 : classes.C1S2_2Hidden}
              >
                <img src={C1S2_2} alt="C1S2_2" />
              </div>
              <div
                className={gridC1S2R ? classes.C1S2_3 : classes.C1S2_3Hidden}
              >
                <img src={C1S2_3} alt="C1S2_3" />
              </div>
              <div
                className={gridC1S2R ? classes.C1S2_4 : classes.C1S2_4Hidden}
              >
                <img src={C1S2_4} alt="C1S2_4" />
              </div>
            </Grid>
          </Grid>
        )}
      {mobile ?
              <Grid
              container
              justify="center"
              style={{ padding: 24, position: "relative" }}
            >
              <Grid xs={12} style={{ position: "relative", height: 440 }} ref={gridC1S3LRef}>
                <div className={gridC1S3L ? classes.C1S3_1 : classes.C1S3_1Hidden}>
                  <img src={C1S3_1} alt="C1S3_1" />
                </div>
                <div className={gridC1S3L ? classes.C1S3_2 : classes.C1S3_2Hidden}>
                  <img src={C1S3_2} alt="C1S3_2" />
                </div>
                <div className={gridC1S3L ? classes.C1S3_3 : classes.C1S3_3Hidden}>
                  <img src={C1S3_3} alt="C1S3_3" />
                </div>
                <div className={gridC1S3L ? classes.C1S3_4 : classes.C1S3_4Hidden}>
                  <img src={C1S3_4} alt="C1S3_4" />
                </div>
              </Grid>
              <Grid
                xs={12}
                ref={gridC1S3RRef}
                className={gridC1S3R ? classes.textShow : classes.textHidden}
              >
                <Grid container style={{ marginTop: 90 }}>
                  <Typography
                    className={classes.typoItemHeader}
                    style={{ maxWidth: 302 }}
                  >
                    {"Explore notre bibliothèque d’activités et de cas d’usage"}
                  </Typography>
                </Grid>
                <Grid container style={{ marginTop: 22 }}>
                  <Typography
                    className={classes.typoItemText}
                    style={{ maxWidth: 278 }}
                  >
                    {
                      "Qibish n’est pas une solution technique à laquelle tu dois adapter tes usages : Qibish a été pensé à partir de cas d’usages concrets en entreprise collectés chez nos clients. Avec plus de 40 différentes activités templatées, construis des centaines de réunions collaboratives innovantes."
                    }
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
      :
        <Grid
          container
          justify="center"
          style={{ marginTop: 200, position: "relative" }}
        >
          <Grid lg={4} style={{ position: "relative" }} ref={gridC1S3LRef}>
            <div className={gridC1S3L ? classes.C1S3_1 : classes.C1S3_1Hidden}>
              <img src={C1S3_1} alt="C1S3_1" />
            </div>
            <div className={gridC1S3L ? classes.C1S3_2 : classes.C1S3_2Hidden}>
              <img src={C1S3_2} alt="C1S3_2" />
            </div>
            <div className={gridC1S3L ? classes.C1S3_3 : classes.C1S3_3Hidden}>
              <img src={C1S3_3} alt="C1S3_3" />
            </div>
            <div className={gridC1S3L ? classes.C1S3_4 : classes.C1S3_4Hidden}>
              <img src={C1S3_4} alt="C1S3_4" />
            </div>
          </Grid>
          <Grid lg={1} />
          <Grid
            lg={3}
            ref={gridC1S3RRef}
            className={gridC1S3R ? classes.textShow : classes.textHidden}
          >
            <Grid container style={{ marginTop: 90 }}>
              <Typography
                className={classes.typoItemHeader}
                style={{ maxWidth: 302 }}
              >
                {"Explore notre bibliothèque d’activités et de cas d’usage"}
              </Typography>
            </Grid>
            <Grid container style={{ marginTop: 22 }}>
              <Typography
                className={classes.typoItemText}
                style={{ maxWidth: 278 }}
              >
                {
                  "Qibish n’est pas une solution technique à laquelle tu dois adapter tes usages : Qibish a été pensé à partir de cas d’usages concrets en entreprise collectés chez nos clients. Avec plus de 40 différentes activités templatées, construis des centaines de réunions collaboratives innovantes."
                }
              </Typography>
            </Grid>
          </Grid>
        </Grid>      
      }

        <Grid container justify="center" style={mobile ?{marginTop: 48 } : { marginTop: 250 }} id="anime">
          <Grid>
            <Typography className={classes.typoNumero}>2</Typography>
          </Grid>
        </Grid>
        <Grid container justify="center" style={{ marginTop: 40 }}>
          <Grid
            className={grid2 ? classes.textShow : classes.textHidden}
            ref={grid2Ref}
          >
            <Typography
              className={classes.typoHeader}
              style={{ maxWidth: 972 }}
            >
              <span className={classes.highlight}>Anime</span>
              {
                " des réunions et ateliers cadrés pour prendre des décisions concrètes."
              }
            </Typography>
          </Grid>
        </Grid>
        <Grid container justify="center" style={mobile ? {padding:24}:{ marginTop: 30 }}>
          <Grid
            className={grid2bis ? classes.textShow : classes.textHidden}
            ref={grid2bisRef}
          >
            <Typography
              className={classes.typoSubHeader}
              style={{ maxWidth: 972 }}
            >
              {
                "Les réunions Qibish ont été conçues pour convenir aux ateliers collaboratifs les plus complexes tout en restant intuitifs. Elles respectent l’ensemble des bonnes pratiques du collaboratif, maximisent la concentration de tes participants."
              }
            </Typography>
          </Grid>
        </Grid>
        {mobile ?
                <Grid
                container
                justify="center"
                style={{ padding:24, position: "relative" }}
              >
                <Grid xs={12} style={{ position: "relative", height: 350 }} ref={gridC2S1LRef}>
                  <div className={gridC2S1L ? classes.C2S1_1 : classes.C2S1_1Hidden}>
                    <img src={C2S1_1} alt="C2S1_1" />
                  </div>
                  <div className={gridC2S1L ? classes.C2S1_2 : classes.C2S1_2Hidden}>
                    <img src={C2S1_2} alt="C2S1_2" />
                  </div>
                  <div className={gridC2S1L ? classes.C2S1_3 : classes.C2S1_3Hidden}>
                    <img src={C2S1_3} alt="C2S1_3" />
                  </div>
                  <div className={gridC2S1L ? classes.C2S1_4 : classes.C2S1_4Hidden}>
                    <img src={C2S1_4} alt="C2S1_4" />
                  </div>
                  <div className={gridC2S1L ? classes.C2S1_5 : classes.C2S1_5Hidden}>
                    <img src={C2S1_5} alt="C2S1_5" />
                  </div>
                  <div className={gridC2S1L ? classes.C2S1_6 : classes.C2S1_6Hidden}>
                    <img src={C2S1_6} alt="C2S1_6" />
                  </div>
                </Grid>
                <Grid
                  xs={12}
                  ref={gridC2S1RRef}
                  className={gridC2S1R ? classes.textShow : classes.textHidden}
                >
                  <Grid container style={{ marginTop: 10 }}>
                    <Typography
                      className={classes.typoItemHeader}
                      style={{ maxWidth: 229 }}
                    >
                      {"Un agenda pas à pas pour atteindre tes objectifs"}
                    </Typography>
                  </Grid>
                  <Grid container style={{ marginTop: 22 }}>
                    <Typography
                      className={classes.typoItemText}
                      style={{ maxWidth: 285 }}
                    >
                      {
                        "Qibish te propose un agenda de travail détaillé étape par étape selon tes objectifs et tes besoins pour chacune de tes réunions afin de la dynamiser, assurer la concentration de tes participants de bout en bout et sécuriser l’atteinte de tes objectifs."
                      }
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
        :
        <Grid
        container
        justify="center"
        style={{ marginTop: 210, position: "relative" }}
      >
        <Grid lg={4} style={{ position: "relative" }} ref={gridC2S1LRef}>
          <div className={gridC2S1L ? classes.C2S1_1 : classes.C2S1_1Hidden}>
            <img src={C2S1_1} alt="C2S1_1" />
          </div>
          <div className={gridC2S1L ? classes.C2S1_2 : classes.C2S1_2Hidden}>
            <img src={C2S1_2} alt="C2S1_2" />
          </div>
          <div className={gridC2S1L ? classes.C2S1_3 : classes.C2S1_3Hidden}>
            <img src={C2S1_3} alt="C2S1_3" />
          </div>
          <div className={gridC2S1L ? classes.C2S1_4 : classes.C2S1_4Hidden}>
            <img src={C2S1_4} alt="C2S1_4" />
          </div>
          <div className={gridC2S1L ? classes.C2S1_5 : classes.C2S1_5Hidden}>
            <img src={C2S1_5} alt="C2S1_5" />
          </div>
          <div className={gridC2S1L ? classes.C2S1_6 : classes.C2S1_6Hidden}>
            <img src={C2S1_6} alt="C2S1_6" />
          </div>
        </Grid>
        <Grid lg={1} />
        <Grid
          lg={3}
          ref={gridC2S1RRef}
          className={gridC2S1R ? classes.textShow : classes.textHidden}
        >
          <Grid container style={{ marginTop: 10 }}>
            <Typography
              className={classes.typoItemHeader}
              style={{ maxWidth: 229 }}
            >
              {"Un agenda pas à pas pour atteindre tes objectifs"}
            </Typography>
          </Grid>
          <Grid container style={{ marginTop: 22 }}>
            <Typography
              className={classes.typoItemText}
              style={{ maxWidth: 285 }}
            >
              {
                "Qibish te propose un agenda de travail détaillé étape par étape selon tes objectifs et tes besoins pour chacune de tes réunions afin de la dynamiser, assurer la concentration de tes participants de bout en bout et sécuriser l’atteinte de tes objectifs."
              }
            </Typography>
          </Grid>
        </Grid>
      </Grid>  
        }
      {mobile ?
              <Grid
              container
              justify="center"
              style={{ padding:24, position: "relative" }}
            >
              <Grid xs={12} style={{ position: "relative", height : 400 }} ref={gridC2S2RRef}>
                <div className={gridC2S2R ? classes.C2S2_1 : classes.C2S2_1Hidden}>
                  <img src={C2S2_1} alt="C2S2_1" />
                </div>
                <div className={gridC2S2R ? classes.C2S2_2 : classes.C2S2_2Hidden}>
                  <img src={C2S2_2} alt="C2S2_2" />
                </div>
              </Grid>
              <Grid
                xs={12}
                ref={gridC2S2LRef}
                className={gridC2S2L ? classes.textShow : classes.textHidden}
              >
                <Grid container style={{ marginTop: 110 }}>
                  <Typography
                    className={classes.typoItemHeader}
                    style={{ maxWidth: 302 }}
                  >
                    {
                      "Des consignes claires et des activités timées pour une efficacité optimale"
                    }
                  </Typography>
                </Grid>
                <Grid container style={{ marginTop: 22 }}>
                  <Typography
                    className={classes.typoItemText}
                    style={{ maxWidth: 290 }}
                  >
                    {
                      "Toutes les activités Qibish intègrent des consignes claires qui permettent de guider rapidement les participants vers l’objectif de ta réunion collaborative en temps et en heure : le timer Qibish y veille au grain !"
                    }
                  </Typography>
                </Grid>
              </Grid>
    

            </Grid>
      :
      <Grid
      container
      justify="center"
      style={{ marginTop: 285, position: "relative" }}
    >
      <Grid lg={2} />
      <Grid
        lg={3}
        ref={gridC2S2LRef}
        className={gridC2S2L ? classes.textShow : classes.textHidden}
      >
        <Grid container style={{ marginTop: 110 }}>
          <Typography
            className={classes.typoItemHeader}
            style={{ maxWidth: 302 }}
          >
            {
              "Des consignes claires et des activités timées pour une efficacité optimale"
            }
          </Typography>
        </Grid>
        <Grid container style={{ marginTop: 22 }}>
          <Typography
            className={classes.typoItemText}
            style={{ maxWidth: 290 }}
          >
            {
              "Toutes les activités Qibish intègrent des consignes claires qui permettent de guider rapidement les participants vers l’objectif de ta réunion collaborative en temps et en heure : le timer Qibish y veille au grain !"
            }
          </Typography>
        </Grid>
      </Grid>

      <Grid lg={4} style={{ position: "relative" }} ref={gridC2S2RRef}>
        <div className={gridC2S2R ? classes.C2S2_1 : classes.C2S2_1Hidden}>
          <img src={C2S2_1} alt="C2S2_1" />
        </div>
        <div className={gridC2S2R ? classes.C2S2_2 : classes.C2S2_2Hidden}>
          <img src={C2S2_2} alt="C2S2_2" />
        </div>
      </Grid>
    </Grid>
      }
      {mobile ?
              <Grid
              container
              justify="center"
              style={{ padding:24, position: "relative" }}
            >
              <Grid xs={12} style={{ position: "relative", height: 270 }} ref={gridC2S3LRef}>
                <div className={gridC2S3L ? classes.C2S3_1 : classes.C2S3_1Hidden}>
                  <img src={C2S3_1} alt="C1S3_1" style={{width: 400, height: "auto"}}/>
                </div>
                <div className={gridC2S3L ? classes.C2S3_2 : classes.C2S3_2Hidden}>
                  <img src={C2S3_2} alt="C1S3_2"  style={{width: 350, height: "auto"}} />
                </div>
              </Grid>
              <Grid
                xs={12}
                ref={gridC2S3RRef}
                className={gridC2S3R ? classes.textShow : classes.textHidden}
              >
                <Grid container style={{ marginTop: 140 }}>
                  <Typography
                    className={classes.typoItemHeader}
                    style={{ maxWidth: 302 }}
                  >
                    {"Expérience utilisateur extraodinaire"}
                  </Typography>
                </Grid>
                <Grid container style={{ marginTop: 22 }}>
                  <Typography
                    className={classes.typoItemText}
                    style={{ maxWidth: 302 }}
                  >
                    {
                      "Intuitif et simple d’utilisation, Qibish est conçu pour que tous tes participants puissent se concentrer sur l’objectif à atteindre plutôt que sur l’utilisation de la solution."
                    }
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
      :
      <Grid
      container
      justify="center"
      style={{ marginTop: 250, position: "relative" }}
    >
      <Grid lg={4} style={{ position: "relative" }} ref={gridC2S3LRef}>
        <div className={gridC2S3L ? classes.C2S3_1 : classes.C2S3_1Hidden}>
          <img src={C2S3_1} alt="C1S3_1" />
        </div>
        <div className={gridC2S3L ? classes.C2S3_2 : classes.C2S3_2Hidden}>
          <img src={C2S3_2} alt="C1S3_2" />
        </div>
      </Grid>
      <Grid lg={1} />
      <Grid
        lg={3}
        ref={gridC2S3RRef}
        className={gridC2S3R ? classes.textShow : classes.textHidden}
      >
        <Grid container style={{ marginTop: 140 }}>
          <Typography
            className={classes.typoItemHeader}
            style={{ maxWidth: 302 }}
          >
            {"Expérience utilisateur extraodinaire"}
          </Typography>
        </Grid>
        <Grid container style={{ marginTop: 22 }}>
          <Typography
            className={classes.typoItemText}
            style={{ maxWidth: 302 }}
          >
            {
              "Intuitif et simple d’utilisation, Qibish est conçu pour que tous tes participants puissent se concentrer sur l’objectif à atteindre plutôt que sur l’utilisation de la solution."
            }
          </Typography>
        </Grid>
      </Grid>
    </Grid>
      }

        <Grid container justify="center" style={mobile ?{ marginTop: 48 } :{ marginTop: 350 }} id="restitue">
          <Grid>
            <Typography className={classes.typoNumero}>3</Typography>
          </Grid>
        </Grid>
        <Grid container justify="center" style={{ marginTop: 40 }}>
          <Grid
            className={grid3 ? classes.textShow : classes.textHidden}
            ref={grid3Ref}
          >
            <Typography className={classes.typoHeader}>
              <span className={classes.highlight}>La restitution</span>
              {", enfin instantanée"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justify="center" style={mobile ? {padding:24}:{ marginTop: 30 }}>
          <Grid
            className={grid3bis ? classes.textShow : classes.textHidden}
            ref={grid3bisRef}
          >
            <Typography
              className={classes.typoSubHeader}
              style={{ maxWidth: 792 }}
            >
              {
                "Qibish te permet de restituer immédiatement ton atelier collaboratif. Cela renforce l’alignement et l’engagement de tes participants et te permet de te concentrer sur le prochain objectif à atteindre."
              }
            </Typography>
          </Grid>
        </Grid>
      {mobile ?
              <Grid
              container
              justify="center"
              style={{padding: 24, position: "relative" }}
            >
              <Grid xs={12} style={{ position: "relative", height: 200 }} ref={gridC3S1LRef}>
                <div className={gridC3S1L ? classes.C3S1_1 : classes.C3S1_1Hidden}>
                  <img src={C3S1_1} alt="C3S1_1" style={{height: 230, width: "auto"}} />
                </div>
                <div className={gridC3S1L ? classes.C3S1_2 : classes.C3S1_2Hidden}>
                  <img src={C3S1_2} alt="C3S1_2" style={{width: 550, height:"auto"}}/>
                </div>
                <div className={gridC3S1L ? classes.C3S1_3 : classes.C3S1_3Hidden}>
                  <img src={C3S1_3} alt="C3S1_3" style={{width: 550, height:"auto"}}/>
                </div>
                <div className={gridC3S1L ? classes.C3S1_4 : classes.C3S1_4Hidden}>
                  <img src={C3S1_4} alt="C3S1_4" style={{width: 550, height:"auto"}}/>
                </div>
                <div className={gridC3S1L ? classes.C3S1_5 : classes.C3S1_5Hidden}>
                  <img src={C3S1_5} alt="C3S1_5" style={{width: 550, height:"auto"}}/>
                </div>
              </Grid>
              <Grid
                xs={12}
                ref={gridC3S1RRef}
                className={gridC3S1R ? classes.textShow : classes.textHidden}
              >
                <Grid container style={{ marginTop: 100 }}>
                  <Typography
                    className={classes.typoItemHeader}
                    style={{ maxWidth: 217 }}
                  >
                    {"Génère et partage ton compte-rendu"}
                  </Typography>
                </Grid>
                <Grid container style={{ marginTop: 22 }}>
                  <Typography
                    className={classes.typoItemText}
                    style={{ maxWidth: 275 }}
                  >
                    {
                      "Dès la fin de ton atelier, tu peux générer et partager le compte-rendu de l’atelier avec l’ensemble des participants. Tu n’arriveras plus jamais en retard aux afterworks. Tu as aussi besoin des données brutes ? Pas de soucis, tu peux les exporter."
                    }
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
      :
      <Grid
      container
      justify="center"
      style={{ marginTop: 170, position: "relative" }}
    >
      <Grid lg={4} style={{ position: "relative" }} ref={gridC3S1LRef}>
        <div className={gridC3S1L ? classes.C3S1_1 : classes.C3S1_1Hidden}>
          <img src={C3S1_1} alt="C3S1_1" />
        </div>
        <div className={gridC3S1L ? classes.C3S1_2 : classes.C3S1_2Hidden}>
          <img src={C3S1_2} alt="C3S1_2" />
        </div>
        <div className={gridC3S1L ? classes.C3S1_3 : classes.C3S1_3Hidden}>
          <img src={C3S1_3} alt="C3S1_3" />
        </div>
        <div className={gridC3S1L ? classes.C3S1_4 : classes.C3S1_4Hidden}>
          <img src={C3S1_4} alt="C3S1_4" />
        </div>
        <div className={gridC3S1L ? classes.C3S1_5 : classes.C3S1_5Hidden}>
          <img src={C3S1_5} alt="C3S1_5" />
        </div>
      </Grid>
      <Grid lg={1} />
      <Grid
        lg={3}
        ref={gridC3S1RRef}
        className={gridC3S1R ? classes.textShow : classes.textHidden}
      >
        <Grid container style={{ marginTop: 100 }}>
          <Typography
            className={classes.typoItemHeader}
            style={{ maxWidth: 217 }}
          >
            {"Génère et partage ton compte-rendu"}
          </Typography>
        </Grid>
        <Grid container style={{ marginTop: 22 }}>
          <Typography
            className={classes.typoItemText}
            style={{ maxWidth: 275 }}
          >
            {
              "Dès la fin de ton atelier, tu peux générer et partager le compte-rendu de l’atelier avec l’ensemble des participants. Tu n’arriveras plus jamais en retard aux afterworks. Tu as aussi besoin des données brutes ? Pas de soucis, tu peux les exporter."
            }
          </Typography>
        </Grid>
      </Grid>
    </Grid>
      }
  {mobile ?
          <Grid
          container
          justify="center"
          style={{ padding: 24, position: "relative" }}
        >

          <Grid xs={12} style={{ position: "relative", height: 150 }} ref={gridC3S2RRef}>
            <div className={gridC3S2R ? classes.C3S2_1 : classes.C3S2_1Hidden}>
              <img src={C3S2_1} alt="C3S2_1" style={{width: 450, height: "auto"}} />
            </div>
          </Grid>         
          <Grid
           xs={12}
            ref={gridC3S2LRef}
            className={gridC3S2L ? classes.textShow : classes.textHidden}
          >
            <Grid container style={{ marginTop: 110 }}>
              <Typography
                className={classes.typoItemHeader}
                style={{ maxWidth: 180 }}
              >
                {"Le feedback, c'est la vie"}
              </Typography>
            </Grid>
            <Grid container style={{ marginTop: 22 }}>
              <Typography
                className={classes.typoItemText}
                style={{ maxWidth: 313 }}
              >
                {
                  "A la fin de chaque atelier Qibish, l’évaluation des participants est systématiquement recueillie. Parfait pour l’engagement, mais aussi pour améliorer ton prochain atelier. "
                }
              </Typography>
            </Grid>
          </Grid>

        </Grid>
  :
  <Grid
  container
  justify="center"
  style={{ marginTop: 200, position: "relative" }}
>
  <Grid lg={2} />
  <Grid
    lg={3}
    ref={gridC3S2LRef}
    className={gridC3S2L ? classes.textShow : classes.textHidden}
  >
    <Grid container style={{ marginTop: 110 }}>
      <Typography
        className={classes.typoItemHeader}
        style={{ maxWidth: 180 }}
      >
        {"Le feedback, c'est la vie"}
      </Typography>
    </Grid>
    <Grid container style={{ marginTop: 22 }}>
      <Typography
        className={classes.typoItemText}
        style={{ maxWidth: 313 }}
      >
        {
          "A la fin de chaque atelier Qibish, l’évaluation des participants est systématiquement recueillie. Parfait pour l’engagement, mais aussi pour améliorer ton prochain atelier. "
        }
      </Typography>
    </Grid>
  </Grid>

  <Grid lg={4} style={{ position: "relative" }} ref={gridC3S2RRef}>
    <div className={gridC3S2R ? classes.C3S2_1 : classes.C3S2_1Hidden}>
      <img src={C3S2_1} alt="C3S2_1" />
    </div>
  </Grid>
</Grid>
  }
  {mobile ?
          <Grid
          container
          justify="center"
          style={{ padding:24, position: "relative" }}
        >
          <Grid xs={12} style={{ position: "relative", height: 200 }} ref={gridC3S3LRef}>
            <div className={gridC3S3L ? classes.C3S3_1 : classes.C3S3_1Hidden}>
              <img src={C3S3_1} alt="C3S3_1" style={{width: 300, height:"auto"}}/>
            </div>
            <div className={gridC3S3L ? classes.C3S3_2 : classes.C3S3_2Hidden}>
              <img src={C3S3_2} alt="C3S3_2" style={{width: 300, height:"auto"}} />
            </div>
          </Grid>
          <Grid
            xs={12}
            ref={gridC3S3RRef}
            className={gridC3S3R ? classes.textShow : classes.textHidden}
          >
            <Grid container style={{ marginTop: 140 }}>
              <Typography
                className={classes.typoItemHeader}
                style={{ maxWidth: 210 }}
              >
                {"Tu en as rêvé, pas vrai ?"}
              </Typography>
            </Grid>
            <Grid container style={{ marginTop: 22 }}>
              <Typography
                className={classes.typoItemText}
                style={{ maxWidth: 302 }}
              >
                {
                  "Qibish est la seule solution du marché qui fournit des statistiques sur la collaboration et l’engagement de tes équipes. Enfin une fonctionnalité qui va faire plaisir aux chefs."
                }
              </Typography>
            </Grid>
          </Grid>
        </Grid>
  :
  <Grid
  container
  justify="center"
  style={{ marginTop: 250, position: "relative" }}
>
  <Grid lg={4} style={{ position: "relative" }} ref={gridC3S3LRef}>
    <div className={gridC3S3L ? classes.C3S3_1 : classes.C3S3_1Hidden}>
      <img src={C3S3_1} alt="C3S3_1" />
    </div>
    <div className={gridC3S3L ? classes.C3S3_2 : classes.C3S3_2Hidden}>
      <img src={C3S3_2} alt="C3S3_2" />
    </div>
  </Grid>
  <Grid lg={1} />
  <Grid
    lg={3}
    ref={gridC3S3RRef}
    className={gridC3S3R ? classes.textShow : classes.textHidden}
  >
    <Grid container style={{ marginTop: 140 }}>
      <Typography
        className={classes.typoItemHeader}
        style={{ maxWidth: 210 }}
      >
        {"Tu en as rêvé, pas vrai ?"}
      </Typography>
    </Grid>
    <Grid container style={{ marginTop: 22 }}>
      <Typography
        className={classes.typoItemText}
        style={{ maxWidth: 302 }}
      >
        {
          "Qibish est la seule solution du marché qui fournit des statistiques sur la collaboration et l’engagement de tes équipes. Enfin une fonctionnalité qui va faire plaisir aux chefs."
        }
      </Typography>
    </Grid>
  </Grid>
</Grid>
  }

        <div className={classes.gridRoue} ref={roueRef}>
          <div
            className={
              accordeonRoue
                ? classes.bandeauBlanc1
                : classes.bandeauBlanc1Hidden
            }
          />
          <div
            className={
              accordeonRoue
                ? classes.bandeauBlanc2
                : classes.bandeauBlanc2Hidden
            }
          />
          <div
            className={
              accordeonRoue
                ? classes.bandeauBlanc3
                : classes.bandeauBlanc3Hidden
            }
          />
          <div
            className={
              accordeonRoue
                ? classes.bandeauBlanc4
                : classes.bandeauBlanc4Hidden
            }
          />
          <div className={classes.roueFond}>
            <img src={roue} alt="roue" />
          </div>
          <div>
            {roueTitles.map((e, index) => (
              <>
                <Typography className={roueStyleBuilder(index)}>{e}</Typography>
              </>
            ))}
          </div>
        </div>
        <Grid container className={classes.gridChiffres}>
          <div className={classes.gridChiffreFillerTopLeft} />
          <div className={classes.gridChiffreFillerTopLeft2} />
          <div className={classes.gridChiffreFillerTopRight2} />
          <div className={classes.gridChiffreFillerTopRight} />
          
          {mobile ?
                    <>

                    <Grid container direction="column" item justify="center" alignItems="center" xs={12}>
                      <Grid>
                        <Typography className={classes.typoChiffresHeader}>40+</Typography>
                      </Grid>
                      <Grid>
                      <Typography className={classes.typoChiffresBody}  >
                        {"Activités disponibles"}
                      </Typography>                        
                      </Grid>
                    </Grid>
                    <Grid container direction="column" item justify="center" alignItems="center" xs={12}>
                      <Grid>
                        <Typography className={classes.typoChiffresHeader}>12+</Typography>
                      </Grid>
                      <Grid>
                      <Typography className={classes.typoChiffresBody}  >
                      {"Cas d'usage opérationnels et stratégiques"}
                      </Typography>                        
                      </Grid>
                    </Grid>
                    <Grid container direction="column" item justify="center" alignItems="center" xs={12}>
                      <Grid>
                        <Typography className={classes.typoChiffresHeader}>240+</Typography>
                      </Grid>
                      <Grid>
                      <Typography className={classes.typoChiffresBody} align="left">
                      {"Ateliers possibles dans Qibish"}
                      </Typography>                        
                      </Grid>
                    </Grid>

                    </>
          :
          <>
          <Grid lg={1} />
          <Grid lg={3}>
            <Typography className={classes.typoChiffresHeader}>40+</Typography>
            <Typography className={classes.typoChiffresBody} align="left">
              {"Activités disponibles"}
            </Typography>
          </Grid>
          <Grid lg={3}>
            <Typography className={classes.typoChiffresHeader}>12+</Typography>
            <Typography className={classes.typoChiffresBody} align="left">
              {"Cas d'usage opérationnels et stratégiques"}
            </Typography>
          </Grid>
          <Grid lg={3}>
            <Typography className={classes.typoChiffresHeader}>240+</Typography>
            <Typography className={classes.typoChiffresBody} align="left">
              {"Ateliers possibles dans Qibish"}
            </Typography>
          </Grid>
          </>
          }

        </Grid>
        <Grid
          container
          justify="flex-start"
          direction="column"
          className={classes.gridFooter}
          ref={gridFooterRef}
        >
          <div
            className={
              accordeonFooter
                ? classes.bandeauNoir1
                : classes.bandeauNoir1Hidden
            }
          />
          <div
            className={
              accordeonFooter
                ? classes.bandeauNoir2
                : classes.bandeauNoir2Hidden
            }
          />
          <div
            className={
              accordeonFooter
                ? classes.bandeauNoir3
                : classes.bandeauNoir3Hidden
            }
          />
          <div
            className={
              accordeonFooter
                ? classes.bandeauNoir4
                : classes.bandeauNoir4Hidden
            }
          />
          {mobile ? null:
          <div className={classes.footerImpecc}>
            <Impecc2 />
          </div>          
          }

          <Grid
            container
            justify={mobile ? "center":"flex-start"}
            style={mobile ?{ marginTop:36 } :{ marginTop: 176, paddingLeft: 80, height: 250 }}
          >
            <Grid>
              <Typography className={classes.typoFooter}>
                {
                  "Renforce l'innovation et la collaboration avec Qibish "
                }
              </Typography>
            </Grid>
            <Grid style={mobile ?{marginTop: 24}:{ paddingLeft: "20%" }}>
              <Button
                disableElevation
                variant="contained"
                className={classes.buttonGoFooter}
                onClick={() => props.goToApp()}
              >
                GO !
              </Button>
            </Grid>
          </Grid>
          {mobile ? 
          <Grid container justify="center" style={{marginTop: 34}}>
            <Grid xs={10}>
              <Paper
                component="form"
                elevation={0}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: mobile ? "100%":303,
                  height: 48,
                  borderRadius: 16,
                  backgroundColor: "#202020",
                  opacity: 0.39,
                }}
                onSubmit={e => {e.preventDefault(); registerNewsletter(mail)}}
              >
                <InputBase
                onChange={(e) => setMail(e.target.value)}
                value={mail}
                  placeholder="S'inscrire à la newsletter"
                  inputProps={{ "aria-label": "S'inscrire à la newsletter" }}
                  style={{
                    marginLeft: 16,
                    flex: 1,
                    color: "#fff",
                    fontSize: 12,
                  }}
                />
                <Button disableElevation style={{ color: "#fff" }} onClick={() =>  registerNewsletter(mail)}>
                  OK
                </Button>
              </Paper>
              <Collapse in={successNewsletter}>
              <Alert
                style={{marginTop: 8}}
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setSuccessNewsletter(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Inscription validée !
              </Alert>
            </Collapse>

         
            <Collapse in={errorNewsletter}>
              <Alert
                style={{marginTop: 8}}
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setErrorNewsletter(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
               Une erreur s'est produite lors de ton inscription
              </Alert>
              </Collapse>
            </Grid>
          </Grid>
          :
          <Grid
            container
            justify="space-between"
            alignItems="flex-start"
            style={{
              marginTop: 70,
              paddingLeft: 80,
              paddingRight: 80,
              height: 150,
            }}
          >
            <Grid>
              <Grid container style={{ width: 310 }} alignItems="center">
                <Grid xs={3}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/"
                  >
                    Accueil
                  </Typography>
                </Grid>
                <Grid xs={3}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/prix"
                  >
                    Prix
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ position: "relative" }}>
                  <Typography className={classes.typoLiensFooter}>
                    Blog
                  </Typography>
                  <div className={classes.footerBlog}>
                    <Blog />
                  </div>
                </Grid>
                <Grid xs={3}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/contact"
                  >
                    Contact
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ marginTop: 24 }}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/solution"
                  >
                    Solution
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ marginTop: 24 }}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/mission"
                  >
                    Mission
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ marginTop: 24 }}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/cgu"
                  >
                    CGU
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ marginTop: 24 }}>
                  <Typography className={classes.typoLiensFooter} onClick={() => props.goToApp()}>
                    Se connecter
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <Paper
                component="form"
                elevation={0}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: 303,
                  height: 48,
                  borderRadius: 16,
                  backgroundColor: "#202020",
                  opacity: 0.39,
                }}
                onSubmit={e => {e.preventDefault(); registerNewsletter(mail)}}
              >
                <InputBase
                onChange={(e) => setMail(e.target.value)}
                value={mail}
                  placeholder="S'inscrire à la newsletter"
                  inputProps={{ "aria-label": "S'inscrire à la newsletter" }}
                  style={{
                    marginLeft: 16,
                    flex: 1,
                    color: "#fff",
                    fontSize: 12,
                  }}
                />
                <Button disableElevation style={{ color: "#fff" }} onClick={() =>  registerNewsletter(mail)}>
                  OK
                </Button>
              </Paper>
              <Collapse in={successNewsletter}>
              <Alert
                style={{marginTop: 8}}
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setSuccessNewsletter(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Inscription validée !
              </Alert>
            </Collapse>

         
            <Collapse in={errorNewsletter}>
              <Alert
                style={{marginTop: 8}}
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setErrorNewsletter(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
               Une erreur s'est produite lors de ton inscription
              </Alert>
              </Collapse>
            </Grid>
          </Grid>          
          }

          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={mobile ?{ marginTop: 40, paddingLeft: 24, paddingRight: 24 } :{ marginTop: 10, paddingLeft: 80, paddingRight: 80 }}
          >
            <Grid>
              <LogoBlanc />
            </Grid>
            <Grid>
                <Grid container direction="column">
                    <Grid>
                        <Typography 
                            className={classes.typoFooterMentions}
                            component={RouterLink}
                            to="/confidentialite">
                            Politique de confidentialité
                        </Typography>                        
                    </Grid>                    
                    <Grid>
                        <Typography 
                            className={classes.typoFooterMentions}
                            component={RouterLink}
                            to="/mentions">
                            Mentions légales © 2020 Qibish
                        </Typography>                        
                    </Grid>

                </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default withRouter(Solution);
