import React from 'react'

const LogoCompletSvg = props => (
    <svg width={props.width} height={props.height} viewBox="0 0 516.23 162.02"  {...props}>
        <path fill={props.color} d="M103.15,0H31.54A31.58,31.58,0,0,0,0,31.54v98.94A31.58,31.58,0,0,0,31.54,162H134.69V31.54A31.57,31.57,0,0,0,103.15,0ZM11,130.48V31.54A20.57,20.57,0,0,1,31.54,11h71.61A20.57,20.57,0,0,1,123.7,31.54V57.59a22,22,0,0,0-11.14-3H66.81A22.16,22.16,0,0,0,44.68,76.68v63.21a22,22,0,0,0,3,11.14H31.54A20.57,20.57,0,0,1,11,130.48ZM123.7,151H66.81a11.16,11.16,0,0,1-11.14-11.14V76.68A11.16,11.16,0,0,1,66.81,65.54h45.75A11.16,11.16,0,0,1,123.7,76.68Z"/>
        <path fill={props.color} d="M259.38,111.87c4.87,5.7,10.45,11.75,16.26,17.57v1.19H262.71c-4-4.51-8.67-10-13.18-15.79a40.88,40.88,0,0,1-6.77.48c-20.89,0-34.42-12.82-34.42-39.89,0-27.77,14.72-40.24,34.78-40.24,21.37,0,34.42,13.42,34.42,39.65C277.54,93.83,270.9,106.29,259.38,111.87Zm-16.5-5.34c15.43,0,23.39-10.68,23.39-31.34S258.08,44,243.24,44c-14.13,0-23.62,10.68-23.62,31.21S228.16,106.53,242.88,106.53Z"/>
        <path fill={props.color} d="M291.55,39.35c0-4.16,3-7,7.24-7,4.51,0,7.36,2.73,7.36,6.89,0,3.92-3,6.88-7.24,6.88C295,46.11,291.55,43.62,291.55,39.35Zm1.78,74.42V58h9.85l.71.36v43.21c0,4.15,0,8.31.12,12.58H294Z"/>
        <path fill={props.color} d="M323.83,112.47l-.48-.6c.12-5.1.12-10.2.12-15.31V47.42c0-4.51,0-9.62-.23-14.36a83.93,83.93,0,0,1,10.09-1.67l.71.36V65h.47a24,24,0,0,1,18.16-8.43c12.59,0,22.32,8.55,22.32,28.25,0,21-12.23,30.51-29.44,30.51C338.79,115.32,330.24,114.48,323.83,112.47ZM364,85.17C364,71.52,359.08,65,349.71,65c-5.94,0-11.64,3.2-15.67,8.54v32.29a41.5,41.5,0,0,0,11.27,1.54C356.71,107.36,364,99.88,364,85.17Z"/>
        <path fill={props.color} d="M388.28,39.35c0-4.16,3-7,7.24-7,4.51,0,7.36,2.73,7.36,6.89,0,3.92-3,6.88-7.24,6.88C391.72,46.11,388.28,43.62,388.28,39.35Zm1.78,74.42V58h9.85l.71.36v43.21c0,4.15,0,8.31.12,12.58h-10Z"/>
        <path fill={props.color} d="M414.75,111.75a46.46,46.46,0,0,1-.71-8.3l.59-.36a41.67,41.67,0,0,0,17.45,4.39c7.36,0,12-3.2,12-8.43,0-6.17-4.63-6.76-13.18-9.61-10.09-3.32-16-6.17-16-16.62,0-10.09,9.26-16,21.48-16a44.21,44.21,0,0,1,14.13,2.25,31,31,0,0,1,.59,8.07l-.59.36a36.64,36.64,0,0,0-13.77-2.85c-5.94,0-11.4,2.49-11.4,7.72,0,5.93,4.63,6.76,12.11,9.26,9.85,3.2,17.09,5.22,17.09,16.14s-9.73,17.57-22,17.57A48.47,48.47,0,0,1,414.75,111.75Z"/>
        <path fill={props.color} d="M467.8,113.77V46.83c0-4.75,0-9.26-.23-13.77a88.1,88.1,0,0,1,10.09-1.67l.71.36V65.46h.47c5.7-5.93,12.59-8.9,20-8.9,10.2,0,17.09,4.75,17.21,18.87l.12,26.59c0,4,0,8.07.11,12.11H506.14l-.71-.36V76.86c0-8.43-3.08-11.87-9.85-11.87-5.93,0-12.11,3.44-17.21,9.61V102c0,4,0,8.07.24,12.11H468.52Z"/>
    </svg>
)

export default LogoCompletSvg