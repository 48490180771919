import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter, Link as RouterLink } from "react-router-dom";
import { Grid, Typography,Button} from "@material-ui/core";
import {LogoSaumon, Donut} from "./icons"

import { ArrowForward } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  fourofourMain: {
    minHeight: "100vh",
    backgroundColor: "#000000",
  },
  typo404:{
    fontFamily: "Roboto",
    fontSize: 163.8,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: 1.56,
    letterSpacing: 1.64,
    textAlign: "center",
    color: "#ffa175"
  },
  buttonAccueil: {
    height: 48,
    width: 211,
    borderRadius: "14px 14px 0px 14px",
    backgroundColor: "#ffa175",
    textTransform: "none",
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: 1.63,
    letterSpacing: 0,
    color: "#000000",
    textAlign: "left",
    '&:hover':{
        backgroundColor: "#ff864e",
    }
  },
}));
function FourOFour(props) {
  const classes = useStyles(props);

  return (
    <React.Fragment>
      <div className={classes.fourofourMain}>
        <Grid style={{height: "100vh"}} container direction="column" justify="center" alignItems="center">
            <Grid>
                <LogoSaumon/>
            </Grid>
            <Grid style={{marginTop : 50}}>
                <Grid container justify="center" alignItems="center">
                    <Grid>
                        <Typography className={classes.typo404}>4</Typography>
                    </Grid>
                    <Grid>
                        <Donut/>
                    </Grid>
                    <Grid>
                        <Typography className={classes.typo404}>4</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid style={{marginTop : 50}}>
            <Button
            component={RouterLink} to="/"
                        variant="contained"
                        disableElevation
                        className={classes.buttonAccueil}
                        endIcon={<ArrowForward />}
                        >
                        {"Retour à l'accueil"}
                        </Button>
            </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default withRouter(FourOFour);
