import React, { useRef, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withRouter, Link as RouterLink } from "react-router-dom";
import {
  Button,
  Grid,
  Typography,
  Paper,
  InputBase,
  Collapse,
  IconButton,
} from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import Lottie from "react-lottie-player";
import animationData1 from "./images/mission_a.json";
import animationData2 from "./images/mission_b.json";
import animationData3 from "./images/mission_c.json";
import animationData4 from "./images/mission_d.json";
import useWindowSize from "./useWindowSize";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import {
  AtelierCohesion,
  Blog,
  Impecc2,
  LogoBlanc,
  Mission1,
  Mission2,
  Mission3,
} from "./icons";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  main: {
    minHeight: 6000,
    backgroundColor: "#ffffff",
  },
  gridTop: {
    background: "linear-gradient(to bottom,  #ffffff 33%, #f9f9f7 66%)",
    height: 1307,
    position: "relative",
    paddingTop: 219,
    borderBottomLeftRadius: 160,
    [theme.breakpoints.down("md")]: {
      paddingTop: 100,
      height: 1450,
      paddingBottom: 50
    },
  },
  gridChiffres: {
    marginTop: 100,
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
    },
  },
  gridPouvoir: {
    height: 1500,
    position: "relative",
    marginTop: 100,
    [theme.breakpoints.down("md")]: {
      height: 2000,
    },
  },
  gridCapital: {
    marginTop: 300,
    height: 800,
    position: "relative",
    overflow: "visible",
    backgroundColor: "#ffe72f",
    borderBottomRightRadius: 160,
    [theme.breakpoints.down("md")]: {
      height: 500,
    },
  },
  gridSoyons: {
    height: 1300,
    position: "relative",
    paddingTop: 268,
    [theme.breakpoints.down("md")]: {
      minHeight: 1700,      
      paddingTop: 128,
    },
  },
  gridFooter: {
    backgroundColor: "#000000",
    height: 800,
    position: "relative",
    overflow: "visible",
    [theme.breakpoints.down('md')]:{
      height: 600,

    }
  },
  yellowTopRight: {
    position: "absolute",
    top: 0,
    right: 0,
    width: 503,
    height: 772,
    borderBottomLeftRadius: 210,
    backgroundColor: "#ffe72f",
  },
  typoTop: {
    fontFamily: "DM Serif Display",
    fontSize: 56,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.1,
    letterSpacing: 0.5,
    color: "#000000",
    maxWidth: 441,
    [theme.breakpoints.down("md")]: {
      fontSize: 40,
      textAlign: "center",
    },
  },
  highlight: {
    background: "linear-gradient(to top, #ffe72f 50%, transparent 50%)",
  },
  typoTopBody: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: 0,
    color: "#000000",
    maxWidth: 215,
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      maxWidth: "unset",
    },
  },
  buttonBlog: {
    height: 48,
    width: 217,
    borderRadius: "14px 14px 0px 14px",
    backgroundColor: "#ffe72f",
    textTransform: "none",
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: 1.63,
    letterSpacing: 0,
    color: "#000000",
    textAlign: "left",
  },
  buttonSolution: {
    height: 40,
    width: 186,
    borderRadius: 14,
    backgroundColor: "#ffe72f",
    textTransform: "none",
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: 1.63,
    letterSpacing: 0,
    color: "#000000",
    textAlign: "left",
  },
  buttonPuissance: {
    height: 40,
    width: 339,
    borderRadius: 14,
    backgroundColor: "#ffe72f",
    textTransform: "none",
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: 1.63,
    letterSpacing: 0,
    color: "#000000",
    textAlign: "left",
  },
  typoChiffresHeader: {
    display: "inline-block",
    fontFamily: "DM Serif Display",
    fontSize: 96,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 0.7,
    letterSpacing: 0,
    textAlign: "center",
    color: "#000000",
    background: "linear-gradient(to top, #ffe72f 50%, transparent 50%)",
  },
  typoChiffresBody: {
    fontFamily: "Roboto",
    marginTop: 32,
    fontSize: 16,
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: 0,
    color: "#505049",
    whiteSpace: "pre-line",
  },
  typoPouvoirHeader: {
    fontFamily: "DM Serif Display",
    fontSize: 34,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.18,
    letterSpacing: 0.5,
    color: "#000000",
    whiteSpace: "pre-line",
    [theme.breakpoints.down('md')]:{
      textAlign: "center"
    }
  },
  typoPouvoirSection: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: 1.63,
    letterSpacing: 0,
    color: "#020f17",
  },
  typoPouvoirBody: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.63,
    letterSpacing: 0,
    color: "#505049",
    whiteSpace: "pre-line",
  },
  typoSoyonsTop: {
    fontFamily: "DM Serif Display",
    fontSize: 34,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.18,
    letterSpacing: 0.5,
    color: "#000000",
    maxWidth: 519,
    [theme.breakpoints.down('md')]:{
      textAlign: "center"
    }
  },
  bandeauJaune1: {
    position: "absolute",
    top: -35,
    height: 30,
    width: "100%",
    backgroundColor: "#ffe72f",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauJaune2: {
    position: "absolute",
    top: -67,
    height: 25,
    width: "100%",
    backgroundColor: "#ffe72f",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauJaune3: {
    position: "absolute",
    top: -92,
    height: 15,
    width: "100%",
    backgroundColor: "#ffe72f",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauJaune4: {
    position: "absolute",
    top: -112,
    height: 5,
    width: "100%",
    backgroundColor: "#ffe72f",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauJaune1Hidden: {
    position: "absolute",
    top: 0,
    height: 30,
    width: "100%",
    backgroundColor: "#ffe72f",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  bandeauJaune2Hidden: {
    position: "absolute",
    top: 0,
    height: 25,
    width: "100%",
    backgroundColor: "#ffe72f",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  bandeauJaune3Hidden: {
    position: "absolute",
    top: 0,
    height: 15,
    width: "100%",
    backgroundColor: "#ffe72f",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  bandeauJaune4Hidden: {
    position: "absolute",
    top: 0,
    height: 5,
    width: "100%",
    backgroundColor: "#ffe72f",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  pictoAtelierCohesion: {
    position: "absolute",
    top: -70,
  },
  soyonsFiller: {
    position: "absolute",
    width: 160,
    height: 160,
    top: 0,
    left: 0,
    backgroundColor: "#ffe72f",
  },
  soyonsFiller2: {
    position: "absolute",
    width: 160,
    height: 160,
    top: 0,
    left: 0,
    backgroundColor: "#fff",
    borderTopLeftRadius: 160,
  },
  bandeauNoir1: {
    position: "absolute",
    top: -35,
    height: 30,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir2: {
    position: "absolute",
    top: -67,
    height: 25,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir3: {
    position: "absolute",
    top: -92,
    height: 15,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir4: {
    position: "absolute",
    top: -112,
    height: 5,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir1Hidden: {
    position: "absolute",
    top: 0,
    height: 30,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir2Hidden: {
    position: "absolute",
    top: 0,
    height: 25,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir3Hidden: {
    position: "absolute",
    top: 0,
    height: 15,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir4Hidden: {
    position: "absolute",
    top: 0,
    height: 5,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  footerImpecc: {
    width: 586,
    height: 720,
    position: "absolute",
    top: 0,
    right: 0,
    overflow: "hidden",
    objectFit: "contain",
  },
  typoFooter: {
    fontFamily: "DM Serif Display",
    fontSize: 48,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.08,
    letterSpacing: 0.43,
    color: "#ffffff",
    maxWidth: 600,
    [theme.breakpoints.down('md')]:{
      textAlign:"center",
      fontSize: 36
    }
  },
  typoLiensFooter: {
    opacity: 0.64,
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: 1.17,
    letterSpacing: 0,
    color: "#ffffff",
    cursor: "pointer",
    textDecoration: "none",
  },
  typoFooterMentions: {
    opacity: 0.4,
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.67,
    letterSpacing: 0,
    textAlign: "right",
    color: "#ffffff",
    cursor: "pointer",
    textDecoration: "none",
  },
  buttonGo: {
    width: 240,
    height: 240,
    borderRadius: 120,
    backgroundColor: "#000000",
    color: "#ffffff",
    fontFamily: "DM Serif Display",
    fontSize: 48,
    zIndex: 200,
    "&:hover ": {
      backgroundColor: "#212121",
    },
  },
  buttonGoFooter: {
    width: 240,
    height: 240,
    borderRadius: 120,
    backgroundColor: "#ffe72f",
    color: "#000000",
    fontFamily: "DM Serif Display",
    fontSize: 48,
    zIndex: 200,
    "&:hover ": {
      backgroundColor: "rgb(178, 161, 32)",
    },
    [theme.breakpoints.down('md')]:{
      width: 180,
      height: 180,
      borderRadius: 90,
      fontSize: 36,
    }
  },
  footerBlog: {
    position: "absolute",
    top: -14,
    left: -15,
  },
  mission1: {
    position: "absolute",
    top: -100,
    [theme.breakpoints.down("md")]: {
      top: 80,
      left: "calc(50% - 155px)",
    },
  },
  mission2: {
    position: "absolute",
    top: -80,
    left: 120,
    [theme.breakpoints.down("md")]: {
      top: 80,
      left: "calc(50% - 110px)",
    },
  },
  mission3: {
    position: "absolute",
    top: -80,
    left: "calc(50% - 260px)",
    [theme.breakpoints.down("md")]: {
      position: "relative",
      top: 0,
      left: "auto",
    },
  },
}));
function Mission(props) {
  const classes = useStyles(props);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const [animPlay1, setAnimPlay1] = useState(false);
  const [animPlay2, setAnimPlay2] = useState(false);
  const [animPlay3, setAnimPlay3] = useState(false);
  const [animPlay4, setAnimPlay4] = useState(false);
  const windowSize = useWindowSize();
  const anim1Ref = useRef();
  const anim2Ref = useRef();
  const anim3Ref = useRef();
  const anim4Ref = useRef();
  const gridCapitalRef = useRef();
  const gridFooterRef = useRef();
  const [accordeonCapital, setAccordeonCapital] = useState(false);
  const [accordeonFooter, setAccordeonFooter] = useState(false);
  const [mission1Offset, setMission1Offset] = useState(-85);
  const missionImg1Ref = useRef();
  const [mission2Offset, setMission2Offset] = useState(-85);
  const missionImg2Ref = useRef();
  const [mail, setMail] = useState("");
  const [errorNewsletter, setErrorNewsletter] = useState(false);
  const [successNewsletter, setSuccessNewsletter] = useState(false);

  function registerNewsletter(mail) {
    axios
      .post("https://www.qibish.com/newsletter", {
        mail: mail,
      })
      .then((res) => {
        setSuccessNewsletter(true);
        setMail("");
      })
      .catch((error) => {
        setErrorNewsletter(true);
        setMail("");
      });
  }

  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + 300 < windowSize.height) {
        setAnimPlay1(true);
      }
    },
    [],
    anim1Ref
  );
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + 300 < windowSize.height) {
        setAnimPlay2(true);
      }
    },
    [],
    anim2Ref
  );
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + 300 < windowSize.height) {
        setAnimPlay3(true);
      }
    },
    [],
    anim3Ref
  );
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + 300 < windowSize.height) {
        setAnimPlay4(true);
      }
    },
    [],
    anim4Ref
  );
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + 25 < windowSize.height) {
        setAccordeonCapital(true);
      } else {
        setAccordeonCapital(false);
      }
    },
    [],
    gridCapitalRef
  );
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + 25 < windowSize.height) {
        setAccordeonFooter(true);
      } else {
        setAccordeonFooter(false);
      }
    },
    [],
    gridFooterRef
  );
  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (currPos.y - 50 < windowSize.height && currPos.y > -550) {
        let totalpix = currPos.y + 550;
        let parallax = Math.round((totalpix / 9) * -1);
        setMission1Offset(parallax);
      } else {
      }
    },
    [mission1Offset],
    missionImg1Ref
  );
  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (currPos.y - 50 < windowSize.height && currPos.y > -550) {
        let totalpix = currPos.y + 550;
        let parallax = Math.round((totalpix / 9) * -1);
        setMission2Offset(parallax);
      } else {
      }
    },
    [mission2Offset],
    missionImg2Ref
  );
  return (
    <React.Fragment>
      <div className={classes.main}>
        <Grid container justify="center" className={classes.gridTop}>
          {mobile ? null : <div className={classes.yellowTopRight} />}

          <Grid lg={5} xs={12}>
            <Grid container direction="column" alignItems={mobile ? "center":"flex-start"}>
              <Grid>
                <Typography className={classes.typoTop}>
                  <span className={classes.highlight}>
                    {"Allons plus loin ensemble,"}
                  </span>
                  {" en simplifiant la collaboration."}
                </Typography>
              </Grid>
              <Grid style={{ marginTop: 40 }}>
                <Typography className={classes.typoTopBody}>
                  {"Transformons les méthodes de travail en entreprise, tout simplement."}
                </Typography>
              </Grid>
              <Grid style={{ marginTop: 32 }}>
{/* attente du blog                <Button
                  variant="contained"
                  disableElevation
                  className={classes.buttonBlog}
                  endIcon={<ArrowForward />}
                >
                  {"Découvre le blog     "}
                </Button> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            lg={5}
            xs={12}
            style={{ position: "relative", height: 400 }}
            ref={missionImg1Ref}
          >
            <div className={classes.mission1}>
              <Mission1 mobile={mobile} />
            </div>
            <div className={classes.mission2}>
              <Mission2 offset={mission1Offset} mobile={mobile} />
            </div>
          </Grid>
          {mobile ? (
            <Grid container justify="center" className={classes.gridChiffres}>
              <Grid item container direction="column" alignItems="center">
                <Grid item>
                  <Typography
                    className={classes.typoChiffresHeader}
                    align={mobile ? "center" : "left"}
                  >
                    75%
                  </Typography>
                </Grid>
                <Grid>
                  <Typography
                    className={classes.typoChiffresBody}
                    align={mobile ? "center" : "left"}
                  >
                    {
                      "des cadres de la génération Y et Z\npensent que les méthodes de travail\nactuelles bloquent leur potentiel"
                    }
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                style={{ marginTop: 36 }}
              >
                <Grid>
                  <Typography className={classes.typoChiffresHeader}>
                    40%
                  </Typography>
                </Grid>
                <Grid>
                  <Typography
                    className={classes.typoChiffresBody}
                    align={mobile ? "center" : "left"}
                  >
                    {
                      "des cadres français souhaiteraient\nanimer des ateliers collaboratifs dans\nleur entreprise, mais manquent de\ntemps et de compétences."
                    }
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                style={{ marginTop: 36 }}
              >
                <Grid>
                  <Typography className={classes.typoChiffresHeader}>
                    3:1
                  </Typography>
                </Grid>
                <Grid>
                  <Typography
                    className={classes.typoChiffresBody}
                    align={mobile ? "center" : "left"}
                  >
                    {
                      "Il faut en moyenne 3 heures\nde préparation pour 1 heure\nde réunion collaborative."
                    }
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container className={classes.gridChiffres}>
              <Grid lg={1} />
              <Grid lg={3}>
                <Typography className={classes.typoChiffresHeader}>
                  75%
                </Typography>
                <Typography className={classes.typoChiffresBody} align="left">
                  {
                    "des cadres de la génération Y et Z\npensent que les méthodes de travail\nactuelles bloquent leur potentiel"
                  }
                </Typography>
              </Grid>
              <Grid lg={1} />
              <Grid lg={3}>
                <Typography className={classes.typoChiffresHeader}>
                  40%
                </Typography>
                <Typography className={classes.typoChiffresBody} align="left">
                  {
                    "des cadres français souhaiteraient\nanimer des ateliers collaboratifs dans\nleur entreprise, mais manquent de\ntemps et de compétences."
                  }
                </Typography>
              </Grid>
              <Grid lg={1} />
              <Grid lg={3}>
                <Typography className={classes.typoChiffresHeader}>
                  3:1
                </Typography>
                <Typography className={classes.typoChiffresBody} align="left">
                  {
                    "Il faut en moyenne 3 heures\nde préparation pour 1 heure\nde réunion collaborative."
                  }
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid container justify="center" className={classes.gridPouvoir}>
          <Grid lg={1}  />
          <Grid lg={3} xs={12}>
            <Grid container direction="column" alignItems={mobile ? "center":"flex-start"}>
              <Grid>
                <Typography className={classes.typoPouvoirHeader}>
                  {"Le pouvoir\ndes ateliers\ncollaboratifs"}
                </Typography>
              </Grid>
              <Grid style={{ marginTop: 32 }}>
                <Button
                  variant="contained"
                  disableElevation
                  className={classes.buttonSolution}
                  component={RouterLink}
                  to="/solution"
                  endIcon={<ArrowForward />}
                >
                  {"Découvre la solution     "}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid lg={3} xs={12} ref={anim1Ref} style={mobile ? {marginTop: 24}:{}} >
            <Grid container justify={mobile ? "center":"flex-start"}>
            <Lottie
              loop={false}
              speed={0.5}
              animationData={animationData1}
              play={animPlay1}
              style={{ width: 242, height: 242 }}
            />
            </Grid>

          </Grid>
          <Grid lg={4} xs={12}>
            <Grid container direction="column" alignItems={mobile ? "center":"flex-start"}>
              <Grid>
                <Typography className={classes.typoPouvoirSection}>
                  Efficacité et Productivité
                </Typography>
              </Grid>
              <Grid style={{ marginTop: 8 }}>
                <Typography className={classes.typoPouvoirBody}>
                  {
                    "Les ateliers collaboratifs apportent la\nstructure nécessaire pour atteindre vos\nobjectifs, soutenue par des règles et des\nconsignes qui rythment les discussions.\nLes échanges sont structurés étape par\nétape et l'information collectée devient\nriche et structurée."
                  }
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid lg={1}  />
          <Grid lg={1}  />
          <Grid lg={3}   />

          <Grid lg={3} xs={12} style={mobile ? {marginTop:24}: { paddingTop: 170 }} ref={anim2Ref}>
          <Grid container justify={mobile ? "center":"flex-start"}>
            <Lottie
              loop={false}
              speed={0.5}
              animationData={animationData2}
              play={animPlay2}
              style={{ width: 242, height: 242 }}
            />
          </Grid>

          </Grid>
          <Grid lg={4} xs={12} style={mobile ? {marginTop:24} :{ paddingTop: 190 }}>
            <Grid container direction="column" alignItems={mobile ? "center":"flex-start"}>
              <Grid>
                <Typography className={classes.typoPouvoirSection}>
                  Adhésion et Engagement
                </Typography>
              </Grid>
              <Grid style={{ marginTop: 8 }}>
                <Typography className={classes.typoPouvoirBody}>
                  {
                    "Les collaborateurs partagent leur visions\net ambitions, alignent les objectifs du\ngroupe avec ceux du projet et de\nl'entreprise, l'adhésion et l'ambition de\nréussir émergent. Ils s'engagent à\natteindre les objectifs définis en groupe."
                  }
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid lg={1} />
          <Grid lg={1} />
          <Grid lg={3} />

          <Grid lg={3} xs={12} style={mobile ? {marginTop:24}: { paddingTop: 170 }} ref={anim3Ref}>
          <Grid container justify={mobile ? "center":"flex-start"}>
          <Lottie
              loop={false}
              speed={0.5}
              animationData={animationData3}
              play={animPlay3}
              style={{ width: 242, height: 242 }}
            />
          </Grid>

          </Grid>
          <Grid lg={4} xs={12} style={mobile ? {marginTop:24} :{ paddingTop: 190 }}>
            <Grid container direction="column" alignItems={mobile ? "center":"flex-start"}>
              <Grid>
                <Typography className={classes.typoPouvoirSection}>
                  Cohésion et Collaboration
                </Typography>
              </Grid>
              <Grid style={{ marginTop: 8 }}>
                <Typography className={classes.typoPouvoirBody}>
                  {
                    "Les participants travaillent\ncollaborativement, construisent sur les\nidées des uns et des autres, résolvent les\nproblématiques ensemble et construisent\nle socle d'une équipe unie."
                  }
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid lg={1} />
          <Grid lg={1} />
          <Grid lg={3} />

          <Grid lg={3} xs={12} style={mobile ? {marginTop:24}: { paddingTop: 170 }}ref={anim4Ref}>
          <Grid container justify={mobile ? "center":"flex-start"}>
          <Lottie
              loop={false}
              speed={0.5}
              animationData={animationData4}
              play={animPlay4}
              style={{ width: 242, height: 242 }}
            />
          </Grid>
          </Grid>
          <Grid lg={4} xs={12} style={mobile ? {marginTop:24} :{ paddingTop: 190 }}>
            <Grid container direction="column" alignItems={mobile ? "center":"flex-start"}>
              <Grid>
                <Typography className={classes.typoPouvoirSection}>
                  Challenge et Dépassement de soi
                </Typography>
              </Grid>
              <Grid style={{ marginTop: 8 }}>
                <Typography className={classes.typoPouvoirBody}>
                  {
                    "Travailler différemment permet aux\ncollaborateurs d'apprendre de nouvelles\ncompétences, d'identifier des axes\nd'amélioration continue et de réinventer ce\nqui est de l'acquis depuis longtemps."
                  }
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid lg={1} />
        </Grid>
        <Grid container direction={mobile ? "column":"row"} alignItems={mobile ? "center":"flex-start"} justify={mobile ? "flex-start":"flex-start"} className={classes.gridCapital} ref={gridCapitalRef}>
          <div
            className={
              accordeonCapital
                ? classes.bandeauJaune1
                : classes.bandeauJaune1Hidden
            }
          />
          <div
            className={
              accordeonCapital
                ? classes.bandeauJaune2
                : classes.bandeauJaune2Hidden
            }
          />
          <div
            className={
              accordeonCapital
                ? classes.bandeauJaune3
                : classes.bandeauJaune3Hidden
            }
          />
          <div
            className={
              accordeonCapital
                ? classes.bandeauJaune4
                : classes.bandeauJaune4Hidden
            }
          />
          {mobile ?
          <>
          <Grid style={{marginTop: 64}}>
                <Typography className={classes.typoPouvoirSection}>
                  La mission de Qibish est une évidence
                </Typography>            
          </Grid>
          <Grid style={{marginTop: 64}} >
          <Typography className={classes.typoTop}>
                  {"Faire du capital\nhumain votre\navantage\nconcurrentiel."}
                </Typography>           
          </Grid>
          </>
        :
        <>
          <Grid lg={1} />
          <Grid lg={4} style={{ paddingTop: 200 }}>
            <Grid container direction="column">
              <Grid>
              <Typography className={classes.typoPouvoirSection}>
                  La mission de Qibish est une évidence
                </Typography>    
              </Grid>
              <Grid>
                <Typography className={classes.typoTop}>
                  {"Faire du capital\nhumain votre\navantage\nconcurrentiel."}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid lg={1} />
          <Grid lg={6} style={{ position: "relative", overflow: "hidden", height: 700 }}>
            <div className={classes.pictoAtelierCohesion}>
              <AtelierCohesion />
            </div>
          </Grid>
        </>
        }

        </Grid>
        <Grid container className={classes.gridSoyons} direction={mobile? "column":"row"} alignItems={mobile? "center": "flex-start"} justify={mobile ? "flex-start":"flex-start"}>
          <div className={classes.soyonsFiller} />
          <div className={classes.soyonsFiller2} />
          <Grid lg={6} xs={"auto"} ref={missionImg2Ref} style={mobile ? { position: "relative",   }:{ position: "relative" }}>
            <div className={classes.mission3}>
              <Mission3 offset={mission2Offset} mobile={mobile} />
            </div>
          </Grid>
          <Grid lg={6} xs={"auto"}>
            <Grid container direction="column" style={mobile ? {padding:16}:{}}>
              <Grid>
                <Typography className={classes.typoSoyonsTop}>
                  {
                    "Les problématiques auxquelles les entreprises ont à faire face sont de plus en plus complexes dans un "
                  }
                  <span className={classes.highlight}>
                    {"écosystème en constante évolution."}
                  </span>
                </Typography>
              </Grid>
              <Grid style={{ maxWidth: 483, marginTop: 120 }}>
                <Typography className={classes.typoPouvoirBody}>
                  {
                    "Soyons honnêtes. Peu importe le nombre de tableaux blancs digitaux créés et de sondages en ligne lancés au sein d’une équipe, cela ne transforme pas les méthodes de travail en entreprise. Ils ont un impact ponctuel sur la participation des collaborateurs, rendent une réunion moins ennuyeuse, mais ils ne renforcent pas profondément la "
                  }
                  <span style={{ fontWeight: "bold" }}>collaboration</span>
                  {", la "}
                  <span style={{ fontWeight: "bold" }}>productivité</span>
                  {" ni "}
                  <span style={{ fontWeight: "bold" }}>
                    l’engagement des salariés
                  </span>
                  {
                    ". Au final, les entreprises qui peuvent se le permettre sollicitent des prestataires pour animer de "
                  }
                  <span style={{ fontWeight: "bold" }}>
                    vrais ateliers collaboratifs impactants
                  </span>
                  {" et à forte valeur ajoutée."}
                </Typography>
              </Grid>
              <Grid style={{ maxWidth: 483, marginTop: 24 }}>
                <Typography className={classes.typoPouvoirBody}>
                  <span style={{ fontWeight: "bold" }}>Chez Qibish</span>
                  {
                    ", nous pensons que toi et tes collaborateurs méritez d’accéder simplement à la "
                  }
                  <span style={{ fontWeight: "bold" }}>
                    puissance des méthodes et outils d’intelligence collective
                  </span>
                  {
                    ", associée à l’expertise de facilitateurs aguerris, au service de tous les métiers et niveaux de compétence. Et surtout, "
                  }
                  <span style={{ fontWeight: "bold" }}>
                    sans devoir casser ta tirelire.
                  </span>
                </Typography>
              </Grid>
              <Grid style={{ marginTop: 40 }}>
                <Button
                  variant="contained"
                  disableElevation
                  className={classes.buttonPuissance}
                  endIcon={<ArrowForward />}
                >
                  {"Commence à utiliser la puissance de Qibish"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justify="flex-start"
          direction="column"
          className={classes.gridFooter}
          ref={gridFooterRef}
        >
          <div
            className={
              accordeonFooter
                ? classes.bandeauNoir1
                : classes.bandeauNoir1Hidden
            }
          />
          <div
            className={
              accordeonFooter
                ? classes.bandeauNoir2
                : classes.bandeauNoir2Hidden
            }
          />
          <div
            className={
              accordeonFooter
                ? classes.bandeauNoir3
                : classes.bandeauNoir3Hidden
            }
          />
          <div
            className={
              accordeonFooter
                ? classes.bandeauNoir4
                : classes.bandeauNoir4Hidden
            }
          />
          {mobile ? null:
          <div className={classes.footerImpecc}>
            <Impecc2 />
          </div>          
          }

          <Grid
            container
            justify={mobile ? "center":"flex-start"}
            style={mobile ?{ marginTop:36 } :{ marginTop: 176, paddingLeft: 80, height: 250 }}
          >
            <Grid>
              <Typography className={classes.typoFooter}>
                {
                  "Révolutionne les méthodes de travail collaboratif avec Qibish "
                }
              </Typography>
            </Grid>
            <Grid style={mobile ?{marginTop: 24}:{ paddingLeft: "20%" }}>
              <Button
                disableElevation
                variant="contained"
                className={classes.buttonGoFooter}
                onClick={() => props.goToApp()}
              >
                GO !
              </Button>
            </Grid>
          </Grid>
          {mobile ? 
          <Grid container justify="center" style={{marginTop: 34}}>
            <Grid xs={10}>
              <Paper
                component="form"
                elevation={0}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: mobile ? "100%":303,
                  height: 48,
                  borderRadius: 16,
                  backgroundColor: "#202020",
                  opacity: 0.39,
                }}
                onSubmit={e => {e.preventDefault(); registerNewsletter(mail)}}
              >
                <InputBase
                onChange={(e) => setMail(e.target.value)}
                value={mail}
                  placeholder="S'inscrire à la newsletter"
                  inputProps={{ "aria-label": "S'inscrire à la newsletter" }}
                  style={{
                    marginLeft: 16,
                    flex: 1,
                    color: "#fff",
                    fontSize: 12,
                  }}
                />
                <Button disableElevation style={{ color: "#fff" }} onClick={() =>  registerNewsletter(mail)}>
                  OK
                </Button>
              </Paper>
              <Collapse in={successNewsletter}>
              <Alert
                style={{marginTop: 8}}
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setSuccessNewsletter(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Inscription validée !
              </Alert>
            </Collapse>

         
            <Collapse in={errorNewsletter}>
              <Alert
                style={{marginTop: 8}}
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setErrorNewsletter(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
               Une erreur s'est produite lors de ton inscription
              </Alert>
              </Collapse>
            </Grid>
          </Grid>
          :
          <Grid
            container
            justify="space-between"
            alignItems="flex-start"
            style={{
              marginTop: 70,
              paddingLeft: 80,
              paddingRight: 80,
              height: 150,
            }}
          >
            <Grid>
              <Grid container style={{ width: 310 }} alignItems="center">
                <Grid xs={3}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/"
                  >
                    Accueil
                  </Typography>
                </Grid>
                <Grid xs={3}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/prix"
                  >
                    Prix
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ position: "relative" }}>
                  <Typography className={classes.typoLiensFooter}>
                    Blog
                  </Typography>
                  <div className={classes.footerBlog}>
                    <Blog />
                  </div>
                </Grid>
                <Grid xs={3}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/contact"
                  >
                    Contact
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ marginTop: 24 }}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/solution"
                  >
                    Solution
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ marginTop: 24 }}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/mission"
                  >
                    Mission
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ marginTop: 24 }}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/cgu"
                  >
                    CGU
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ marginTop: 24 }}>
                  <Typography className={classes.typoLiensFooter} onClick={() => props.goToApp()}>
                    Se connecter
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <Paper
                component="form"
                elevation={0}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: 303,
                  height: 48,
                  borderRadius: 16,
                  backgroundColor: "#202020",
                  opacity: 0.39,
                }}
                onSubmit={e => {e.preventDefault(); registerNewsletter(mail)}}
              >
                <InputBase
                onChange={(e) => setMail(e.target.value)}
                value={mail}
                  placeholder="S'inscrire à la newsletter"
                  inputProps={{ "aria-label": "S'inscrire à la newsletter" }}
                  style={{
                    marginLeft: 16,
                    flex: 1,
                    color: "#fff",
                    fontSize: 12,
                  }}
                />
                <Button disableElevation style={{ color: "#fff" }} onClick={() =>  registerNewsletter(mail)}>
                  OK
                </Button>
              </Paper>
              <Collapse in={successNewsletter}>
              <Alert
                style={{marginTop: 8}}
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setSuccessNewsletter(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Inscription validée !
              </Alert>
            </Collapse>

         
            <Collapse in={errorNewsletter}>
              <Alert
                style={{marginTop: 8}}
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setErrorNewsletter(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
               Une erreur s'est produite lors de ton inscription
              </Alert>
              </Collapse>
            </Grid>
          </Grid>          
          }

          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={mobile ?{ marginTop: 40, paddingLeft: 24, paddingRight: 24 } :{ marginTop: 10, paddingLeft: 80, paddingRight: 80 }}
          >
            <Grid>
              <LogoBlanc />
            </Grid>
            <Grid>
                <Grid container direction="column">
                    <Grid>
                        <Typography 
                            className={classes.typoFooterMentions}
                            component={RouterLink}
                            to="/confidentialite">
                            Politique de confidentialité
                        </Typography>                        
                    </Grid>                    
                    <Grid>
                        <Typography 
                            className={classes.typoFooterMentions}
                            component={RouterLink}
                            to="/mentions">
                            Mentions légales © 2020 Qibish
                        </Typography>                        
                    </Grid>

                </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default withRouter(Mission);
