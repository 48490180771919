import React, { useEffect, useRef, useState  } from "react";
import { makeStyles, withStyles , useTheme} from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withRouter, Link as RouterLink } from "react-router-dom";
import { Grid, Typography, Switch, Button, Divider, InputBase, Paper, Collapse, IconButton } from "@material-ui/core";
import { FlecheAnnuel, Eco20, Check, Interrogation, Blog, LogoBlanc, Impecc2 } from "./icons";
import { ArrowForward, KeyboardArrowDown } from "@material-ui/icons";
import useWindowSize from "./useWindowSize";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { Alert } from '@material-ui/lab'
import CloseIcon from '@material-ui/icons/Close';
import axios from "axios"

const AnnuelSwitch = withStyles({
  switchBase: {
    color: "#fff",
    "&$checked": {
      color: "#fff",
    },
    "&$checked + $track": {
      backgroundColor: "#9b9b9b",
    },
  },
  checked: {},
  track: {
    backgroundColor: "#9b9b9b",
  },
})(Switch);
const useStyles = makeStyles((theme) => ({
  prixMain: {
    minHeight: 5156,
    paddingTop: 206,
    backgroundColor: "#000000",
    [theme.breakpoints.down('md')]:{
      paddingTop: 100,
      minHeight: 4000
    }
  },
  gridTopSubHeader: {
    marginTop: 40,
  },
  gridPrix: {
    marginTop: 60,
    paddingLeft: 80,
    paddingRight: 80,
    [theme.breakpoints.down('md')]:{
      paddingLeft: 16,
      paddingRight: 16
    }
  },
  gridPrixComparaison: {
    marginTop: 84,
    paddingLeft: 80,
    paddingRight: 80,
    paddingBottom : 300
  },
  gridContact: {
    height: 967,
    backgroundColor: "#fff",
    position: "relative",
    overflow: "visible",
    [theme.breakpoints.down('md')]:{
     marginTop: 200
    }
  },
  gridFooter: {
    backgroundColor: "#000000",
    height: 800,
    position: "relative",
    overflow: "visible",
    [theme.breakpoints.down('md')]:{
      height: 600,
    }
  },
  typoTop: {
    fontFamily: "DM Serif Display",
    fontSize: 56,
    fontWeight: "normal",
    fontStyle: "normal",
    letterSpacing: 0.5,
    textAlign: "center",
    color: "#fff",
    lineHeight: 1,
    [theme.breakpoints.down('md')]:{
      fontSize: 46,
      display: "inline",
      position: "relative"
    }
  },
  typoTopHighlight: {
    fontFamily: "DM Serif Display",
    fontSize: 56,
    fontWeight: "normal",
    fontStyle: "normal",
    letterSpacing: 0.5,
    textAlign: "center",
    color: "#fff",
    background: "linear-gradient(to top, #262626 50%, transparent 50%)",
    lineHeight: 1.2,
    [theme.breakpoints.down('md')]:{
      fontSize: 46,
      display: "inline",
      position: "relative"
    }
  },
  typoTopSubHeader: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: 1.65,
    letterSpacing: 0,
    textAlign: "center",
    color: "#fff",
    whiteSpace: "pre-line",
    maxWidth: 612,
  },
  typoMensuel: {
    opacity: 0.4,
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.29,
    letterSpacing: 0,
    color: "#ffffff",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  typoMensuelSelected: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.29,
    letterSpacing: 0,
    color: "#ffe72f",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  fleche: {
    position: "absolute",
    top: -40,
    right: -15,
  },
  eco: {
    position: "absolute",
    top: -50,
    right: -150,
  },
  cardPrixNoir: {
    width: 410,
    height: 442,
    border: "6px solid #1E1E1E",
    borderRadius: "56px 56px 0px 56px",
    [theme.breakpoints.down('md')]:{
      height: 550
    }

  },
  cardPrixJaune: {
    width: 410,
    height: 474,
    backgroundColor: "#ffe72f",
    borderRadius: "56px 56px 0px 56px",
  },
  typoHeaderTypeBlanc: {
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: 1.17,
    letterSpacing: 0,
    textAlign: "center",
    color: "#ffffff",
  },
  typoHeaderPrixBlanc: {
    fontFamily: "Roboto",
    fontSize: 40,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: 0.7,
    letterSpacing: 0,
    textAlign: "center",
    color: "#ffffff",
  },
  typoHeaderFeatures: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: 1.63,
    letterSpacing: 0,
    textAlign: "center",
    color: "#ffffff",
    whiteSpace: "pre-line",
  },
  buttonGris: {
    height: 48,
    borderRadius: "14px 14px 0px 14px",
    backgroundColor: "#202020",
    fontFamily: "Heebo",
    fontSize: 16,
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: 0,
    color: "#ffffff",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#434343",
    },
  },
  voirComparaison: {
    height: 40,
    borderRadius: 14,
    backgroundColor: "#202020",
    fontFamily: "Heebo",
    fontSize: 14,
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: 0,
    color: "#ffffff",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#434343",
    },
  },
  typoHeaderTypeNoir: {
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: 1.17,
    letterSpacing: 0,
    textAlign: "center",
    color: "#000",
  },
  typoHeaderPrixNoir: {
    fontFamily: "Roboto",
    fontSize: 40,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: 0.7,
    letterSpacing: 0,
    textAlign: "center",
    color: "#000",
  },
  typoHeaderFeaturesNoir: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: 1.63,
    letterSpacing: 0,
    textAlign: "center",
    color: "#000",
    whiteSpace: "pre-line",
  },
  buttonBlanc: {
    height: 48,
    borderRadius: "14px 14px 0px 14px",
    backgroundColor: "#fff",
    fontFamily: "Heebo",
    fontSize: 16,
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: 0,
    color: "#000",
    textTransform: "none",
  },
  buttonJaune: {
    height: 48,
    width: 206,
    borderRadius: "14px 14px 0px 14px",
    backgroundColor: "#ffe72f",
    fontFamily: "Heebo",
    fontSize: 16,
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: 0,
    color: "#000",
    textTransform: "none",
  },
  buttonJauneContact: {
    height: 48,
    width: 290,
    borderRadius: "14px 14px 0px 14px",
    backgroundColor: "#ffe72f",
    fontFamily: "Heebo",
    fontSize: 16,
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: 0,
    color: "#000",
    textTransform: "none",
  },
  typoHeaderGris: {
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: 2.17,
    letterSpacing: 0,
    textAlign: "center",
    color: "#4c4c36",
  },
  typoPrixMois: {
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: 1.17,
    letterSpacing: 0,
    textAlign: "center",
    color: "#000000",
  },
  typoHeaderLigneGris: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: 0,
    color: "#7b7a74",
  },
  typoHeaderLigneBlanc: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: 0,
    color: "#fff",
  },
  typoLigneGris: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: 1.29,
    letterSpacing: 0,
    textAlign: "center",
    color: "#9b9b9b",
  },
  ligneTableauSection: {
    height: 54,
    borderRadius: 8,
    marginTop: 8,
    paddingLeft: 16,
    "&:hover": {
      backgroundColor: "rgba(32, 32, 32, 0.4)",
    },
  },
  divider: {
    marginLeft: 16,
    marginRight: 16,
    marginTop: 8,
    width: "calc(100% - 32px)",
    backgroundColor: "#979797",
    opacity: 0.16,
  },
  typoParMoisBlanc: {
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: 1.17,
    letterSpacing: 0,
    textAlign: "center",
    color: "#ffffff",
  },
  typoContact:{
    zIndex: 15,
    maxWidth: 331,
    fontFamily: "DM Serif Display",
    fontSize: 56,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: 0.5,
    textAlign: "center",
    color: "#222220"
  },
  tribu: {
    top: 0,
    width:"100%", 
    position:"absolute", 
    backgroundColor: "rgba(18, 18, 18, 0.32)", 
    borderRadius: "40px 40px 0px 40px"
  },
  typoFooterPrix:{
    fontFamily: "DM Serif Display",
    fontSize: 34,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.18,
    letterSpacing: 0.5,
    textAlign: "center",
    color: "#ffffff",
    maxWidth: 990,
  },
  bandeauBlanc1: {
    position: "absolute",
    top: -35,
    height: 30,
    width: "100%",
    backgroundColor: "#ffffff",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauBlanc2: {
    position: "absolute",
    top: -67,
    height: 25,
    width: "100%",
    backgroundColor: "#ffffff",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauBlanc3: {
    position: "absolute",
    top: -92,
    height: 15,
    width: "100%",
    backgroundColor: "#ffffff",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauBlanc4: {
    position: "absolute",
    top: -112,
    height: 5,
    width: "100%",
    backgroundColor: "#ffffff",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauBlanc1Hidden: {
    position: "absolute",
    top: 0,
    height: 30,
    width: "100%",
    backgroundColor: "#ffffff",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  bandeauBlanc2Hidden: {
    position: "absolute",
    top: 0,
    height: 25,
    width: "100%",
    backgroundColor: "#ffffff",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  bandeauBlanc3Hidden: {
    position: "absolute",
    top: 0,
    height: 15,
    width: "100%",
    backgroundColor: "#ffffff",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  bandeauBlanc4Hidden: {
    position: "absolute",
    top: 0,
    height: 5,
    width: "100%",
    backgroundColor: "#ffffff",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  interrogation:{
    position: "absolute",
    top: "calc(50% - 155px)",
    right: "calc(50% - 245px)",
    [theme.breakpoints.down('md')]:{
      right: "calc(50% - 145px)",
    }
  },
  bandeauNoir1: {
    position: "absolute",
    top: -35,
    height: 30,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir2: {
    position: "absolute",
    top: -67,
    height: 25,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir3: {
    position: "absolute",
    top: -92,
    height: 15,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir4: {
    position: "absolute",
    top: -112,
    height: 5,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir1Hidden: {
    position: "absolute",
    top: 0,
    height: 30,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir2Hidden: {
    position: "absolute",
    top: 0,
    height: 25,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir3Hidden: {
    position: "absolute",
    top: 0,
    height: 15,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir4Hidden: {
    position: "absolute",
    top: 0,
    height: 5,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  footerImpecc: {
    width: 586,
    height: 720,
    position: "absolute",
    top: 0,
    right: 0,
    overflow: "hidden",
    objectFit: "contain",
  },
  typoFooter: {
    fontFamily: "DM Serif Display",
    fontSize: 48,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.08,
    letterSpacing: 0.43,
    color: "#ffffff",
    maxWidth: 600,
    [theme.breakpoints.down('md')]:{
      textAlign:"center",
      fontSize: 36
    }
  },
  typoLiensFooter: {
    opacity: 0.64,
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: 1.17,
    letterSpacing: 0,
    color: "#ffffff",
    cursor: "pointer",
    textDecoration: "none",
  },
  typoFooterMentions: {
    opacity: 0.4,
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.67,
    letterSpacing: 0,
    textAlign: "right",
    color: "#ffffff",
    cursor: "pointer",
    textDecoration: "none",
  },
  buttonGo: {
    width: 240,
    height: 240,
    borderRadius: 120,
    backgroundColor: "#000000",
    color: "#ffffff",
    fontFamily: "DM Serif Display",
    fontSize: 48,
    zIndex: 200,
    "&:hover ": {
      backgroundColor: "#212121",
    },
  },
  buttonGoFooter: {
    width: 240,
    height: 240,
    borderRadius: 120,
    backgroundColor: "#ffe72f",
    color: "#000000",
    fontFamily: "DM Serif Display",
    fontSize: 48,
    zIndex: 200,
    "&:hover ": {
      backgroundColor: "rgb(178, 161, 32)",
    },
    [theme.breakpoints.down('md')]:{
      width: 180,
      height: 180,
      borderRadius: 90,
      fontSize: 36,
    }
  },
  footerBlog: {
    position: "absolute",
    top: -14,
    left: -15,
  },
}));

function Prix(props) {
  const classes = useStyles(props);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const windowSize = useWindowSize();
  const [annuel, setAnnuel] = useState(true);
  const gridPrixRef = useRef();
  const tribuTopRef = useRef()
  const tribuBottomRef = useRef()
  const [tribuHeight, setTribuHeight]  = useState(0)
  const [tribuTop, setTribuTop]  = useState(0)
  const [tribuWidth, setTribuWidth]  = useState(0)
  const [tribuLeft, setTribuLeft]  = useState(0)
  const contactRef = useRef()
  const [accordeonContact, setAccordeonContact] = useState(false)
  const [accordeonFooter, setAccordeonFooter] = useState(false)
  const gridFooterRef = useRef()
  const [mail, setMail] = useState("")
  const [errorNewsletter, setErrorNewsletter] = useState(false)
  const [successNewsletter, setSuccessNewsletter] = useState(false)

  function registerNewsletter(mail){
    axios.post("https://www.qibish.com/newsletter", {
      mail:mail
    })
    .then((res) => {setSuccessNewsletter(true); setMail("")})
    .catch((error) => {
      setErrorNewsletter(true)
      setMail("")
    });
  }

  const scrollToRefObject = (ref) =>
    window.scroll({
      top: ref.current.offsetTop - 50,
      left: 0,
      behavior: "smooth",
    });

    useEffect(() => {
      if(tribuTopRef && tribuBottomRef && mobile===false){
        let height = (tribuBottomRef.current.offsetTop - tribuTopRef.current.offsetTop) + 120
        setTribuHeight(height)
        setTribuTop(tribuTopRef.current.offsetTop )
        setTribuWidth(tribuTopRef.current.offsetWidth)
        setTribuLeft(tribuTopRef.current.offsetLeft)
      }
    },[tribuTopRef, tribuBottomRef, windowSize, mobile])

    useScrollPosition(
      ({ currPos }) => {
       
        if (currPos.y + 25 < windowSize.height) {
          setAccordeonContact(true);
        } else {
          setAccordeonContact(false);
        }
      },
      [],
      contactRef
    );
    useScrollPosition(
      ({ currPos }) => {
        
        if (currPos.y + 25 < windowSize.height) {
          setAccordeonFooter(true);
        } else {
          setAccordeonFooter(false);
        }
      },
      [],
      gridFooterRef
    );
  return (
    <React.Fragment>
      {mobile ? null:
      <div  className={classes.tribu} style={{height: tribuHeight, top: tribuTop, width: tribuWidth, left: tribuLeft}}/>      
      
      }
      
      <div className={classes.prixMain}>

        <Grid container justify="center">
          <Grid container justify="center" item>
            <Typography className={classes.typoTop}>
              Tire le maximum de la
            </Typography>
          </Grid>
          <Grid container justify="center" item style={mobile ? { textAlign: "center" } : {}}>
            <Grid>
            <Typography className={classes.typoTopHighlight}>
              puissance de ton équipe
            </Typography>              
            </Grid>

          </Grid>
        </Grid>
        <Grid container justify="center" className={classes.gridTopSubHeader}>
          <Typography className={classes.typoTopSubHeader}>
            {
              "Découvre la solution Qibish, sans limite et sans carte de crédit. Plus d’excuse pour ne pas booster la collaboration et la productivité de ton équipe."
            }
          </Typography>
        </Grid>
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ marginTop: 86 }}
        >
          <Grid>
            <Typography
              className={
                annuel ? classes.typoMensuel : classes.typoMensuelSelected
              }
            >
              Mensuel
            </Typography>
          </Grid>
          <Grid style={{ paddingLeft: 16, paddingRight: 16 }}>
            <AnnuelSwitch
              checked={annuel}
              size="small"
              onChange={() => setAnnuel(!annuel)}
              inputProps={{ "aria-label": "annuel" }}
            />
          </Grid>
          <Grid style={{ position: "relative", overflow: "visible" }}>
            <Typography
              className={
                annuel ? classes.typoMensuelSelected : classes.typoMensuel
              }
            >
              Annuel
            </Typography>
            {mobile ?
           null 
            :
            <>
            <div className={classes.fleche}>
              <FlecheAnnuel />
            </div>
            <div className={classes.eco}>
              <Eco20 />
            </div>
            </>
            }

          </Grid>
        </Grid>
        <Grid container alignItems={mobile ? "center":"flex-end"} className={classes.gridPrix}>
          <Grid lg={4} xs={12} style={mobile ?{} :{ paddingRight: 12 }}>
            <Grid container justify={mobile ? "center":"flex-end"}>
              <div className={classes.cardPrixNoir}>
                <Grid container direction="column">
                  <Grid style={{ paddingTop: 65 }}>
                    <Typography className={classes.typoHeaderTypeBlanc}>
                      ÉQUIPE
                    </Typography>
                  </Grid>
                  <Grid style={{ paddingTop: 28 }}>
                    <Typography className={classes.typoHeaderPrixBlanc}>
                      Gratuit
                    </Typography>
                  </Grid>
                  <Grid style={{ paddingTop: 60 }}>
                    <Typography className={classes.typoHeaderFeatures}>
                      {mobile ?
                  "Accès immédiat à Qibish\nJusqu'à 5 participants\nCréation d'ateliers illimitée\nBibliothèque de 42 activités\nBibliothèque de cas d'usage\nArchivage illimité\nSupport par email"
                      :
                        "Jusqu'à 5 participants\nCréation d'ateliers illimitée\nArchivage sans limite"
                      }
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    justify="center"
                    style={{ paddingTop: 50 }}
                  >
                    <Button
                      disableElevation
                      variant="contained"
                      endIcon={<ArrowForward />}
                      className={classes.buttonGris}
                      onClick={() => props.goToApp()}
                    >
                      Je m'inscris
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Grid lg={4} xs={12} style={mobile ? {marginTop: 16}:{ paddingRight: 12, paddingLeft: 12 }}>
            <Grid container justify="center">
              <div className={classes.cardPrixJaune}>
                <Grid container direction="column">
                  <Grid style={{ paddingTop: 65 }}>
                    <Typography className={classes.typoHeaderTypeNoir}>
                      TRIBU
                    </Typography>
                  </Grid>
                  <Grid style={{ paddingTop: 28 }}>
                    <Typography className={classes.typoHeaderPrixNoir}>
                      {annuel ? "7,90€" : "8,90€"}
                    </Typography>
                  </Grid>
                  <Grid style={{ paddingTop: 12 }}>
                    <Typography className={classes.typoPrixMois}>
                      /MOIS
                    </Typography>
                  </Grid>
                  <Grid style={{ paddingTop: 28 }}>
                    <Typography className={classes.typoHeaderGris}>
                      EN PLUS D'ÉQUIPE
                    </Typography>
                  </Grid>
                  <Grid style={{ paddingTop: 20 }}>
                    <Typography className={classes.typoHeaderFeaturesNoir}>
                      {mobile ?
                        "Jusqu'à 150 participants\nPersonnalisation des paramètres d'activités\nStatistiques de collaboration\nSessions de formation"
                      :
                        "Jusqu'à 150 participants\nPersonnalisation des paramètres d'activités\nSessions de formation"
                      }
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    justify="center"
                    style={{ paddingTop: 50 }}
                  >
                    <Button
                      disableElevation
                      variant="contained"
                      endIcon={<ArrowForward />}
                      className={classes.buttonBlanc}
                      onClick={() => annuel ? props.goToAppPaymentAnnuel(): props.goToAppPaymentMensuel()}
                    >
                      Je choisis l'offre
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Grid lg={4} xs={12} style={mobile ?{marginTop:16}: { paddingLeft: 12 }}>
            <Grid container>
              <div className={classes.cardPrixNoir}>
                <Grid container direction="column">
                  <Grid style={{ paddingTop: 65 }}>
                    <Typography className={classes.typoHeaderTypeBlanc}>
                      COMMUNAUTÉ
                    </Typography>
                  </Grid>
                  <Grid style={{ paddingTop: 28 }}>
                    <Typography className={classes.typoHeaderPrixBlanc}>
                      Sur demande
                    </Typography>
                  </Grid>
                  <Grid style={mobile ? {paddingTop: 28}:{ paddingTop: 21 }}>
                    <Typography className={classes.typoHeaderGris}>
                      EN PLUS DE TRIBU
                    </Typography>
                  </Grid>
                  <Grid style={{ paddingTop: 16 }}>
                    <Typography className={classes.typoHeaderFeatures}>
                      {mobile ?
                        "Nombre de participants illimité\nPersonnalisation des cas d'usages\nSupport par des experts facilitateurs\nSSO\nHébergement privé\nGestion des abonnements centralisée"
                      :
                      "Nombre de participants illimité\nPersonnalisation des cas d'usages\nSupport par des experts facilitateurs"
                      }
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    justify="center"
                    style={{ paddingTop: 50 }}
                  >
                    <Button
                      disableElevation
                      variant="contained"
                      endIcon={<ArrowForward />}
                      className={classes.buttonGris}
                      component={RouterLink}
                      to="/contact"
                    >
                      Nous contacter
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {mobile ? null:
        <Grid container justify="center" style={{ marginTop: 84 }}>
          <Grid>
            <Button
              disableElevation
              variant="contained"
              endIcon={<KeyboardArrowDown />}
              className={classes.voirComparaison}
              onClick={() => scrollToRefObject(gridPrixRef)}
            >
              Voir comparaison
            </Button>
          </Grid>
        </Grid>        
        }
        {mobile ? null :
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.gridPrixComparaison}
          ref={gridPrixRef}
        >
          <Grid lg={3} />
          <Grid lg={3} style={{ paddingBottom: 40, paddingTop: 50 }}>
            <Typography className={classes.typoHeaderTypeBlanc}>
              ÉQUIPE
            </Typography>
          </Grid>
          <Grid lg={3} ref={tribuTopRef} style={{ paddingBottom: 40, paddingTop: 50, position: "relative" }}>
           
            <Typography className={classes.typoHeaderTypeBlanc}>
              TRIBU
            </Typography>
          </Grid>
          <Grid lg={3} style={{ paddingBottom: 40, paddingTop: 50 }}>
            <Typography className={classes.typoHeaderTypeBlanc}>
              COMMUNAUTÉ
            </Typography>
          </Grid>

          <Grid
            container
            alignItems="center"
            className={classes.ligneTableauSection}
          >
            <Grid lg={3}>
              <Typography className={classes.typoHeaderLigneGris}>
                CRÉATION D'ATELIERS
              </Typography>
            </Grid>
            <Grid lg={3} >
              <Typography className={classes.typoLigneGris}>
                Illimitée
              </Typography>
            </Grid>
            <Grid lg={3}style={{position: "relative"}}>
              <Typography className={classes.typoLigneGris}>
                Illimitée
              </Typography>
            </Grid>
            <Grid lg={3}>
              <Typography className={classes.typoLigneGris}>
                Illimitée
              </Typography>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid
            container
            alignItems="center"
            className={classes.ligneTableauSection}
          >
            <Grid lg={3}>
              <Typography className={classes.typoHeaderLigneGris}>
                BIBLIOTHÈQUE D'ACTIVITÉS
              </Typography>
            </Grid>
            <Grid lg={3}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
            <Grid lg={3} style={{position: "relative"}}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
            <Grid lg={3}>
              <Typography className={classes.typoLigneGris}>
                Personnalisée
              </Typography>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid
            container
            alignItems="center"
            className={classes.ligneTableauSection}
          >
            <Grid lg={3}>
              <Typography className={classes.typoHeaderLigneGris}>
                BIBLIOTHÈQUE DE CAS D'USAGE
              </Typography>
            </Grid>
            <Grid lg={3}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
            <Grid lg={3} style={{position: "relative"}}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
            <Grid lg={3}>
              <Typography className={classes.typoLigneGris}>
                Personnalisée
              </Typography>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid
            container
            alignItems="center"
            className={classes.ligneTableauSection}
          >
            <Grid lg={3}>
              <Typography className={classes.typoHeaderLigneGris}>
                ATELIERS
              </Typography>
            </Grid>
            <Grid lg={3} />
            <Grid lg={3} />
            <Grid lg={3} />
          </Grid>
          <Grid
            container
            alignItems="center"
            className={classes.ligneTableauSection}
          >
            <Grid lg={3}>
              <Typography className={classes.typoHeaderLigneBlanc}>
                Nombre de participants
              </Typography>
            </Grid>
            <Grid lg={3}>
              <Typography className={classes.typoLigneGris}>5</Typography>
            </Grid>
            <Grid lg={3} style={{position: "relative"}}>
              <Typography className={classes.typoLigneGris}>150</Typography>
            </Grid>
            <Grid lg={3}>
              <Typography className={classes.typoLigneGris}>
                Illimité
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            className={classes.ligneTableauSection}
          >
            <Grid lg={3}>
              <Typography className={classes.typoHeaderLigneBlanc}>
                Consignes d'activités pré-remplies
              </Typography>
            </Grid>
            <Grid lg={3}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
            <Grid lg={3} style={{position: "relative"}}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
            <Grid lg={3}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            className={classes.ligneTableauSection}
          >
            <Grid lg={3}>
              <Typography className={classes.typoHeaderLigneBlanc}>
                Chronomètre
              </Typography>
            </Grid>
            <Grid lg={3}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
            <Grid lg={3} style={{position: "relative"}}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
            <Grid lg={3}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            className={classes.ligneTableauSection}
          >
            <Grid lg={3}>
              <Typography className={classes.typoHeaderLigneBlanc}>
                Partage de présentation
              </Typography>
            </Grid>
            <Grid lg={3}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
            <Grid lg={3} style={{position: "relative"}}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
            <Grid lg={3}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            className={classes.ligneTableauSection}
          >
            <Grid lg={3}>
              <Typography className={classes.typoHeaderLigneBlanc}>
                Téléchargement des comptes-rendus
              </Typography>
            </Grid>
            <Grid lg={3}>
              <Typography className={classes.typoLigneGris}>
                Illimité
              </Typography>
            </Grid>
            <Grid lg={3} style={{position: "relative"}}>
              <Typography className={classes.typoLigneGris}>
                Illimité
              </Typography>
            </Grid>
            <Grid lg={3}>
              <Typography className={classes.typoLigneGris}>
                Illimité
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            className={classes.ligneTableauSection}
          >
            <Grid lg={3}>
              <Typography className={classes.typoHeaderLigneBlanc}>
                Archivage
              </Typography>
            </Grid>
            <Grid lg={3}>
              <Typography className={classes.typoLigneGris}>
                Illimité
              </Typography>
            </Grid>
            <Grid lg={3} style={{position: "relative"}}>
              <Typography className={classes.typoLigneGris}>
                Illimité
              </Typography>
            </Grid>
            <Grid lg={3}>
              <Typography className={classes.typoLigneGris}>
                Illimité
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            className={classes.ligneTableauSection}
          >
            <Grid lg={3}>
              <Typography className={classes.typoHeaderLigneBlanc}>
                Personnalisation des consignes d'ateliers
              </Typography>
            </Grid>
            <Grid lg={3}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
            <Grid lg={3} style={{position: "relative"}}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
            <Grid lg={3}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            className={classes.ligneTableauSection}
          >
            <Grid lg={3}>
              <Typography className={classes.typoHeaderLigneBlanc}>
                Personnalisation des paramètres d'activités
              </Typography>
            </Grid>
            <Grid lg={3}></Grid>
            <Grid lg={3} style={{position: "relative"}}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
            <Grid lg={3}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid
            container
            alignItems="center"
            className={classes.ligneTableauSection}
          >
            <Grid lg={3}>
              <Typography className={classes.typoHeaderLigneGris}>
                SUPPORT
              </Typography>
            </Grid>
            <Grid lg={3}></Grid>
            <Grid lg={3}></Grid>
            <Grid lg={3}></Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            className={classes.ligneTableauSection}
          >
            <Grid lg={3}>
              <Typography className={classes.typoHeaderLigneBlanc}>
                Ressources de formation libre service
              </Typography>
            </Grid>
            <Grid lg={3}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
            <Grid lg={3} style={{position: "relative"}}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
            <Grid lg={3}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            className={classes.ligneTableauSection}
          >
            <Grid lg={3}>
              <Typography className={classes.typoHeaderLigneBlanc}>
                Support par email
              </Typography>
            </Grid>
            <Grid lg={3}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
            <Grid lg={3} style={{position: "relative"}}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
            <Grid lg={3}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            className={classes.ligneTableauSection}
          >
            <Grid lg={3}>
              <Typography className={classes.typoHeaderLigneBlanc}>
                Sessions de formation
              </Typography>
            </Grid>
            <Grid lg={3}></Grid>
            <Grid lg={3} style={{position: "relative"}}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
            <Grid lg={3}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            className={classes.ligneTableauSection}
          >
            <Grid lg={3}>
              <Typography className={classes.typoHeaderLigneBlanc}>
                Support par une équipe de facilitation
              </Typography>
            </Grid>
            <Grid lg={3}></Grid>
            <Grid lg={3}></Grid>
            <Grid lg={3}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            className={classes.ligneTableauSection}
          >
            <Grid lg={3}>
              <Typography className={classes.typoHeaderLigneBlanc}>
                Gestion des abonnements centralisée
              </Typography>
            </Grid>
            <Grid lg={3}></Grid>
            <Grid lg={3}></Grid>
            <Grid lg={3}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid
            container
            alignItems="center"
            className={classes.ligneTableauSection}
          >
            <Grid lg={3}>
              <Typography className={classes.typoHeaderLigneGris}>
                SÉCURITÉ
              </Typography>
            </Grid>
            <Grid lg={3}></Grid>
            <Grid lg={3}></Grid>
            <Grid lg={3}></Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            className={classes.ligneTableauSection}
          >
            <Grid lg={3}>
              <Typography className={classes.typoHeaderLigneBlanc}>
                Données sécurisées et hébergées en France
              </Typography>
            </Grid>
            <Grid lg={3}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
            <Grid lg={3} style={{position: "relative"}}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
            <Grid lg={3}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            className={classes.ligneTableauSection}
          >
            <Grid lg={3}>
              <Typography className={classes.typoHeaderLigneBlanc}>
                SSO
              </Typography>
            </Grid>
            <Grid lg={3}></Grid>
            <Grid lg={3}></Grid>
            <Grid lg={3}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            className={classes.ligneTableauSection}
          >
            <Grid lg={3}>
              <Typography className={classes.typoHeaderLigneBlanc}>
                Hébergement privé
              </Typography>
            </Grid>
            <Grid lg={3}></Grid>
            <Grid lg={3}></Grid>
            <Grid lg={3}>
              <Grid container justify="center">
                <Check />
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 70 }}>
            <Grid lg={3} />
            <Grid lg={3}>
              <Typography className={classes.typoHeaderPrixBlanc}>
                Gratuit
              </Typography>
            </Grid>
            <Grid lg={3} style={{position: "relative"}}>
              <Typography className={classes.typoHeaderPrixBlanc}>
                {annuel ? "7,90€" : "8,90€"}
              </Typography>
            </Grid>
            <Grid lg={3}>
              <Typography className={classes.typoHeaderPrixBlanc}>
                Sur demande
              </Typography>
            </Grid>
            <Grid lg={3} />
            <Grid lg={3}></Grid>
            <Grid lg={3} style={{ paddingTop: 12, position:"relative" }}>
              <Typography className={classes.typoParMoisBlanc}>
                /MOIS
              </Typography>
            </Grid>
            <Grid lg={3}></Grid>
            <Grid lg={3} />
            <Grid lg={3}>
              <Grid container justify="center" style={{ paddingTop: 30 }}>
                <Button
                  disableElevation
                  variant="contained"
                  endIcon={<ArrowForward />}
                  className={classes.buttonGris}
                  onClick={() => props.goToApp()}
                >
                  Je m'inscris
                </Button>
              </Grid>
            </Grid>
            <Grid lg={3} ref={tribuBottomRef}>
              <Grid container item justify="center" style={{ paddingTop: 30 }}>
                <Button
                  disableElevation
                  variant="contained"
                  endIcon={<ArrowForward />}
                  className={classes.buttonJaune}
                  onClick={() => annuel ? props.goToAppPaymentAnnuel(): props.goToAppPaymentMensuel()}
                >
                  Je choisis l'offre
                </Button>
              </Grid>
            </Grid>
            <Grid lg={3}>
              <Grid container item justify="center" style={{ paddingTop: 30 }}>
                <Button
                  disableElevation
                  variant="contained"
                  endIcon={<ArrowForward />}
                  className={classes.buttonGris}
                  component={RouterLink}
                  to="/contact"
                >
                  Nous contacter
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        }

        
        <div className={classes.gridContact} ref={contactRef}>
        <div
            className={
              accordeonContact ? classes.bandeauBlanc1 : classes.bandeauBlanc1Hidden
            }
          />
          <div
            className={
              accordeonContact ? classes.bandeauBlanc2 : classes.bandeauBlanc2Hidden
            }
          />
          <div
            className={
              accordeonContact ? classes.bandeauBlanc3 : classes.bandeauBlanc3Hidden
            }
          />
          <div
            className={
              accordeonContact ? classes.bandeauBlanc4 : classes.bandeauBlanc4Hidden
            }
          />

        <Grid container direction="column" justify="center" alignItems="center" style={{height: "100%"}}>
            <Grid container item justify="center" style={{position: "relative"}}>
              
              <div className={classes.interrogation}>
                <Interrogation />
              </div>
              <Typography className={classes.typoContact}>Besoin d'aide Une question</Typography>
            </Grid>
            <Grid container item justify="center" style={{marginTop: 96}}>
            <Button
                  disableElevation
                  variant="contained"
                  endIcon={<ArrowForward />}
                  className={classes.buttonJauneContact}
                  component={RouterLink}
                  to="/contact"
                >
                  Contacte-nous on adore ça
                </Button>
            </Grid>
        </Grid>
        </div>
        <Grid
          container
          justify="flex-start"
          direction="column"
          className={classes.gridFooter}
          ref={gridFooterRef}
        >
          <div
            className={
              accordeonFooter
                ? classes.bandeauNoir1
                : classes.bandeauNoir1Hidden
            }
          />
          <div
            className={
              accordeonFooter
                ? classes.bandeauNoir2
                : classes.bandeauNoir2Hidden
            }
          />
          <div
            className={
              accordeonFooter
                ? classes.bandeauNoir3
                : classes.bandeauNoir3Hidden
            }
          />
          <div
            className={
              accordeonFooter
                ? classes.bandeauNoir4
                : classes.bandeauNoir4Hidden
            }
          />
          {mobile ? null:
          <div className={classes.footerImpecc}>
            <Impecc2 />
          </div>          
          }

          <Grid
            container
            justify={mobile ? "center":"flex-start"}
            style={mobile ?{ marginTop:36 } :{ marginTop: 176, paddingLeft: 80, height: 250 }}
          >
            <Grid>
              <Typography className={classes.typoFooter}>
                {
                  "Découvre le futur du collaboratif avec Qibish"
                }
              </Typography>
            </Grid>
            <Grid style={mobile ?{marginTop: 24}:{ paddingLeft: "20%" }}>
              <Button
                disableElevation
                variant="contained"
                className={classes.buttonGoFooter}
                onClick={() => props.goToApp()}
              >
                GO !
              </Button>
            </Grid>
          </Grid>
          {mobile ? 
          <Grid container justify="center" style={{marginTop: 34}}>
            <Grid xs={10}>
              <Paper
                component="form"
                elevation={0}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: mobile ? "100%":303,
                  height: 48,
                  borderRadius: 16,
                  backgroundColor: "#202020",
                  opacity: 0.39,
                }}
                onSubmit={e => {e.preventDefault(); registerNewsletter(mail)}}
              >
                <InputBase
                onChange={(e) => setMail(e.target.value)}
                value={mail}
                  placeholder="S'inscrire à la newsletter"
                  inputProps={{ "aria-label": "S'inscrire à la newsletter" }}
                  style={{
                    marginLeft: 16,
                    flex: 1,
                    color: "#fff",
                    fontSize: 12,
                  }}
                />
                <Button disableElevation style={{ color: "#fff" }} onClick={() =>  registerNewsletter(mail)}>
                  OK
                </Button>
              </Paper>
              <Collapse in={successNewsletter}>
              <Alert
                style={{marginTop: 8}}
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setSuccessNewsletter(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Inscription validée !
              </Alert>
            </Collapse>

         
            <Collapse in={errorNewsletter}>
              <Alert
                style={{marginTop: 8}}
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setErrorNewsletter(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
               Une erreur s'est produite lors de ton inscription
              </Alert>
              </Collapse>
            </Grid>
          </Grid>
          :
          <Grid
            container
            justify="space-between"
            alignItems="flex-start"
            style={{
              marginTop: 70,
              paddingLeft: 80,
              paddingRight: 80,
              height: 150,
            }}
          >
            <Grid>
              <Grid container style={{ width: 310 }} alignItems="center">
                <Grid xs={3}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/"
                  >
                    Accueil
                  </Typography>
                </Grid>
                <Grid xs={3}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/prix"
                  >
                    Prix
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ position: "relative" }}>
                  <Typography className={classes.typoLiensFooter}>
                    Blog
                  </Typography>
                  <div className={classes.footerBlog}>
                    <Blog />
                  </div>
                </Grid>
                <Grid xs={3}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/contact"
                  >
                    Contact
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ marginTop: 24 }}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/solution"
                  >
                    Solution
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ marginTop: 24 }}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/mission"
                  >
                    Mission
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ marginTop: 24 }}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/cgu"
                  >
                    CGU
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ marginTop: 24 }}>
                  <Typography className={classes.typoLiensFooter} onClick={() => props.goToApp()}>
                    Se connecter
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <Paper
                component="form"
                elevation={0}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: 303,
                  height: 48,
                  borderRadius: 16,
                  backgroundColor: "#202020",
                  opacity: 0.39,
                }}
                onSubmit={e => {e.preventDefault(); registerNewsletter(mail)}}
              >
                <InputBase
                onChange={(e) => setMail(e.target.value)}
                value={mail}
                  placeholder="S'inscrire à la newsletter"
                  inputProps={{ "aria-label": "S'inscrire à la newsletter" }}
                  style={{
                    marginLeft: 16,
                    flex: 1,
                    color: "#fff",
                    fontSize: 12,
                  }}
                />
                <Button disableElevation style={{ color: "#fff" }} onClick={() =>  registerNewsletter(mail)}>
                  OK
                </Button>
              </Paper>
              <Collapse in={successNewsletter}>
              <Alert
                style={{marginTop: 8}}
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setSuccessNewsletter(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Inscription validée !
              </Alert>
            </Collapse>

         
            <Collapse in={errorNewsletter}>
              <Alert
                style={{marginTop: 8}}
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setErrorNewsletter(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
               Une erreur s'est produite lors de ton inscription
              </Alert>
              </Collapse>
            </Grid>
          </Grid>          
          }

          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={mobile ?{ marginTop: 40, paddingLeft: 24, paddingRight: 24 } :{ marginTop: 10, paddingLeft: 80, paddingRight: 80 }}
          >
            <Grid>
              <LogoBlanc />
            </Grid>
            <Grid>
                <Grid container direction="column">
                    <Grid>
                        <Typography 
                            className={classes.typoFooterMentions}
                            component={RouterLink}
                            to="/confidentialite">
                            Politique de confidentialité
                        </Typography>                        
                    </Grid>                    
                    <Grid>
                        <Typography 
                            className={classes.typoFooterMentions}
                            component={RouterLink}
                            to="/mentions">
                            Mentions légales © 2020 Qibish
                        </Typography>                        
                    </Grid>

                </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default withRouter(Prix);
