import React from "react";

export default function ExoinitialsSwitch(props){
    let initials = ""
    let offset= 12.74
    let width = 87
    let height = 101
    if(props.width){width = props.width}
    if(props.height){height = props.height}
    switch(props.variant){
        case "Input2fcs":
            initials = "Fcs"
            offset=8
        break;
        case "Input3css":
            initials = "Cs"
        break;
        case "Input4KISS":
            initials = "Ki"
            offset=15
        break;
        case "Input4L":
            initials = "4L"
        break;        
        case "Input4swot":
            initials = "Sw"
            offset=12
        break;   
        case "Input4back":
            initials = "B2f"
            offset= 8
        break;     
        case "Intro":
            initials = "In"
            offset = 15
        break; 
        case "Cards3gen":
            initials = "Ga"
        break;        
        case "Cards3rules":
            initials = "Or"
        break;      
        case "Collecte2risque":
            initials = "Cr"
        break;          
        case "Collecte3action":
            initials = "Ca"
        break;            
        case "Collecte3req":
            initials = "Cb"
        break;
        case "Collecte3obj" :
            initials = "Co"
        break;
        case "Collecte4mag":
            initials = "Cm"
        break; 
        case "Pickimageweather":
            initials = "Mj"
        break;  

        case "Select":
            initials = "Se"
        break;  
        case "Vote":
            initials = "Vo"
        break;  
        case "PrioQW":
            initials = "Qw"
            offset=10
        break;
        case "Prioui":
            initials = "Mp"
            offset=10
        break;                               
        case "Conclusionroti":
            initials = "Ro"
        break;           
        case "Conclusionconf":
            initials = "Vc"
        break;
        case "Présentation":
            initials = "Pz"
        break;
        case "Collecte2brainstorming":
            initials = "Br"
        break;
        case "Collecte3risque":
            initials = "Cr"
            offset= 14
        break;
        case "Collecte4idea":
            initials = "Ide"
            offset=11
        break;
        case "Collecte4portrait":
            initials = "Po"
        break;
        case "Input2feedpj":
            initials = "Fp"
        break;
        case "Input2feedws":
            initials = "Fa"
        break;
        case "Input3daily":
            initials = "Sup"
            offset=7
        break;
        case "Input4discovery":
            initials = "Dm"
            offset=9
        break;
        case "Input4hebdo":
            initials = "Heb"
            offset=6
        break;
        case "Input4kanban":
            initials = "Kb"
        break;
        case "Input4mix":
            initials = "Mi"
        break;
        case "Input4ocean":
            initials = "Ob"
        break;
        case "Input5onetone":
            initials = "Ev"
        break;
        case "Input6bono":
            initials = "6c"
        break;
        case "Input6empathy":
            initials = "Em"
            offset=10
        break;
        case "Input6personae":
            initials = "Pe"
        break;
        case "Input6pestel":
            initials = "Pes"
            offset=8
        break;
        case "Input8canvas":
            initials = "UxC"
            offset=6
        break;
        case "Priocb":
            initials = "Acb"
            offset=7
        break;
        case "PrioRC":
            initials = "Pr"
        break;

         
        default : initials = ""
    }
   return (
    <svg {...props} width={width} height={height}  viewBox="0 0 48 56">
    <g fill="none" fillRule="evenodd">
        <path fill={props.color === "white" ? "#FFFFFF" : props.color === "grey"? "#FAFAFB": "#FFE72F"}  d="M46 55H9c-3.866 0-7-3.134-7-7V8c0-3.866 3.134-7 7-7h30c3.866 0 7 3.134 7 7v47z"/>
        <text fill="#030303" fontFamily="Roboto-Medium, Roboto" fontSize="20" fontWeight="500">
            
            <tspan x={offset} y="34">{initials}</tspan>
           
            
        </text>
    </g>
</svg>
   )
}