import React, { useRef, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withRouter, Link as RouterLink } from "react-router-dom";
import {
  Button,
  Card,
  Grid,
  InputBase,
  Paper,
  Typography,
  Collapse,
  IconButton
} from "@material-ui/core";
import "typeface-roboto";
import "typeface-dm-serif-display";
import video_strategie from "./videos/qibish_home_strategie_1080px.mp4";
import video_brainstorming from "./videos/qibish_home_brainstorming_1080px.mp4";
import video_amelioration from "./videos/qibish_home_ameliorations_1080px.mp4";
import video_teambuilding from "./videos/qibish_home_teambuilding_1080px.mp4";
import {
  Depote,
  Muscle,
  Grandis,
  PictoContinue,
  FondJaune,
  RondJaune,
  TempsReel,
  Securite,
  TousSupports,
  Plus,
  Egal,
  Impecc,
  Impecc2,
  Blog,
  LogoBlanc,
  Techno,
} from "./icons";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import useWindowSize from "./useWindowSize";
import { ArrowForward } from "@material-ui/icons";
import dot from "./images/dot.png";
import dot2 from "./images/dot2.png";
import dot3 from "./images/dot3.png";
import prepare_1 from "./images/prepare_1.png";
import prepare_2 from "./images/prepare_2.png";
import prepare_3 from "./images/prepare_3.png";
import anime from "./images/anime.png";
import restitue from "./images/restitue.png";
import { Alert } from '@material-ui/lab'
import CloseIcon from '@material-ui/icons/Close';
import axios from "axios"

const useStyles = makeStyles((theme) => ({
  main: {
    minHeight: 6600,
    backgroundColor: "#ffe72f",

    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('md')]:{
      minHeight: 5500,
      //sinon bug arrière plan
      paddingTop: 1,
    }
  },
  gridTop: {
    marginTop: 206,
    [theme.breakpoints.down('md')]:{
      marginTop: 80
    }
  },
  gridTopSubHeader: {
    marginTop: 24,
  },
  gridGo: {
    marginTop: 66,
  },
  gridCas: {
    marginTop: -90,
    position: "relative",
  },
  gridYW: {
    background: "linear-gradient(to bottom, #ffffff 33%, #f8f8f4 67%)",
    height: 1021,
    position: "relative",
    overflow: "visible",
    borderBottomRightRadius: 160,
    zIndex: 5,
    [theme.breakpoints.down('md')]:{
      height: 1351,
    }
  },
  gridTechno: {
    backgroundColor: "#ffffff",
    borderTopLeftRadius: 160,
    height: 1021,
    zIndex: 5,
    position: "relative",
    borderBottomLeftRadius: 160,
    [theme.breakpoints.down('md')]:{
      height: 1021,
    }
  },
  gridFooter: {
    backgroundColor: "#000000",
    height: 800,
    position: "relative",
    overflow: "visible",
    [theme.breakpoints.down('md')]:{
      height: 600,
    }
  },
  fillerTechnoTopLeft: {
    position: "absolute",
    bottom: -160,
    left: 0,
    width: 160,
    height: 160,
    backgroundColor: "#f8f8f4",
    zIndex: 1,
  },
  fillerTechnoTopRight: {
    position: "absolute",
    top: -160,
    right: 0,
    width: 160,
    height: 160,
    backgroundColor: "#ffffff",
    zIndex: 1,
  },
  fillerTechnoTopRight2: {
    position: "absolute",
    top: -160,
    right: 0,
    width: 160,
    height: 160,
    borderBottomRightRadius: 160,
    backgroundColor: "#f8f8f4",
    zIndex: 1,
  },
  fillerTechnoBottomRight: {
    position: "absolute",
    bottom: -160,
    right: 0,
    width: 160,
    height: 160,
    backgroundColor: "#ffffff",
    zIndex: 1,
  },
  fillerTechnoBottomRight2: {
    position: "absolute",
    bottom: -160,
    right: 0,
    width: 160,
    height: 160,
    backgroundColor: "#ffe72f",
    borderTopRightRadius: 160,
    zIndex: 1,
  },
  grid3x: {
    marginTop: 250,
    height: 500,
    [theme.breakpoints.down('md')]:{
      marginTop: 160,
      height: 1000,
      paddingBottom: 150
    }
  },
  typoTop: {
    fontFamily: "DM Serif Display",
    fontSize: 72,
    fontWeight: "normal",
    fontStyle: "normal",
    letterSpacing: 0.5,
    textAlign: "center",
    color: "#000000",
    lineHeight: 1,
    [theme.breakpoints.down('md')]:{
      fontSize: 46,
      display: "inline",
      position: "relative"
    }
  },
  typoTopHighlight: {
    fontFamily: "DM Serif Display",
    fontSize: 72,
    fontWeight: "normal",
    fontStyle: "normal",
    letterSpacing: 0.5,
    textAlign: "center",
    color: "#000000",
    background: "linear-gradient(to top, #fff 50%, transparent 50%)",
    lineHeight: 1.2,
    [theme.breakpoints.down('md')]:{
      fontSize: 46,
      display: "inline",
      position: "relative"
    }
  },
  typoTopSubHeader: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: 1.65,
    letterSpacing: 0,
    textAlign: "center",
    color: "#030303",
    maxWidth: 406,
    [theme.breakpoints.down('md')]:{
      maxWidth: 306,
    }
  },
  typoCas: {
    fontFamily: "DM Serif Display",
    fontSize: 56,
    letterSpacing: 0.5,
    lineHeight: 1,
    color: "#000000",
    maxWidth: 387,
    textAlign: "left",
    [theme.breakpoints.down('md')]:{

      fontSize: 40,
    }
  },
  typoCardCasHeader: {
    fontSize: 16,
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#000000",
    lineHeight: 1.63,
  },
  typoCardCasBody: {
    fontSize: 16,
    fontWeight: "normal",
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#505049",
    lineHeight: 1.63,
    textAlign: "left"
  },
  typoCasCategorie: {
    opacity: 0.4,
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: 1.63,
    letterSpacing: 0,
    color: "#000000",
    cursor: "pointer",
  },
  typoMuscle: {
    position: "absolute",
    fontFamily: "DM Serif Display",
    fontSize: 44,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: 0.5,
    color: "#000000",
    top: "calc(50% - 72px)",
    left: 92,
    whiteSpace: "pre-line",
    textAlign: "center",
    pointerEvents: "none",
  },
  typoDepote: {
    position: "absolute",
    fontFamily: "DM Serif Display",
    fontSize: 44,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: 0.5,
    color: "#000000",
    top: "calc(50% - 60px)",
    right: 97,
    whiteSpace: "pre-line",
    textAlign: "center",
    pointerEvents: "none",
  },
  typoGrandis: {
    position: "absolute",
    fontFamily: "DM Serif Display",
    fontSize: 44,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: 0.5,
    color: "#000000",
    top: "calc(50% - 70px)",
    left: 110,
    whiteSpace: "pre-line",
    textAlign: "center",
    pointerEvents: "none",
  },
  typoMuscleHover: {
    visibility: "hidden",
    position: "absolute",
    fontFamily: "Roboto",
    fontSize: 21,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.7,
    letterSpacing: 0.5,
    color: "#ffffff",
    top: "calc(50% - 45px)",
    left: 80,
    whiteSpace: "pre-line",
    pointerEvents: "none",
  },
  typoMuscleHoverShow: {
    visibility: "visible",
    position: "absolute",
    fontFamily: "Roboto",
    fontSize: 21,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.7,
    letterSpacing: 0.5,
    color: "#ffffff",
    top: "calc(50% - 65px)",
    left: 80,
    whiteSpace: "pre-line",
    pointerEvents: "none",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeIn,
      duration: "0.4s",
    }),
  },
  typoDepoteHover: {
    visibility: "hidden",
    position: "absolute",
    fontFamily: "Roboto",
    fontSize: 21,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.7,
    letterSpacing: 0.5,
    color: "#ffffff",
    top: "calc(50% - 60px)",
    right: 80,
    whiteSpace: "pre-line",
    pointerEvents: "none",
  },
  typoDepoteHoverShow: {
    visibility: "visible",
    position: "absolute",
    fontFamily: "Roboto",
    fontSize: 21,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.7,
    letterSpacing: 0.5,
    color: "#ffffff",
    top: "calc(50% - 80px)",
    right: 80,
    whiteSpace: "pre-line",
    pointerEvents: "none",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeIn,
      duration: "0.4s",
    }),
  },
  typoGrandisHover: {
    visibility: "hidden",
    position: "absolute",
    fontFamily: "Roboto",
    fontSize: 21,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.7,
    letterSpacing: 0.5,
    color: "#ffffff",
    top: "calc(50% - 60px)",
    left: 75,
    whiteSpace: "pre-line",
    pointerEvents: "none",
  },
  typoGrandisHoverShow: {
    visibility: "visible",
    position: "absolute",
    fontFamily: "Roboto",
    fontSize: 21,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.7,
    letterSpacing: 0.5,
    color: "#ffffff",
    top: "calc(50% - 80px)",
    left: 75,
    whiteSpace: "pre-line",
    pointerEvents: "none",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeIn,
      duration: "0.4s",
    }),
  },
  typoYWHeader: {
    fontFamily: "DM Serif Display",
    fontSize: 34,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.18,
    letterSpacing: 0.5,
    textAlign: "center",
    color: "#000000",
  },
  typoYWCategorie: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.63,
    letterSpacing: 0,
    color: "#000000",
    opacity: 0.4,
    cursor: "pointer",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  typoYWCategorieSelected: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: 1.63,
    letterSpacing: 0,
    color: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  typoNotreTechno: {
    maxWidth: 400,
    fontFamily: "DM Serif Display",
    fontSize: 34,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.18,
    letterSpacing: 0.5,
    color: "#000000",
    whiteSpace: "pre-line",
  },
  typoTechno: {
    fontFamily: "Roboto",
    fontSize: 18,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.56,
    letterSpacing: 0,
    color: "#000000",
    whiteSpace: "pre-line",
  },
  typo3x: {
    fontFamily: "DM Serif Display",
    fontSize: 120,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: 0,
    textAlign: "center",
    color: "#000000",
  },
  typo3xSub: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: 0,
    color: "#000000",
    whiteSpace: "pre-line",
    [theme.breakpoints.down('md')]:{
      textAlign:"center"
    }
  },
  typo3xLast: {
    fontFamily: "DM Serif Display",
    fontSize: 56,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: 0,
    color: "#000000",
    whiteSpace: "pre-line",
    textAlign: "left",
    [theme.breakpoints.down('md')]:{
      textAlign:"center",
      fontSize: 40,
    }
  },
  typoFooter: {
    fontFamily: "DM Serif Display",
    fontSize: 48,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.08,
    letterSpacing: 0.43,
    color: "#ffffff",
    maxWidth: 600,
    [theme.breakpoints.down('md')]:{
      textAlign:"center",
      fontSize: 36
    }
  },
  typoLiensFooter: {
    opacity: 0.64,
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: 1.17,
    letterSpacing: 0,
    color: "#ffffff",
    cursor: "pointer",
    textDecoration: "none",
  },
  typoFooterMentions: {
    opacity: 0.4,
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.67,
    letterSpacing: 0,
    textAlign: "right",
    color: "#ffffff",
    cursor: "pointer",
    textDecoration: "none",
  },
  buttonGo: {
    width: 240,
    height: 240,
    borderRadius: 120,
    backgroundColor: "#000000",
    color: "#ffffff",
    fontFamily: "DM Serif Display",
    fontSize: 48,
    zIndex: 200,
    "&:hover ": {
      backgroundColor: "#212121",
    },
    [theme.breakpoints.down('md')]:{
      width: 180,
      height: 180,
      borderRadius: 90,
      fontSize: 36,
    }
  },
  buttonGoFooter: {
    width: 240,
    height: 240,
    borderRadius: 120,
    backgroundColor: "#ffe72f",
    color: "#000000",
    fontFamily: "DM Serif Display",
    fontSize: 48,
    zIndex: 200,
    "&:hover ": {
      backgroundColor: "rgb(178, 161, 32)",
    },
    [theme.breakpoints.down('md')]:{
      width: 180,
      height: 180,
      borderRadius: 90,
      fontSize: 36,
    }
  },
  triangleTop: {
    position: "absolute",
    top: 0,
    left: 0,
    background:
      "linear-gradient(to right bottom, #ffe72f 0%, #ffe72f 49%, transparent 50%)",
    width: "100%",
    height: 130,
    [theme.breakpoints.down('md')]:{
 height: 60,

    }
  },
  triangleBottom: {
    position: "absolute",
    bottom: 0,
    right: 0,
    background:
      "linear-gradient(to left top, #ffe72f 0%, #ffe72f 49%, transparent 50%)",
    width: "100%",
    height: 130,
    [theme.breakpoints.down('md')]:{
      height: 60,
     
         }
  },
  cardCas: {
    width: 350,
    height: 160,
    borderRadius: 9,
    padding: 24,
    backgroundColor: "#ffffff",
    boxShadow: "0 12px 24px 0 rgba(0, 0, 0, 0.07)",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
    [theme.breakpoints.down('md')]:{
     marginLeft:16
     
         }
  },
  cardCasHidden: {
    width: 350,
    height: 26,
    borderRadius: 9,
    paddingLeft: 24,
    backgroundColor: "transparent",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  bandeauBlanc1: {
    position: "absolute",
    top: -35,
    height: 30,
    width: "100%",
    backgroundColor: "#ffffff",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauBlanc2: {
    position: "absolute",
    top: -67,
    height: 25,
    width: "100%",
    backgroundColor: "#ffffff",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauBlanc3: {
    position: "absolute",
    top: -92,
    height: 15,
    width: "100%",
    backgroundColor: "#ffffff",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauBlanc4: {
    position: "absolute",
    top: -112,
    height: 5,
    width: "100%",
    backgroundColor: "#ffffff",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauBlanc1Hidden: {
    position: "absolute",
    top: 0,
    height: 30,
    width: "100%",
    backgroundColor: "#ffffff",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  bandeauBlanc2Hidden: {
    position: "absolute",
    top: 0,
    height: 25,
    width: "100%",
    backgroundColor: "#ffffff",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  bandeauBlanc3Hidden: {
    position: "absolute",
    top: 0,
    height: 15,
    width: "100%",
    backgroundColor: "#ffffff",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  bandeauBlanc4Hidden: {
    position: "absolute",
    top: 0,
    height: 5,
    width: "100%",
    backgroundColor: "#ffffff",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir1: {
    position: "absolute",
    top: -35,
    height: 30,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir2: {
    position: "absolute",
    top: -67,
    height: 25,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir3: {
    position: "absolute",
    top: -92,
    height: 15,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir4: {
    position: "absolute",
    top: -112,
    height: 5,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir1Hidden: {
    position: "absolute",
    top: 0,
    height: 30,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir2Hidden: {
    position: "absolute",
    top: 0,
    height: 25,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir3Hidden: {
    position: "absolute",
    top: 0,
    height: 15,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  bandeauNoir4Hidden: {
    position: "absolute",
    top: 0,
    height: 5,
    width: "100%",
    backgroundColor: "#000000",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.3s",
    }),
  },
  selectedChipYW0: {
    position: "absolute",
    width: 82,
    height: 33,
    borderRadius: 16.5,
    backgroundColor: "#efece3",
    top: 0,
    left: "calc(50% - 200px)",
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.6s",
    }),
    [theme.breakpoints.down('md')]:{
      left: "calc(50% - 143px)",
    }
  },
  selectedChipYW1: {
    position: "absolute",
    width: 82,
    height: 33,
    borderRadius: 16.5,
    backgroundColor: "#efece3",
    top: 0,
    left: "calc(50% - 43px)",
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.6s",
    }),
    [theme.breakpoints.down('md')]:{
      left: "calc(50% - 43px)",
    }
  },
  selectedChipYW2: {
    position: "absolute",
    width: 82,
    height: 33,
    borderRadius: 16.5,
    backgroundColor: "#efece3",
    top: 0,
    left: "calc(50% + 115px)",
    zIndex: 1,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.6s",
    }),
    [theme.breakpoints.down('md')]:{
      left: "calc(50% + 57px)",
    }
  },
  YWprepareLeft: {
    width: 695,
    top: 365,
    position: "absolute",
    left: "calc(50% - 550px)",
    visibility: "visible",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.6s",
    }),
    [theme.breakpoints.down('md')]:{
      width: 350,
      top: 250,
      left:"calc(50% - 175px)"
    }
  },
  YWprepareLeftHiddenLeft: {
    width: 695,
    top: 365,
    position: "absolute",
    left: 0,
    visibility: "hidden",
    opacity: 0,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.6s",
    }),
    [theme.breakpoints.down('md')]:{
      width: 350,
      top: 250,
      left:"calc(50% - 175px)"
    }
  },
  YWprepareLeft1: {
    width: 695,
    top: 365,
    position: "absolute",
    left: "calc(50% - 550px)",
    visibility: "visible",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.6s",
    }),
    [theme.breakpoints.down('md')]:{
      width: 350,
      top: 250,
      left:"calc(50% - 175px)"
    }
  },
  YWprepareLeftHidden1Right: {
    width: 695,
    top: 365,
    position: "absolute",
    left: "calc(100% - 695px)",
    visibility: "hidden",
    opacity: 0,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.6s",
    }),
    [theme.breakpoints.down('md')]:{
      width: 350,
      top: 250,
      left:"calc(50% - 175px)"
    }
  },
  YWprepareLeftHidden1Left: {
    width: 695,
    top: 365,
    position: "absolute",
    left: 0,
    visibility: "hidden",
    opacity: 0,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.6s",
    }),
    [theme.breakpoints.down('md')]:{
      width: 350,
      top: 250,
      left:"calc(50% - 175px)"
    }
  },
  YWprepareLeft2: {
    width: 695,
    top: 365,
    position: "absolute",
    left: "calc(50% - 550px)",
    visibility: "visible",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.6s",
    }),
    [theme.breakpoints.down('md')]:{
      width: 350,
      top: 250,
      left:"calc(50% - 175px)"
    }
  },
  YWprepareLeftHidden2: {
    width: 695,
    top: 365,
    position: "absolute",
    left: "calc(100% - 695px)",
    visibility: "hidden",
    opacity: 0,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.6s",
    }),
    [theme.breakpoints.down('md')]:{
      width: 350,
      top: 250,
      left:"calc(50% - 175px)"
    }
  },
  YWprepareRight: {
    width: 530,
    top: 326,
    height: 550,
    position: "absolute",
    right: "calc(50% - 550px)",
    visibility: "visible",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.6s",
    }),
    [theme.breakpoints.down('md')]:{
      width: 350,
      top: 650,
      right:"calc(50% - 175px)",
      overflow:"hidden"
    }
  },
  YWprepareRightHidden: {
    width: 530,
    top: 326,
    height: 550,
    position: "absolute",
    right: "calc(100% - 550px)",
    visibility: "hidden",
    opacity: 0,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.6s",
    }),
    [theme.breakpoints.down('md')]:{
      width: 350,
      top: 650,
      right:"calc(50% - 175px)",
      overflow:"hidden"
    }
  },
  YWprepareRight1: {
    width: 530,
    top: 326,
    height: 450,
    position: "absolute",
    right: "calc(50% - 550px)",
    visibility: "visible",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.6s",
    }),
    [theme.breakpoints.down('md')]:{
      width: 350,
      top: 650,
      right:"calc(50% - 175px)",
      overflow:"hidden"
    }
  },
  YWprepareRightHidden1Right: {
    width: 530,
    top: 326,
    height: 450,
    position: "absolute",
    right: 0,
    visibility: "hidden",
    opacity: 0,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.6s",
    }),
    [theme.breakpoints.down('md')]:{
      width: 350,
      top: 650,
      right:"calc(50% - 175px)",
      overflow:"hidden"
    }
  },
  YWprepareRightHidden1Left: {
    width: 530,
    top: 326,
    height: 450,
    position: "absolute",
    right: "100%",
    visibility: "hidden",
    opacity: 0,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.6s",
    }),
    [theme.breakpoints.down('md')]:{
      width: 350,
      top: 650,
      right:"calc(50% - 175px)",
      overflow:"hidden"
    }
  },
  YWprepareRight2: {
    width: 530,
    top: 326,
    height: 450,
    position: "absolute",
    right: "calc(50% - 550px)",
    visibility: "visible",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.6s",
    }),
    [theme.breakpoints.down('md')]:{
      width: 350,
      top: 650,
      right:"calc(50% - 175px)",
      overflow:"hidden"
    }
  },
  YWprepareRightHidden2: {
    width: 530,
    top: 326,
    height: 450,
    position: "absolute",
    right: 0,
    visibility: "hidden",
    opacity: 0,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: "0.6s",
    }),
    [theme.breakpoints.down('md')]:{
      width: 350,
      top: 650,
      right:"calc(50% - 175px)",
      overflow:"hidden"
    }
  },
  YWprepareHeader: {
    fontFamily: "DM Serif Display",
    fontSize: 56,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: 0.5,
    color: "#000000",
    [theme.breakpoints.down('md')]:{
      fontSize: 40,
      textAlign: "center"
    }
  },
  YWprepareHeaderSurligne: {
    fontFamily: "DM Serif Display",
    fontSize: 56,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: 0.5,
    color: "#000000",
    background: "linear-gradient(to top, #ffe72f 50%, transparent 50%)",
    [theme.breakpoints.down('md')]:{
     display:"inline",
     fontSize: 40,
     textAlign: "center"
    }
  },
  YWprepareBody: {
    fontFamily: "Roboto",
    fontSize: 18,
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: 1.56,
    letterSpacing: 0,
    color: "#000000",
    maxWidth: 375,
    [theme.breakpoints.down('md')]:{
      fontSize: 14,
      textAlign: "center"
     }
  },
  YWButton: {
    backgroundColor: "#000000",
    color: "#ffffff",
    textTransform: "none",
    height: 40,
    borderRadius: 14,
  },
  YWPrepareFondJaune: {
    position: "absolute",
    zIndex: 10,
    top: 0,
    right: 85,
    [theme.breakpoints.down('md')]:{
      right: 35
    }
  },
  YWPrepareDots: {
    position: "absolute",
    zIndex: 11,
    bottom: 30,
    right: 60,
  },
  YWPrepareFondJaune1: {
    position: "absolute",
    zIndex: 10,
    bottom: 30,
    right: 85,
  },
  YWPrepareDots1: {
    position: "absolute",
    zIndex: 11,
    bottom: 0,
    left: 0,
  },
  YWPrepareFondJaune2: {
    position: "absolute",
    zIndex: 10,
    top: 0,
    right: 0,
    [theme.breakpoints.down('md')]:{
      //visibility:"hidden"
    }
  },
  YWPrepareDots2: {
    position: "absolute",
    zIndex: 11,
    top: 30,
    right: 50,
  },
  YWAnime: {
    position: "absolute",
    zIndex: 12,
    top: 0,
    left: 0,
    [theme.breakpoints.down('md')]:{
      left: 36,
      top: 110
    }
  },
  YWRestitue: {
    position: "absolute",
    zIndex: 12,
    top: 30,
    left: -30,
    [theme.breakpoints.down('md')]:{
      left: 0,
      top:0
    }
  },
  YWPrepare1: {
    position: "absolute",
    zIndex: 12,
    bottom: 20,
    left: 0,
  },
  YWPrepare2: {
    position: "absolute",
    zIndex: 13,
    bottom: 50,
    right: 0,
  },
  YWPrepare3: {
    position: "absolute",
    zIndex: 14,
    top: -10,
    right: 70,
    [theme.breakpoints.down('md')]:{
      right: 20
    }
  },
  technoImage: {
    position: "absolute",
    top: 200,
    left: "50%",
    [theme.breakpoints.down('md')]:{
     position : "relative",
     left:0,
     top:0
    }
  },
  Impecc3x: {
    position: "absolute",
    top: -50,
    right: -80,
  },
  footerImpecc: {
    width: 586,
    height: 720,
    position: "absolute",
    top: 0,
    right: 0,
    overflow: "hidden",
    objectFit: "contain",
  },
  footerBlog: {
    position: "absolute",
    top: -14,
    left: -15,
  },
  video:{
    maxWidth: 560,
   [theme.breakpoints.down('md')]:{
     width: "90%",
     maxWidth: 400
   }  
  }
}));
function Home(props) {
  const classes = useStyles(props);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const [indexCas, setIndexCas] = useState(0);
  const [hover, setHover] = useState(null);
  const [accordeonYW, setAccordeonYW] = useState(false);
  const [accordeonFooter, setAccordeonFooter] = useState(false);
  const [indexYW, setIndexYW] = useState(0);
  const muscleRef = useRef();
  const depoteRef = useRef();
  const grandisRef = useRef();
  const gridYWRef = useRef();
  const gridFooterRef = useRef();
  const windowSize = useWindowSize();
  const [offsetTechno, setOffsetTechno] = useState(-180)
  const technoImgRef = useRef();
  const [mail, setMail] = useState("")
  const [errorNewsletter, setErrorNewsletter] = useState(false)
  const [successNewsletter, setSuccessNewsletter] = useState(false)
  const scrollGridRef = useRef();

  function registerNewsletter(mail){
    axios.post("https://www.qibish.com/newsletter", {
      mail:mail
    })
    .then((res) => {setSuccessNewsletter(true); setMail("")})
    .catch((error) => {
      setErrorNewsletter(true)
      setMail("")
    });
  }

  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y < windowSize.height / 2 && currPos.y > 230) {
        setHover(0);
      } else if (currPos.y > 600) {
        setHover(null);
      }
    },
    [],
    muscleRef
  );
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y < windowSize.height / 2 && currPos.y > 150) {
        setHover(1);
      }
    },
    [],
    depoteRef
  );
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y < windowSize.height / 2 && currPos.y > 175) {
        setHover(2);
      } else if (currPos.y < 175) {
        setHover(null);
      }
    },
    [],
    grandisRef
  );
  useScrollPosition(
    ({ currPos }) => {
     
      if (currPos.y + 25 < windowSize.height) {
        setAccordeonYW(true);
      } else {
        setAccordeonYW(false);
      }
    },
    [],
    gridYWRef
  );
  useScrollPosition(
    ({ currPos }) => {
      
      if (currPos.y + 25 < windowSize.height) {
        setAccordeonFooter(true);
      } else {
        setAccordeonFooter(false);
      }
    },
    [],
    gridFooterRef
  );
  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (currPos.y - 50 < windowSize.height && currPos.y > -550) {
        let totalpix = currPos.y + 550
        let parallax = Math.round((totalpix/9)*-1)
        setOffsetTechno(parallax)
      } else {
      }
    },
    [offsetTechno],
    technoImgRef
  );
  function videoRenderer(index) {
    let video;
    switch (index) {
      case 0:
        video = (
          <video key={0} className={classes.video} autoPlay muted>
            <source src={video_strategie} type="video/mp4" />
          </video>
        );
        break;
      case 1:
        video = (
          <video key={1} className={classes.video} autoPlay muted>
            <source src={video_brainstorming} type="video/mp4" />
          </video>
        );
        break;
      case 2:
        video = (
          <video key={2} className={classes.video} autoPlay muted>
            <source src={video_amelioration} type="video/mp4" />
          </video>
        );
        break;
      case 3:
        video = (
          <video key={3} className={classes.video} autoPlay muted>
            <source src={video_teambuilding} type="video/mp4" />
          </video>
        );
        break;
      default:
        video = null;
    }
    return video;
  }

  return (
    <React.Fragment>
      <div className={classes.main}>
        <Grid container justify="center" className={classes.gridTop}>
          <Grid container justify="center" item>
            <Grid style={mobile ? {textAlign:"center"}:{}}>
            <Typography  className={classes.typoTopHighlight}>
              Propulse tes ateliers
            </Typography>
            </Grid>

          </Grid>
          <Grid container justify="center" item>
            <Typography className={classes.typoTop}>
              au niveau supérieur
            </Typography>
          </Grid>
        </Grid>
        <Grid container justify="center" className={classes.gridTopSubHeader}>
          <Typography className={classes.typoTopSubHeader}>
            {
              "Découvre la première solution intelligente de préparation et d’animation de réunions collaboratives, fondée sur les meilleures méthodes d’intelligence collective."
            }
          </Typography>
        </Grid>
        <Grid container justify="center" className={classes.gridGo}>
          <Button
            disableElevation
            variant="contained"
            className={classes.buttonGo}
            onClick={() => props.goToApp()}
          >
            GO !
          </Button>
        </Grid>
        <Grid container justify="flex-start" className={classes.gridCas}>
          <Grid
            item
            lg={11}
            xs={12}
            style={mobile ?{ height: 850, backgroundColor: "#ffffff" }:{ height: 1096, backgroundColor: "#ffffff" }}
          >
            <div className={classes.triangleTop} />
            {mobile ? null:
            <div className={classes.triangleBottom} />
            }
            
            <Grid
              container
              justify="center"
              style={mobile ?{ height: "100%", padding: 16 } :{ height: "100%", padding: 160 }}
            >
              <Grid item lg={6} xs={12} style={mobile ?{ marginTop: 130 } :{ marginTop: 200 }}>
                <Grid container justify="flex-start" alignItems="flex-start"  >
                  <Grid>
                    <Typography className={classes.typoCas}>
                      {"On s’est occupé de ton cas"}
                    </Typography>
                  </Grid>
                  {mobile ?
                  <div ref={scrollGridRef}style={{maxWidth: "100%", overflowX: "scroll"}}>
                  <Grid container  style={{marginTop:24,height: 180, width: 1500}}>
                    <Grid>
                    <Card
                      elevation={0}
                      className={classes.cardCas}
                      onClick={() => mobile ? (setIndexCas(0),scrollGridRef.current.scroll({left:0, behavior:"smooth"}) ):(null)}
                    >
                      <Grid container justify="flex-start">
                        <Typography
                          className={classes.typoCardCasHeader}
                          
                        >
                          {"Stratégie & pilotage"}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        justify="flex-start"
                        style={{ marginTop: 8 }}
                      >
                        <Typography className={classes.typoCardCasBody}>
                          {
                            "Sois à l'écoute du marché, développe ton portefeuille d'activités et pilote tes équipes vers la réussite"
                          }
                        </Typography>
                      </Grid>
                    </Card>
                    </Grid>
                    <Grid item >
                    <Card
                      elevation={0}
                      className={classes.cardCas}
                      onClick={() => mobile ? (setIndexCas(1),scrollGridRef.current.scroll({left:370, behavior:"smooth"}) ):null}
                    >
                      <Grid container justify="flex-start">
                        <Typography
                          className={classes.typoCardCasHeader}
                         
                        >
                          {"Brainstorming & Idéation"}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        justify="flex-start"
                        style={{ marginTop: 8 }}
                      >
                        <Typography className={classes.typoCardCasBody}>
                          {
                            "Développe des idées innovantes avec ton groupe et transforme chaque réunion en pépite d’innovation"
                          }
                        </Typography>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item >
                    <Card
                      elevation={0}
                      className={classes.cardCas}
                      onClick={() => mobile ? (setIndexCas(2),scrollGridRef.current.scroll({left:740, behavior:"smooth"}) ):null}
                    >
                      <Grid container justify="flex-start">
                        <Typography
                          className={classes.typoCardCasHeader}
                          onClick={() => setIndexCas(2)}
                        >
                          {"Amélioration Continue"}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        justify="flex-start"
                        style={{ marginTop: 8 }}
                      >
                        <Typography className={classes.typoCardCasBody}>
                          {
                            "Embarque tes équipes dans l'amélioration de vos processus et méthodes de travail pour booster votre productivité"
                          }
                        </Typography>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item >
                    <Card
                      elevation={0}
                      className={classes.cardCas}
                      onClick={() => mobile ? (setIndexCas(3),scrollGridRef.current.scroll({left:1110, behavior:"smooth"}) ):null}
                    >
                      <Grid container justify="flex-start">
                        <Typography
                          className={classes.typoCardCasHeader}
                          
                        >
                          {"Teambuilding & Engagement"}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        justify="flex-start"
                        style={{ marginTop: 8 }}
                      >
                        <Typography className={classes.typoCardCasBody}>
                          {
                            "Renforce la cohésion d’équipe, engage tes collaborateurs et rends les acteurs du succès de l'entreprise"
                          }
                        </Typography>
                      </Grid>
                    </Card>
                  </Grid>
                  </Grid>                    
                  </div>

                  :
                  <>
                  <Grid container item style={{ marginTop: 36 }}>
                    <Card
                      elevation={0}
                      className={
                        indexCas === 0 ? classes.cardCas : classes.cardCasHidden
                      }
                    >
                      <Grid container justify="flex-start">
                        <Typography
                          className={
                            indexCas === 0
                              ? classes.typoCardCasHeader
                              : classes.typoCasCategorie
                          }
                          onClick={() => setIndexCas(0)}
                        >
                          {"Stratégie & pilotage"}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        justify="flex-start"
                        style={{ marginTop: 8 }}
                      >
                        <Typography className={classes.typoCardCasBody}>
                          {
                            "Sois à l'écoute du marché, développe ton portefeuille d'activités et pilote tes équipes vers la réussite"
                          }
                        </Typography>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid container item style={{ marginTop: 16 }}>
                    <Card
                      elevation={0}
                      className={
                        indexCas === 1 ? classes.cardCas : classes.cardCasHidden
                      }
                    >
                      <Grid container justify="flex-start">
                        <Typography
                          className={
                            indexCas === 1
                              ? classes.typoCardCasHeader
                              : classes.typoCasCategorie
                          }
                          onClick={() => setIndexCas(1)}
                        >
                          {"Brainstorming & Idéation"}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        justify="flex-start"
                        style={{ marginTop: 8 }}
                      >
                        <Typography className={classes.typoCardCasBody}>
                          {
                            "Développe des idées innovantes avec ton groupe et transforme chaque réunion en pépite d’innovation"
                          }
                        </Typography>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid container item style={{ marginTop: 16 }}>
                    <Card
                      elevation={0}
                      className={
                        indexCas === 2 ? classes.cardCas : classes.cardCasHidden
                      }
                    >
                      <Grid container justify="flex-start">
                        <Typography
                          className={
                            indexCas === 2
                              ? classes.typoCardCasHeader
                              : classes.typoCasCategorie
                          }
                          onClick={() => setIndexCas(2)}
                        >
                          {"Amélioration Continue"}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        justify="flex-start"
                        style={{ marginTop: 8 }}
                      >
                        <Typography className={classes.typoCardCasBody}>
                          {
                            "Embarque tes équipes dans l'amélioration de vos processus et méthodes de travail pour booster votre productivité"
                          }
                        </Typography>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid container item style={{ marginTop: 16 }}>
                    <Card
                      elevation={0}
                      className={
                        indexCas === 3 ? classes.cardCas : classes.cardCasHidden
                      }
                    >
                      <Grid container justify="flex-start">
                        <Typography
                          className={
                            indexCas === 3
                              ? classes.typoCardCasHeader
                              : classes.typoCasCategorie
                          }
                          onClick={() => setIndexCas(3)}
                        >
                          {"Teambuilding & Engagement"}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        justify="flex-start"
                        style={{ marginTop: 8 }}
                      >
                        <Typography className={classes.typoCardCasBody}>
                          {
                            "Renforce la cohésion d’équipe, engage tes collaborateurs et rends les acteurs du succès de l'entreprise"
                          }
                        </Typography>
                      </Grid>
                    </Card>
                  </Grid>
                  </>
                  }

                </Grid>
              </Grid>
              <Grid item lg={6} xs={12} style={mobile? { marginTop: 20 }:{ marginTop: 200 }}>
                {mobile ? 
                <Grid container justify="center">
{videoRenderer(indexCas)}
                </Grid>
                : 
                <>
                 {videoRenderer(indexCas)}
                 </>
                }
               
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {mobile ?
        null
        :
        <Grid
          container
          justify="center"
          style={{ height: 1221, position: "relative" }}
        >
          <Grid
            style={{ marginLeft: "40vw", position: "absolute", top: 78 }}
            onMouseOver={() => setHover(0)}
            onMouseOut={() => setHover(null)}
            ref={muscleRef}
          >
            <Typography className={classes.typoMuscle}>
              {"Renforce\nton\nexpertise"}
            </Typography>

            <Typography
              className={
                hover === 0
                  ? classes.typoMuscleHoverShow
                  : classes.typoMuscleHover
              }
            >
              {"Découvre sans plus\nattendre les meilleures\nméthodes de "}
              {
                <span style={{ color: "#ffe72f" }}>
                  {"travail\ncollaboratif"}
                </span>
              }
            </Typography>

            <Muscle
              style={{ zIndex: 2100 }}
              fill={hover === 0 ? "#000" : "none"}
            />
          </Grid>
          <Grid
            style={{ marginRight: "30vw", position: "absolute", top: 340 }}
            onMouseOver={() => setHover(1)}
            onMouseOut={() => setHover(null)}
            ref={depoteRef}
          >
            <Depote fill={hover === 1 ? "#000" : "none"} />
            <Typography className={classes.typoDepote}>
              {"Surpasse\nles\nlimites"}
            </Typography>
            <Typography
              className={
                hover === 1
                  ? classes.typoDepoteHoverShow
                  : classes.typoDepoteHover
              }
            >
              {"Anime des réunions\nimpactantes qui\n"}
              {
                <span style={{ color: "#ffe72f" }}>
                  {"boostent tes projets\n"}
                </span>
              }
              {"au quotidien"}
            </Typography>
          </Grid>
          <Grid
            style={{ marginLeft: "20vw", position: "absolute", top: 681 }}
            onMouseOver={() => setHover(2)}
            onMouseOut={() => setHover(null)}
            ref={grandisRef}
          >
            <Grandis fill={hover === 2 ? "#000" : "none"} />
            <Typography className={classes.typoGrandis}
            >
              {"Engage\ntes\néquipes"}
            </Typography>
            <Typography
              className={
                hover === 2
                  ? classes.typoGrandisHoverShow
                  : classes.typoGrandisHover
              }
            >
              {"Révolutionne les\nhabitudes de travail et\nprépare tes équipes"}
              {
                <span style={{ color: "#ffe72f" }}>
                  {" aux\nchallenges de demain"}
                </span>
              }
             
            </Typography>
          </Grid>
        </Grid>        
        }

        <Grid
          container
          justify="flex-start"
          alignItems="center"
          direction="column"
          className={classes.gridYW}
          ref={gridYWRef}
        >
          {mobile ? null :
          <>
          <div
            className={
              accordeonYW ? classes.bandeauBlanc1 : classes.bandeauBlanc1Hidden
            }
          />
          <div
            className={
              accordeonYW ? classes.bandeauBlanc2 : classes.bandeauBlanc2Hidden
            }
          />
          <div
            className={
              accordeonYW ? classes.bandeauBlanc3 : classes.bandeauBlanc3Hidden
            }
          />
          <div
            className={
              accordeonYW ? classes.bandeauBlanc4 : classes.bandeauBlanc4Hidden
            }  
          />
          </>}
          <Grid item container justify="center" style={{ marginTop: 90 }}>
            <Grid>
              <Typography className={classes.typoYWHeader}>
                Your workshops
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            justify="center"
            alignItems="center"
            style={{ marginTop: 42, position: "relative" }}
          >
            <div
              className={
                indexYW === 0
                  ? classes.selectedChipYW0
                  : indexYW === 1
                  ? classes.selectedChipYW1
                  : classes.selectedChipYW2
              }
            />
            <Grid
              onClick={() => setIndexYW(0)}
              style={mobile ?{ paddingRight: 8, paddingBottom: 4, zIndex: 2000 } :{ paddingRight: 32, paddingBottom: 4, zIndex: 2000 }}
            >
              <Typography
                className={
                  indexYW === 0
                    ? classes.typoYWCategorieSelected
                    : classes.typoYWCategorie
                }
              >
                Prépare
              </Typography>
            </Grid>
            
            <Grid style={{zIndex: 10}}>
              <PictoContinue />
            </Grid>
            <Grid
              onClick={() => setIndexYW(1)}
              style={mobile ? {
                paddingRight: 8,
                paddingLeft: 8,
                paddingBottom: 4,
                zIndex: 2000,
              }
                :{
                paddingRight: 32,
                paddingLeft: 42,
                paddingBottom: 4,
                zIndex: 2000,
              }}
            >
              <Typography
                className={
                  indexYW === 1
                    ? classes.typoYWCategorieSelected
                    : classes.typoYWCategorie
                }
              >
                Anime
              </Typography>
            </Grid>
            <Grid style={{zIndex: 10}}> 
              <PictoContinue />
            </Grid>
            <Grid
              onClick={() => setIndexYW(2)}
              style={mobile ?{ paddingLeft: 8, paddingBottom: 4, zIndex: 2000 } :{ paddingLeft: 42, paddingBottom: 4, zIndex: 2000 }}
            >
              <Typography
                className={
                  indexYW === 2
                    ? classes.typoYWCategorieSelected
                    : classes.typoYWCategorie
                }
              >
                Restitue
              </Typography>
            </Grid>
          </Grid>
          <div
            className={
              indexYW === 0
                ? classes.YWprepareLeft
                : classes.YWprepareLeftHiddenLeft
            }
          >
            <Grid container justify={mobile ? "center":"flex-start"}>
              <Grid container item justify={mobile ? "center":"flex-start"}>
                <Grid>
                <Typography className={classes.YWprepareHeader}>
                  Qibish, des ateliers
                </Typography>                  
                </Grid>

                <Grid style={mobile? {textAlign: "center"}:{}}>
                <Typography className={classes.YWprepareHeaderSurligne}>
                  collaboratifs surpuissants,
                </Typography>                  
                </Grid>

                <Typography className={classes.YWprepareHeader}>
                  maintenant.
                </Typography>
              </Grid>
            </Grid>
            <Grid container justify={mobile ? "center":"flex-start"} style={{ marginTop: 36 }}>
              <Grid>
                <Typography className={classes.YWprepareBody}>
                  {
                    "Prépare des ateliers adaptés à tes enjeux et tes objectifs en quelques minutes grâce au générateur Qibish et à sa bibliothèque de cas d’usage."
                  }
                </Typography>
              </Grid>
            </Grid>
            <Grid container justify={mobile ? "center":"flex-start"} style={{ marginTop: 24 }}>
              <Grid>
                <Button
                  variant="contained"
                  disableElevation
                  endIcon={<ArrowForward />}
                  className={classes.YWButton}
                  component={RouterLink}
                  to="/solution"
                >
                  On t'en dit plus ici
                </Button>
              </Grid>
            </Grid>
          </div>
          <div
            className={
              indexYW === 0
                ? classes.YWprepareRight
                : classes.YWprepareRightHidden
            }
          >
            <div className={classes.YWPrepareFondJaune}>
              <FondJaune />
            </div>
            <div className={classes.YWPrepareDots}>
              <img src={dot} alt="dots" />
            </div>
            <div className={classes.YWPrepare1}>
              <img src={prepare_3} alt="prepare_1" />
            </div>
            <div className={classes.YWPrepare2}>
              <img src={prepare_2} alt="prepare_2" />
            </div>
            <div className={classes.YWPrepare3}>
              <img src={prepare_1} alt="prepare_3" />
            </div>
          </div>
          <div
            className={
              indexYW === 0
                ? classes.YWprepareLeftHidden1Right
                : indexYW === 1
                ? classes.YWprepareLeft1
                : classes.YWprepareLeftHidden1Left
            }
          >
                        <Grid container justify={mobile ? "center":"flex-start"}>
              <Grid container item justify={mobile ? "center":"flex-start"}>
                <Grid>
                <Typography className={classes.YWprepareHeader}>
                {"Une participation &"}
                </Typography>                  
                </Grid>

                <Grid style={mobile? {textAlign: "center"}:{}}>
                <Typography className={classes.YWprepareHeaderSurligne}>
                productivité de qualité
                </Typography>                  
                </Grid>

                <Typography className={classes.YWprepareHeader}>
                maximale assurée
                </Typography>
              </Grid>
            </Grid>

            <Grid container justify={mobile ? "center":"flex-start"} style={{ marginTop: 36 }}>
              <Grid>
                <Typography className={classes.YWprepareBody}>
                  {
                    "Anime des ateliers intuitifs pour toi comme pour tes participants, guidé pas à pas par des consignes d’une simplicité renversante."
                  }
                </Typography>
              </Grid>
            </Grid>
            <Grid container justify={mobile ? "center":"flex-start"} style={{ marginTop: 24 }}>
              <Grid>
                <Button
                  variant="contained"
                  disableElevation
                  endIcon={<ArrowForward />}
                  className={classes.YWButton}
                  component={RouterLink}
                  to="/solution"
                >
                  On t'en dit plus ici
                </Button>
              </Grid>
            </Grid>
          </div>
          <div
            className={
              indexYW === 0
                ? classes.YWprepareRightHidden1Right
                : indexYW === 1
                ? classes.YWprepareRight1
                : classes.YWprepareRightHidden1Left
            }
          >
            <div className={classes.YWPrepareFondJaune1}>
              <FondJaune />
            </div>
            <div className={classes.YWPrepareDots1}>
              <img src={dot2} alt="dots" />
            </div>
            <div className={classes.YWAnime}>
              <img src={anime} alt="anime" style={mobile ? {width: 330, height:"auto"}:{}} />
            </div>
          </div>
          <div
            className={
              indexYW === 2
                ? classes.YWprepareLeft2
                : classes.YWprepareLeftHidden2
            }
          >
                                    
              <Grid container item justify={mobile ? "center":"flex-start"}>
                <Grid>
                <Typography className={classes.YWprepareHeader}>
                {"Ta réunion ne\u00A0"}
                </Typography>                  
                </Grid>

                <Grid style={mobile? {textAlign: "center"}:{}}>
                <Typography className={classes.YWprepareHeaderSurligne}>
                s’arrête pas là
                </Typography>                  
            </Grid>
            </Grid>
            <Grid container justify={mobile ? "center":"flex-start"} style={{ marginTop: 36 }}>
              <Grid>
                <Typography className={classes.YWprepareBody}>
                  {
                    "Partage le compte-rendu de l’atelier pour que les actions et décisions soient communiquées rapidement et prends du recul grâce aux statistiques Qibish uniques sur la collaboration et l’engagement de tes équipes."
                  }
                </Typography>
              </Grid>
            </Grid>
            <Grid container justify={mobile ? "center":"flex-start"} style={{ marginTop: 24 }}>
              <Grid>
                <Button
                  variant="contained"
                  disableElevation
                  endIcon={<ArrowForward />}
                  className={classes.YWButton}
                  component={RouterLink}
                  to="/solution"
                >
                  On t'en dit plus ici
                </Button>
              </Grid>
            </Grid>
          </div>
          <div
            className={
              indexYW === 2
                ? classes.YWprepareRight2
                : classes.YWprepareRightHidden2
            }
          >
            <div className={classes.YWPrepareFondJaune2}>
              <RondJaune />
            </div>
            <div className={classes.YWPrepareDots2}>
              <img src={dot3} alt="dots" />
            </div>
            <div className={classes.YWRestitue}>
              <img src={restitue} alt="restitue" style={mobile ? {width:350, height: "auto"}:{}} />
            </div>
          </div>
          <div className={classes.fillerTechnoTopLeft} />
        </Grid>
        <Grid
          container
          direction="column"
          justify="flex-start"
          className={classes.gridTechno}
        >
          <div className={classes.fillerTechnoTopRight} />
          <div className={classes.fillerTechnoTopRight2} />
          <div className={classes.fillerTechnoBottomRight} />
          <div className={classes.fillerTechnoBottomRight2} />
          {mobile ? 
          null
          :
          <div className={classes.technoImage} ref={technoImgRef}>
            <Techno offset={offsetTechno} mobile={mobile}/>
          </div>          
          }

          <Grid item container style={mobile ? { paddingLeft: "15%", marginTop: 80 }:{ paddingLeft: "15%", marginTop: 200 }}>
            <Typography className={classes.typoNotreTechno}>
              {"Une solution adaptée au monde de l'entreprise"}
            </Typography>
          </Grid>
          {mobile ?
          <Grid container justify="center" style={{marginTop:24}}>
                      <div className={classes.technoImage} ref={technoImgRef}>
            <Techno offset={offsetTechno} mobile={mobile}/>
          </div>  
          </Grid>
          :null}
          <Grid
            item
            container
            alignItems="center"
            style={mobile ? {marginTop:24}:{ paddingLeft: "15%", marginTop: 100 }}
          >
            <Grid xs={3} lg={1}>
              <TempsReel />
            </Grid>
            <Grid xs={9} lg={11} style={mobile ?{paddingLeft: 8}:{ paddingLeft: 0}}>
              <Typography className={classes.typoTechno}>
                <span style={{ fontWeight: "bold" }}>
                  {"Solution temps réel"}
                </span>{" "}
                {", pour une\nparticipation efficace et réactive."}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            style={mobile ? {paddingLeft:8,marginTop:24}:{ paddingLeft: "15%", marginTop: 60 }}
          >
            <Grid xs={3} lg={1}>
              <TousSupports />
            </Grid>
            <Grid xs={9} lg={11}  style={mobile ?{paddingLeft: 8}:{ paddingLeft: 0 }}>
              <Typography className={classes.typoTechno}>
                <span style={{ fontWeight: "bold" }}>
                  {"Adapté pour tous les supports\ndigitaux,"}
                </span>{" "}
                {" sans installation requise.\nUn jeu d'enfant."}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            style={mobile ? {paddingLeft:8,marginTop:24}:{ paddingLeft: "15%", marginTop: 60 }}
          >
            <Grid xs={3} lg={1}>
              <Securite />
            </Grid>
            <Grid xs={9} lg={11}  style={mobile ?{paddingLeft: 8}:{ paddingLeft: 0 }}>
              <Typography className={classes.typoTechno}>
                <span style={{ fontWeight: "bold" }}>
                  {"Des données sécurisées"}
                </span>{" "}
                {"pour\ntravailler en toute sérénité."}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justify="center" className={classes.grid3x}>
          <Grid item lg={"auto"} xs={12}>
            <Grid>
              <Typography className={classes.typo3x}>3x</Typography>
            </Grid>
            <Grid style={{ paddingTop: 24 }}>
              <Typography className={classes.typo3xSub}>
                {"Des participants 3 fois\nplus productifs en\nréunion Qibish"}
              </Typography>
            </Grid>
          </Grid>
          <Grid style={{ paddingTop: 24, paddingLeft: 40, paddingRight: 40 }}>
            <Plus />
          </Grid>
          <Grid item lg={"auto"} xs={12}>
            <Grid>
              <Typography className={classes.typo3x}>5x</Typography>
            </Grid>
            <Grid style={{ paddingTop: 24 }}>
              <Typography className={classes.typo3xSub}>
                {"Préparation de réunion\ncollaborative 5 fois\nplus rapide"}
              </Typography>
            </Grid>
          </Grid>
          <Grid style={{ paddingLeft: 40, paddingRight: 60, paddingTop: 50 }}>
            <Egal />
          </Grid>
          <Grid item lg={"auto"} xs={12}>
            <Grid style={{ paddingTop: 16, position: "relative" }}>
              <Typography className={classes.typo3xLast}>
                {"Des équipes\nplus engagées\net innovantes !"}
              </Typography>
              {mobile ? null:
              <div className={classes.Impecc3x}>
                <Impecc />
              </div>              
              }

            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justify="flex-start"
          direction="column"
          className={classes.gridFooter}
          ref={gridFooterRef}
        >
          <div
            className={
              accordeonFooter
                ? classes.bandeauNoir1
                : classes.bandeauNoir1Hidden
            }
          />
          <div
            className={
              accordeonFooter
                ? classes.bandeauNoir2
                : classes.bandeauNoir2Hidden
            }
          />
          <div
            className={
              accordeonFooter
                ? classes.bandeauNoir3
                : classes.bandeauNoir3Hidden
            }
          />
          <div
            className={
              accordeonFooter
                ? classes.bandeauNoir4
                : classes.bandeauNoir4Hidden
            }
          />
          {mobile ? null:
          <div className={classes.footerImpecc}>
            <Impecc2 />
          </div>          
          }

          <Grid
            container
            justify={mobile ? "center":"flex-start"}
            style={mobile ?{ marginTop:36 } :{ marginTop: 176, paddingLeft: 80, height: 250 }}
          >
            <Grid>
              <Typography className={classes.typoFooter}>
                {
                  "Obtiens Qibish, la meilleure solution pour tes ateliers collaboratifs"
                }
              </Typography>
            </Grid>
            <Grid style={mobile ?{marginTop: 24}:{ paddingLeft: "20%" }}>
              <Button
                disableElevation
                variant="contained"
                className={classes.buttonGoFooter}
                onClick={() => props.goToApp()}
              >
                GO !
              </Button>
            </Grid>
          </Grid>
          {mobile ? 
          <Grid container justify="center" style={{marginTop: 34}}>
            <Grid xs={10}>
              <Paper
                component="form"
                elevation={0}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: mobile ? "100%":303,
                  height: 48,
                  borderRadius: 16,
                  backgroundColor: "#202020",
                  opacity: 0.39,
                }}
                onSubmit={e => {e.preventDefault(); registerNewsletter(mail)}}
              >
                <InputBase
                onChange={(e) => setMail(e.target.value)}
                value={mail}
                  placeholder="S'inscrire à la newsletter"
                  inputProps={{ "aria-label": "S'inscrire à la newsletter" }}
                  style={{
                    marginLeft: 16,
                    flex: 1,
                    color: "#fff",
                    fontSize: 12,
                  }}
                />
                <Button disableElevation style={{ color: "#fff" }} onClick={() =>  registerNewsletter(mail)}>
                  OK
                </Button>
              </Paper>
              <Collapse in={successNewsletter}>
              <Alert
                style={{marginTop: 8}}
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setSuccessNewsletter(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Inscription validée !
              </Alert>
            </Collapse>

         
            <Collapse in={errorNewsletter}>
              <Alert
                style={{marginTop: 8}}
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setErrorNewsletter(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
               Une erreur s'est produite lors de ton inscription
              </Alert>
              </Collapse>
            </Grid>
          </Grid>
          :
          <Grid
            container
            justify="space-between"
            alignItems="flex-start"
            style={{
              marginTop: 70,
              paddingLeft: 80,
              paddingRight: 80,
              height: 150,
            }}
          >
            <Grid>
              <Grid container style={{ width: 310 }} alignItems="center">
                <Grid xs={3}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/"
                  >
                    Accueil
                  </Typography>
                </Grid>
                <Grid xs={3}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/prix"
                  >
                    Prix
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ position: "relative" }}>
                  <Typography className={classes.typoLiensFooter}>
                    Blog
                  </Typography>
                  <div className={classes.footerBlog}>
                    <Blog />
                  </div>
                </Grid>
                <Grid xs={3}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/contact"
                  >
                    Contact
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ marginTop: 24 }}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/solution"
                  >
                    Solution
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ marginTop: 24 }}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/mission"
                  >
                    Mission
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ marginTop: 24 }}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/cgu"
                  >
                    CGU
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ marginTop: 24 }}>
                  <Typography className={classes.typoLiensFooter} onClick={() => props.goToApp()}>
                    Se connecter
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <Paper
                component="form"
                elevation={0}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: 303,
                  height: 48,
                  borderRadius: 16,
                  backgroundColor: "#202020",
                  opacity: 0.39,
                }}
                onSubmit={e => {e.preventDefault(); registerNewsletter(mail)}}
              >
                <InputBase
                onChange={(e) => setMail(e.target.value)}
                value={mail}
                  placeholder="S'inscrire à la newsletter"
                  inputProps={{ "aria-label": "S'inscrire à la newsletter" }}
                  style={{
                    marginLeft: 16,
                    flex: 1,
                    color: "#fff",
                    fontSize: 12,
                  }}
                />
                <Button disableElevation style={{ color: "#fff" }} onClick={() =>  registerNewsletter(mail)}>
                  OK
                </Button>
              </Paper>
              <Collapse in={successNewsletter}>
              <Alert
                style={{marginTop: 8}}
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setSuccessNewsletter(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Inscription validée !
              </Alert>
            </Collapse>

         
            <Collapse in={errorNewsletter}>
              <Alert
                style={{marginTop: 8}}
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setErrorNewsletter(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
               Une erreur s'est produite lors de ton inscription
              </Alert>
              </Collapse>
            </Grid>
          </Grid>          
          }

          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={mobile ?{ marginTop: 40, paddingLeft: 24, paddingRight: 24 } :{ marginTop: 10, paddingLeft: 80, paddingRight: 80 }}
          >
            <Grid>
              <LogoBlanc />
            </Grid>
            <Grid>
                <Grid container direction="column">
                    <Grid>
                        <Typography 
                            className={classes.typoFooterMentions}
                            component={RouterLink}
                            to="/confidentialite">
                            Politique de confidentialité
                        </Typography>                        
                    </Grid>                    
                    <Grid>
                        <Typography 
                            className={classes.typoFooterMentions}
                            component={RouterLink}
                            to="/mentions">
                            Mentions légales © 2020 Qibish
                        </Typography>                        
                    </Grid>

                </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default withRouter(Home);
