import React, {useEffect} from "react";
import queryString from "query-string";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withRouter, Link as RouterLink } from "react-router-dom";
import { Grid, Typography, Paper, Button, InputBase, Collapse, IconButton } from "@material-ui/core";
import {PictoContact, Impecc2, LogoBlanc, Blog} from "./icons"
import { useState } from "react";
import { ArrowForward } from "@material-ui/icons";
import axios from "axios"
import { Alert } from '@material-ui/lab'
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  contactMain: {
    minHeight: 1659,
    backgroundColor: "#000000",
    [theme.breakpoints.down('md')]:{
      minHeight: 1400,

    }
  },
  gridContact: {
    paddingTop: 200,
  },
  gridFooter: {
    backgroundColor: "#000000",
    height: 800,
    position: "relative",
    overflow: "visible",
    [theme.breakpoints.down('md')]:{
      height: 600,

    }
  },
  typoQuestion:{
    fontFamily: "DM Serif Display",
    fontSize: 56,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: 0.5,
    color: "#ffffff",
    [theme.breakpoints.down('md')]:{
      fontSize: 56,
      textAlign: "center"
    }
  },
  typoTexte:{
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 2,
    letterSpacing: 0,
    color: "#7e7e7e",
    [theme.breakpoints.down('md')]:{
     
      textAlign: "center"
    }
  },
  footerImpecc: {
    width: 586,
    height: 720,
    position: "absolute",
    top: 0,
    right: 0,
    overflow: "hidden",
    objectFit: "contain",
  },
  typoFooter: {
    fontFamily: "DM Serif Display",
    fontSize: 48,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.08,
    letterSpacing: 0.43,
    color: "#ffffff",
    maxWidth: 600,
    [theme.breakpoints.down('md')]:{
      textAlign:"center",
      fontSize: 36
    }
  },
  typoLiensFooter: {
    opacity: 0.64,
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: 1.17,
    letterSpacing: 0,
    color: "#ffffff",
    cursor: "pointer",
    textDecoration: "none",
  },
  typoFooterMentions: {
    opacity: 0.4,
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.67,
    letterSpacing: 0,
    textAlign: "right",
    color: "#ffffff",
    cursor: "pointer",
    textDecoration: "none",
  },

  buttonGoFooter: {
    width: 240,
    height: 240,
    borderRadius: 120,
    backgroundColor: "#ffe72f",
    color: "#000000",
    fontFamily: "DM Serif Display",
    fontSize: 48,
    zIndex: 200,
    "&:hover ": {
      backgroundColor: "rgb(178, 161, 32)",
    },
    [theme.breakpoints.down('md')]:{
      width: 180,
      height: 180,
      borderRadius: 90,
      fontSize: 36,
    }
  },
  footerBlog: {
    position: "absolute",
    top: -14,
    left: -15,
  },
  paperInput:{
    display: "flex",
    alignItems: "center",
    width: "100%",
    maxWidth: 411,
    height: 48,
    borderRadius: 16,
    backgroundColor: "#202020",
  },
  paperInputMessage:{
    display: "flex",
    alignItems: "center",
    width: "100%",
    maxWidth: 411,
    minHeight: 112,
    borderRadius: 16,
    backgroundColor: "#202020",
  },
  buttonBlog: {
    height: 48,
    width: 145,
    borderRadius: "14px 14px 0px 14px",
    backgroundColor: "#ffe72f",
    textTransform: "none",
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: 1.63,
    letterSpacing: 0,
    color: "#000000",
    textAlign: "left",
    "&:hover ": {
      backgroundColor: "rgb(178, 161, 32)",
    },
  },
  buttonDisabled:{
    backgroundColor: "#202020 !important"
  }
}));
function Contact(props) {
  const classes = useStyles(props);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const [prenom, setPrenom] = useState("")
  const [contactMail, setContactMail] = useState("")
  const [tel, setTel] = useState("")
  const [message, setMessage] = useState("")
  const [mail, setMail] = useState("")
  const [errorNewsletter, setErrorNewsletter] = useState(false)
  const [successNewsletter, setSuccessNewsletter] = useState(false)
  const [errorContact, setErrorContact] = useState(false)
  const [successContact, setSuccessContact] = useState(false)
  useEffect(() => {
    let urlData = queryString.parse(window.location.search)
    if(urlData && urlData.suppressionCompte === "true"){
      setPrenom(urlData.prenom)
      setContactMail(urlData.mail)
      setMessage("Bonjour,\n\nJe souhaite obtenir la clôture de mon compte et vous demande, conformément à l'article 17.1 du RGPD de supprimer l'ensemble de mes données personnelles qui lui sont rattachées. Je vous remercie de m'informer des mesures prises à la suite de ma demande au plus tard dans un délai d'un mois à compter de ce jour (article 12.3 du RGPD).\n")
    }


   }, [])
  function registerNewsletter(mail){
    axios.post("https://www.qibish.com/newsletter", {
      mail:mail
    })
    .then((res) => {setSuccessNewsletter(true); setMail("")})
    .catch((error) => {
      setErrorNewsletter(true)
      setMail("")
    });
  }

function sendMail(){
  axios.post("https://www.qibish.com/contact", {
    firstname: prenom,
    mail: contactMail,
    tel: tel,
    message: message
  })
  .then((res) => {
    setSuccessContact(true)
    setContactMail("")
    setMessage("")
    setPrenom("")
    setTel("")
  })
  .catch((error) => {
    setErrorContact(true)
   
  });
}
  return (
    <React.Fragment>
      <div className={classes.contactMain}>
        {mobile ?
<>
                    <Grid container direction="column" style={{paddingTop: 120, paddingLeft: 16, paddingRight: 16}} >
                      <Grid >
                          <Typography className={classes.typoQuestion}>Tu as une question ?</Typography>
                      </Grid>


                      <Grid style={{marginTop: 32}} >
                          <Typography className={classes.typoTexte}>{"Notre équipe Qibish est là pour t’aider et te guider à travers toutes les questions que tu pourrais te poser sur l’application Qibish. À bientôt :)"}</Typography>
                      </Grid>
                      <Grid style={{marginTop: 70}}>
                      <Paper
                      
                      elevation={0}
                      className={classes.paperInput}
                    >
                      <InputBase
                        placeholder="Ton prénom"
                        inputProps={{ "aria-label": "Ton prénom" }}
                        style={{
                          marginLeft: 16,
                          flex: 1,
                          color: "#fff",
                          fontSize: 12,
                        }}
                        value={prenom}
                        onChange={e => setPrenom(e.target.value)}
                      />
                    </Paper>
                      </Grid>
                      <Grid style={{marginTop: 8}}>
                      <Paper
                      
                      elevation={0}
                      className={classes.paperInput}
                    >
                      <InputBase
                        placeholder="Ton adresse email"
                        inputProps={{ "aria-label": "Ton adresse email" }}
                        style={{
                          marginLeft: 16,
                          flex: 1,
                          color: "#fff",
                          fontSize: 12,
                        }}
                        value={contactMail}
                        onChange={e => setContactMail(e.target.value)}
                      />
                    </Paper>
                      </Grid>
                      <Grid style={{marginTop: 8}}>
                      <Paper
                      
                      elevation={0}
                      className={classes.paperInput}
                    >
                      <InputBase
                        placeholder="Ton numéro (facultatif)"
                        inputProps={{ "aria-label": "Ton numéro" }}
                        style={{
                          marginLeft: 16,
                          flex: 1,
                          color: "#fff",
                          fontSize: 12,
                        }}
                        value={tel}
                        onChange={e => setTel(e.target.value)}
                      />
                    </Paper>
                      </Grid>
                      <Grid style={{marginTop: 8}}>
                      <Paper
                      
                      elevation={0}
                      className={classes.paperInputMessage}
                    >
                      <InputBase
                        placeholder="Ton message"
                        inputProps={{ "aria-label": "Ton message" }}
                        multiline
                        rowsMin={5}
                        style={{
                          marginLeft: 16,
                          flex: 1,
                          color: "#fff",
                          fontSize: 12,
                        }}
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                      />
                    </Paper>
                      </Grid>
                      <Grid style={{marginTop: 24, maxWidth: 411}}>
                          <Grid container justify="flex-end">
                              <Button
                              classes={{disabled:classes.buttonDisabled}}
                              disabled={contactMail === "" || prenom === "" || message === ""}
                              variant="contained"
                              disableElevation
                              className={classes.buttonBlog}
                              endIcon={<ArrowForward />}
                              onClick={() => sendMail()}
                              >
                              {"Envoyer     "}
                              </Button>
                          </Grid>
                          <Collapse in={successContact}>
                    <Alert
                      style={{marginTop: 8}}
                      severity="success"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setSuccessContact(false)
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      Message envoyé !
                    </Alert>
                  </Collapse>
      
               
                  <Collapse in={errorContact}>
                    <Alert
                      style={{marginTop: 8}}
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setErrorContact(false)
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                     Une erreur s'est produite lors de l'envoi du message
                    </Alert>
                    </Collapse>
                      </Grid>
                    </Grid>
        </>
        :
        <Grid container className={classes.gridContact}>
          <Grid lg={1} />
          <Grid lg={5}>
              <Grid container direction="column" >
                <Grid style={{maxWidth: 411}}>
                    <Typography className={classes.typoQuestion}>Tu as une question ?</Typography>
                </Grid>
                <Grid>
                    <div style={{marginLeft: -80, marginTop:-40}}>
                        <PictoContact />                        
                    </div>

                </Grid>
              </Grid>
          </Grid>
          <Grid lg={1} />
          <Grid lg={4}>
              <Grid container direction="column">
                <Grid style={{maxWidth:365}}>
                    <Typography className={classes.typoTexte}>{"Notre équipe Qibish est là pour t’aider et te guider à travers toutes les questions que tu pourrais te poser sur l’application Qibish. À bientôt :)"}</Typography>
                </Grid>
                <Grid style={{marginTop: 70}}>
                <Paper
                
                elevation={0}
                className={classes.paperInput}
              >
                <InputBase
                  placeholder="Ton prénom"
                  inputProps={{ "aria-label": "Ton prénom" }}
                  style={{
                    marginLeft: 16,
                    flex: 1,
                    color: "#fff",
                    fontSize: 12,
                  }}
                  value={prenom}
                  onChange={e => setPrenom(e.target.value)}
                />
              </Paper>
                </Grid>
                <Grid style={{marginTop: 8}}>
                <Paper
                
                elevation={0}
                className={classes.paperInput}
              >
                <InputBase
                  placeholder="Ton adresse email"
                  inputProps={{ "aria-label": "Ton adresse email" }}
                  style={{
                    marginLeft: 16,
                    flex: 1,
                    color: "#fff",
                    fontSize: 12,
                  }}
                  value={contactMail}
                  onChange={e => setContactMail(e.target.value)}
                />
              </Paper>
                </Grid>
                <Grid style={{marginTop: 8}}>
                <Paper
                
                elevation={0}
                className={classes.paperInput}
              >
                <InputBase
                  placeholder="Ton numéro (facultatif)"
                  inputProps={{ "aria-label": "Ton numéro" }}
                  style={{
                    marginLeft: 16,
                    flex: 1,
                    color: "#fff",
                    fontSize: 12,
                  }}
                  value={tel}
                  onChange={e => setTel(e.target.value)}
                />
              </Paper>
                </Grid>
                <Grid style={{marginTop: 8}}>
                <Paper
                
                elevation={0}
                className={classes.paperInputMessage}
              >
                <InputBase
                  placeholder="Ton message"
                  inputProps={{ "aria-label": "Ton message" }}
                  multiline
                  rowsMin={5}
                  style={{
                    marginLeft: 16,
                    flex: 1,
                    color: "#fff",
                    fontSize: 12,
                  }}
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                />
              </Paper>
                </Grid>
                <Grid style={{marginTop: 24, maxWidth: 411}}>
                    <Grid container justify="flex-end">
                        <Button
                        classes={{disabled:classes.buttonDisabled}}
                        disabled={contactMail === "" || prenom === "" || message === ""}
                        variant="contained"
                        disableElevation
                        className={classes.buttonBlog}
                        endIcon={<ArrowForward />}
                        onClick={() => sendMail()}
                        >
                        {"Envoyer     "}
                        </Button>
                    </Grid>
                    <Collapse in={successContact}>
              <Alert
                style={{marginTop: 8}}
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setSuccessContact(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Message envoyé !
              </Alert>
            </Collapse>

         
            <Collapse in={errorContact}>
              <Alert
                style={{marginTop: 8}}
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setErrorContact(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
               Une erreur s'est produite lors de l'envoi du message
              </Alert>
              </Collapse>
                </Grid>
              </Grid>
          </Grid>
          <Grid lg={1} />
        </Grid>        
        }

<Grid
          container
          justify="flex-start"
          direction="column"
          className={classes.gridFooter}
          
        >
          
          {mobile ? null:
          <div className={classes.footerImpecc}>
            <Impecc2 />
          </div>          
          }

          <Grid
            container
            justify={mobile ? "center":"flex-start"}
            style={mobile ?{ marginTop:36 } :{ marginTop: 176, paddingLeft: 80, height: 250 }}
          >
            <Grid>
              <Typography className={classes.typoFooter}>
                {
                  "Facilite la communication et l’échange au sein de tes équipes avec Qibish"
                }
              </Typography>
            </Grid>
            <Grid style={mobile ?{marginTop: 24}:{ paddingLeft: "20%" }}>
              <Button
                disableElevation
                variant="contained"
                className={classes.buttonGoFooter}
                onClick={() => props.goToApp()}
              >
                GO !
              </Button>
            </Grid>
          </Grid>
          {mobile ? 
          <Grid container justify="center" style={{marginTop: 34}}>
            <Grid xs={10}>
              <Paper
                component="form"
                elevation={0}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: mobile ? "100%":303,
                  height: 48,
                  borderRadius: 16,
                  backgroundColor: "#202020",
                  opacity: 0.39,
                }}
                onSubmit={e => {e.preventDefault(); registerNewsletter(mail)}}
              >
                <InputBase
                onChange={(e) => setMail(e.target.value)}
                value={mail}
                  placeholder="S'inscrire à la newsletter"
                  inputProps={{ "aria-label": "S'inscrire à la newsletter" }}
                  style={{
                    marginLeft: 16,
                    flex: 1,
                    color: "#fff",
                    fontSize: 12,
                  }}
                />
                <Button disableElevation style={{ color: "#fff" }} onClick={() =>  registerNewsletter(mail)}>
                  OK
                </Button>
              </Paper>
              <Collapse in={successNewsletter}>
              <Alert
                style={{marginTop: 8}}
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setSuccessNewsletter(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Inscription validée !
              </Alert>
            </Collapse>

         
            <Collapse in={errorNewsletter}>
              <Alert
                style={{marginTop: 8}}
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setErrorNewsletter(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
               Une erreur s'est produite lors de ton inscription
              </Alert>
              </Collapse>
            </Grid>
          </Grid>
          :
          <Grid
            container
            justify="space-between"
            alignItems="flex-start"
            style={{
              marginTop: 70,
              paddingLeft: 80,
              paddingRight: 80,
              height: 150,
            }}
          >
            <Grid>
              <Grid container style={{ width: 310 }} alignItems="center">
                <Grid xs={3}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/"
                  >
                    Accueil
                  </Typography>
                </Grid>
                <Grid xs={3}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/prix"
                  >
                    Prix
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ position: "relative" }}>
                  <Typography className={classes.typoLiensFooter}>
                    Blog
                  </Typography>
                  <div className={classes.footerBlog}>
                    <Blog />
                  </div>
                </Grid>
                <Grid xs={3}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/contact"
                  >
                    Contact
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ marginTop: 24 }}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/solution"
                  >
                    Solution
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ marginTop: 24 }}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/mission"
                  >
                    Mission
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ marginTop: 24 }}>
                  <Typography
                    className={classes.typoLiensFooter}
                    component={RouterLink}
                    to="/cgu"
                  >
                    CGU
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ marginTop: 24 }}>
                  <Typography className={classes.typoLiensFooter} onClick={() => props.goToApp()}>
                    Se connecter
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <Paper
                component="form"
                elevation={0}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: 303,
                  height: 48,
                  borderRadius: 16,
                  backgroundColor: "#202020",
                  opacity: 0.39,
                }}
                onSubmit={e => {e.preventDefault(); registerNewsletter(mail)}}
              >
                <InputBase
                onChange={(e) => setMail(e.target.value)}
                value={mail}
                  placeholder="S'inscrire à la newsletter"
                  inputProps={{ "aria-label": "S'inscrire à la newsletter" }}
                  style={{
                    marginLeft: 16,
                    flex: 1,
                    color: "#fff",
                    fontSize: 12,
                  }}
                />
                <Button disableElevation style={{ color: "#fff" }} onClick={() =>  registerNewsletter(mail)}>
                  OK
                </Button>
              </Paper>
              <Collapse in={successNewsletter}>
              <Alert
                style={{marginTop: 8}}
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setSuccessNewsletter(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Inscription validée !
              </Alert>
            </Collapse>

         
            <Collapse in={errorNewsletter}>
              <Alert
                style={{marginTop: 8}}
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setErrorNewsletter(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
               Une erreur s'est produite lors de ton inscription
              </Alert>
              </Collapse>
            </Grid>
          </Grid>          
          }

          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={mobile ?{ marginTop: 40, paddingLeft: 24, paddingRight: 24 } :{ marginTop: 10, paddingLeft: 80, paddingRight: 80 }}
          >
            <Grid>
              <LogoBlanc />
            </Grid>
            <Grid>
                <Grid container direction="column">
                    <Grid>
                        <Typography 
                            className={classes.typoFooterMentions}
                            component={RouterLink}
                            to="/confidentialite">
                            Politique de confidentialité
                        </Typography>                        
                    </Grid>                    
                    <Grid>
                        <Typography 
                            className={classes.typoFooterMentions}
                            component={RouterLink}
                            to="/mentions">
                            Mentions légales © 2020 Qibish
                        </Typography>                        
                    </Grid>

                </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default withRouter(Contact);
